import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Input,
  Textarea,
  Checkbox,
  Typography,
  IconButton,
  Spinner,
} from "@material-tailwind/react";
import { PaperAirplaneIcon, DocumentTextIcon, XMarkIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../../utils/axiosInstance";
import { colors } from "../../utils/colors";
import { trackEvent } from "../../utils/analytics";

export default function SendModal({ isOpen, onClose, onSend, documents = [], isLoadingDocuments, dealId }) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [selectedDocs, setSelectedDocs] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSend = async () => {
    const selectedDocuments = documents.filter((doc) => selectedDocs[doc.id]);

    const payload = {
      email,
      message,
      documents: selectedDocuments.map((doc) => ({
        id: doc.id,
        name: doc.name,
        type: doc.type,
      })),
      dealId,
    };

    setIsSending(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axiosInstance.post("/send-documents", payload);
      if (response.data.success) {
        trackEvent('modal_send_documents', { 
          propertyId: dealId,
          documentCount: selectedDocuments.length
        });
        
        setSuccess(true);
        onSend(payload);
      } else {
        throw new Error("Échec de l'envoi des documents.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des documents:", error);
      setError("Une erreur s'est produite lors de l'envoi des documents. Veuillez réessayer.");
    } finally {
      setIsSending(false);
    }
  };

  const toggleDocument = (docId) => {
    setSelectedDocs((prev) => ({
      ...prev,
      [docId]: !prev[docId],
    }));
  };

  const resetForm = () => {
    setEmail("");
    setMessage("");
    setSelectedDocs({});
    setSuccess(false);
    setError(null);
  };

  return (
    <Dialog open={isOpen} handler={onClose} size="md">
      <DialogHeader className="flex items-center justify-between">
        <Typography variant="h5" color="blue-gray" className="flex items-center">
          <PaperAirplaneIcon className="h-6 w-6 mr-2" />
          Envoyer les documents
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <XMarkIcon className="h-5 w-5" />
        </IconButton>
      </DialogHeader>
      <DialogBody divider className="overflow-y-auto max-h-[60vh]">
        {success ? (
          <div className="flex flex-col items-center justify-center space-y-4">
            <CheckCircleIcon className="h-16 w-16 text-green-500" />
            <Typography variant="h4" color="green">
              Documents envoyés avec succès !
            </Typography>
            <Typography color="gray" className="text-center">
              Les documents ont été envoyés à l'adresse email : {email}
            </Typography>
          </div>
        ) : (
          <div className="space-y-6">
            <div>
              <Typography variant="h6" color="blue-gray" className="mb-4">
                Destinataire
              </Typography>
              <Input
                type="email"
                label="Adresse e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div>
              <Typography variant="h6" color="blue-gray" className="mb-4">
                Message (optionnel)
              </Typography>
              <Textarea
                label="Votre message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
              />
            </div>

            <div>
              <Typography variant="h6" color="blue-gray" className="mb-4">
                Documents à envoyer
              </Typography>
              {isLoadingDocuments ? (
                <div className="flex items-center justify-center">
                  <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
                  <Typography color="gray" className="ml-2">
                    Chargement des documents, cela peut prendre jusqu'à 30 secondes...
                  </Typography>
                </div>
              ) : (
                Array.isArray(documents) && documents.length > 0 ? (
                  documents.map((doc) => (
                    <div key={doc.id} className="flex items-center mb-2">
                      <Checkbox
                        id={`doc-${doc.id}`}
                        checked={selectedDocs[doc.id] || false}
                        onChange={() => toggleDocument(doc.id)}
                      />
                      <label htmlFor={`doc-${doc.id}`} className="flex items-center cursor-pointer ml-2">
                        <DocumentTextIcon className="h-5 w-5 mr-2 text-blue-gray-500" />
                        <Typography color="blue-gray">{doc.name}</Typography>
                      </label>
                    </div>
                  ))
                ) : (
                  <Typography color="gray">Aucun document disponible</Typography>
                )
              )}
            </div>
            {error && <Typography color="red" className="text-center">{error}</Typography>}
          </div>
        )}
      </DialogBody>
      <DialogFooter className="space-x-2">
        {success ? (
          <>
            <Button
              variant="outlined"
              style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
              className="flex items-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-lg hover:opacity-100"
              onClick={onClose}
            >
              Fermer
            </Button>
            <Button
              variant="gradient"
              color="green"
              onClick={resetForm}
              className="flex items-center"
            >
              Envoyer un autre email
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
              className="flex items-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-lg hover:opacity-100"
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              variant="gradient"
              color="green"
              onClick={handleSend}
              className="flex items-center"
              disabled={isSending || !email || Object.values(selectedDocs).filter(Boolean).length === 0}
            >
              {isSending ? (
                <Spinner className="h-4 w-4 mr-2" style={{ color: colors.dimoPrimary }} />
              ) : (
                <PaperAirplaneIcon className="h-4 w-4 mr-2" />
              )}
              {isSending ? "Envoi..." : "Envoyer"}
            </Button>
          </>
        )}
      </DialogFooter>
    </Dialog>
  );
}