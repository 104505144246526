import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { List, ListItem, ListItemPrefix, Button } from "@material-tailwind/react";
import {
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  HomeIcon,
  CreditCardIcon,
  ArrowUpTrayIcon,
  DocumentCheckIcon,
  CalendarDaysIcon,
  RocketLaunchIcon,
  PlusIcon,
  ExclamationTriangleIcon,
  IdentificationIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
  GiftIcon,
  LightBulbIcon,
  ChatBubbleLeftRightIcon,
  ChartBarIcon
} from "@heroicons/react/24/outline";
import axiosInstance from "../../utils/axiosInstance";
import { colors } from "../../utils/colors";
import Logo from "../../utils/logo";
import { useProperties } from "../../contexts/PropertiesContext";
import { Link } from "react-router-dom";
import { trackEvent } from "../../utils/analytics";

export default function Sidebar({ isOpen, closeSidebar }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const { properties, quotes, loading } = useProperties();
  const [isActionsOpen, setIsActionsOpen] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [isOrganization, setIsOrganization] = useState(false);

  const [notifications, setNotifications] = useState({
    fiscalId: false,
    dpe: false,
    appointment: false,
    documents: false,
    deals: false,
    maintenanceReport: false,
    energyRenovation: false,
    quotes: false,
    titleOfProperty: false,
    consumptionInvoice: false
  });

  const [showQuote, setShowQuote] = useState(false);
  const [showWonItems, setShowWonItems] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);

  // Vérifier si l'utilisateur est une organisation
  useEffect(() => {
    const token = document.cookie.split('; ').find(row => row.startsWith('authToken='));
    if (token) {
      const tokenValue = token.split('=')[1];
      try {
        const decodedToken = JSON.parse(atob(tokenValue.split('.')[1]));
        setIsOrganization(decodedToken.is_organization);
      } catch (error) {
        console.error("Erreur lors du décodage du token:", error);
        setIsOrganization(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!loading && properties) {
      const newNotifications = {
        fiscalId: properties.some(p => p.actionsNeeded.fiscalId),
        dpe: properties.some(p => p.actionsNeeded.dpe),
        appointment: properties.some(p => p.actionsNeeded.appointment),
        documents: properties.some(p => p.actionsNeeded.customerDocument && !p.appointmentIsPast),
        deals: false,
        maintenanceReport: properties.some(p => p.actionsNeeded.maintenanceReportMissing),
        energyRenovation: properties.some(p => p.actionsNeeded.energyRenovationMissing),
        quotes: quotes && quotes.some(q => q.quoteStatus === 'pending'),
        titleOfProperty: properties.some(p => p.actionsNeeded.titleOfPropertyMissing),
        consumptionInvoice: properties.some(p => p.actionsNeeded.consumptionInvoiceMissing)
      };
      setNotifications(newNotifications);

      setShowQuote(properties.some(p => p.status === 'open') || (quotes && quotes.length > 0));
      setShowWonItems(properties.some(p => p.status === 'won'));
      setShowDocuments(properties.some(p => p.actionsNeeded.customerDocument));
      
      setInitialLoadComplete(true);
    }
  }, [properties, quotes, loading]);

  const handleNavigation = useCallback((path) => {
    // Tracking de la navigation depuis la sidebar
    trackEvent('sidebar_navigate', {
      from: location.pathname,
      to: path
    });
    
    setActiveTab(path.split('/')[1]);
    navigate(path);
    closeSidebar();
  }, [location.pathname, navigate, closeSidebar]);

  const handleLogout = useCallback(async () => {
    // Tracking de la déconnexion
    trackEvent('sidebar_logout', {});
    
    try {
      await axiosInstance.get('/logout');
      navigate('/');
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      
      // Tracking de l'erreur de déconnexion
      trackEvent('sidebar_logout_error', {
        error: error.message || 'unknown'
      });
    }
  }, [navigate]);

  const handleNewQuote = () => {
    // Tracking du clic sur "Nouveau devis"
    trackEvent('sidebar_new_quote', {
      isOrganization: isOrganization
    });
    
    if (isOrganization) {
      window.open("https://app.dimo-diagnostic.net/mon-offre?s=1&o=devis-pro", "_blank");
    } else {
      window.open("https://www.dimo-diagnostic.net/devis-diagnostic-immobilier-tarifs", "_blank");
    }
  };

  const handleToggleActions = () => {
    // Tracking de l'ouverture/fermeture de la section "Actions à mener"
    trackEvent('sidebar_toggle_actions', {
      wasOpen: isActionsOpen
    });
    
    setIsActionsOpen(!isActionsOpen);
  };

  const handleLogoClick = () => {
    // Tracking du clic sur le logo
    trackEvent('sidebar_logo_click', {});
  };

  // Mémoriser les items de navigation qui dépendent des propriétés et des notifications
  const memoizedNavItems = useMemo(() => {
    const mainNavItems = [
      { path: "/dashboard", icon: HomeIcon, label: "Accueil", alwaysShow: true },
      { path: "/quote", icon: RocketLaunchIcon, label: "Devis", showIf: showQuote, notificationKey: "quotes" },
      { path: "/appointment", icon: CalendarDaysIcon, label: "Préparer mon RDV", notificationKey: "appointment", showIf: notifications.appointment },
      { path: "/documents", icon: ArrowUpTrayIcon, label: "Documents à fournir", notificationKey: "documents", showIf: showDocuments },
      { path: "/diagnostics", icon: DocumentTextIcon, label: "Diagnostics", showIf: showWonItems },
      { path: "/invoices", icon: CreditCardIcon, label: "Factures", showIf: showWonItems },
      { path: "/deals", icon: GiftIcon, label: "Club Partenaires", alwaysShow: true, isNew: true },
      { path: "/reviews", icon: ChatBubbleLeftRightIcon, label: "Avis clients", alwaysShow: true },
      // Le lien de parrainage n'est visible que pour les particuliers
      { path: "/support", icon: QuestionMarkCircleIcon, label: "Contact & Aide", showIf: properties && properties.length > 0 },
      { path: "/simulation-dpe", icon: ChartBarIcon, label: "Simulation DPE", alwaysShow: true, isNew: true },

    ];

    return mainNavItems;
  }, [showQuote, notifications.appointment, showDocuments, showWonItems, properties, isOrganization]);

  // Mémoriser les actions requises qui dépendent des notifications
  const memoizedRequiredActions = useMemo(() => {
    return [
      { path: "/fiscal-id", icon: IdentificationIcon, label: "Identifiant fiscal", notificationKey: "fiscalId", showIf: notifications.fiscalId },
      { path: "/dpe", icon: DocumentCheckIcon, label: "Accord DPE", notificationKey: "dpe", showIf: notifications.dpe },
      { path: "/documents?type=maintenance", icon: ArrowUpTrayIcon, label: "Rapports d'entretien", notificationKey: "maintenanceReport", showIf: notifications.maintenanceReport },
      { path: "/documents?type=energy", icon: ArrowUpTrayIcon, label: "Factures de rénovation", notificationKey: "energyRenovation", showIf: notifications.energyRenovation },
      { path: "/documents?type=ownership", icon: IdentificationIcon, label: "Titre de propriété", notificationKey: "titleOfProperty", showIf: notifications.titleOfProperty },
      { path: "/documents?type=consumption", icon: ArrowUpTrayIcon, label: "Factures de consommation", notificationKey: "consumptionInvoice", showIf: notifications.consumptionInvoice },
    ];
  }, [notifications]);

  // Mémoriser les items de bas de page
  const memoizedFooterItems = useMemo(() => {
    return [
      { path: "/suggestion", icon: LightBulbIcon, label: "Suggérer une amélioration", onClick: () => handleNavigation("/suggestion"), isFooterItem: true },
      { path: "/technical-help", icon: ExclamationTriangleIcon, label: "Signaler un problème", onClick: () => handleNavigation("/technical-help"), isFooterItem: true },
      { path: "/logout", icon: UserIcon, label: "Déconnexion", onClick: handleLogout, isFooterItem: true },
    ];
  }, [handleLogout, handleNavigation]);

  // Mémoriser si des actions sont requises
  const hasRequiredActions = useMemo(() => {
    return memoizedRequiredActions.some(action => action.showIf);
  }, [memoizedRequiredActions]);

  const renderNavItem = (item) => (
    <ListItem
      key={item.path}
      className={`cursor-pointer ${activeTab === item.path ? 'bg-gray-100' : ''} ${item.isFooterItem ? 'py-2' : ''}`}
      onClick={item.onClick || (() => handleNavigation(item.path))}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <ListItemPrefix>
            <item.icon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          </ListItemPrefix>
          <div className="flex items-center">
            <span style={{ color: colors.dimoPrimary }}>{item.label}</span>
            {item.isNew && (
              <span className="ml-2 text-xs font-bold uppercase" style={{ color: colors.dimoPop }}>
                new
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center">
          {item.notificationKey && notifications[item.notificationKey] && (
            <span className="w-2 h-2 bg-red-500 rounded-full opacity-75"></span>
          )}
        </div>
      </div>
    </ListItem>
  );

  const renderPlaceholder = () => (
    <div className="flex items-center space-x-4 px-4 py-3">
      <div className="w-5 h-5 bg-gray-200 rounded-full animate-pulse"></div>
      <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
    </div>
  );

  const renderNewQuoteButtonPlaceholder = () => (
    <div className="flex items-center gap-3 mb-4 mx-4 h-10 bg-gray-200 rounded animate-pulse"></div>
  );

  return (
    <aside
      className={`
        fixed left-0 z-[80] w-64 h-dvh bg-white shadow-md
        transition-transform duration-300 ease-in-out transform
        ${isOpen ? 'translate-x-0' : '-translate-x-full'}
        lg:translate-x-0
        flex flex-col
      `}
    >
      <div className="p-4">
        <Link to="/dashboard" className="mx-auto" onClick={handleLogoClick}>
          <Logo />
        </Link>
      </div>
      <div className="flex-1 overflow-y-auto">
        <List>
          {loading && !initialLoadComplete ? (
            <>
              {renderNewQuoteButtonPlaceholder()}
              {Array(5).fill(0).map((_, index) => (
                <React.Fragment key={index}>{renderPlaceholder()}</React.Fragment>
              ))}
            </>
          ) : (
            <>
              <Button
                style={{ backgroundColor: colors.dimoPrimary }}
                className="flex items-center gap-3 mb-4 mx-4"
                onClick={handleNewQuote}
              >
                <PlusIcon className="h-5 w-5" />
                Nouveau devis
              </Button>

              {/* Accueil */}
              {renderNavItem(memoizedNavItems[0])}

              {/* Devis */}
              {showQuote && renderNavItem(memoizedNavItems[1])}

              {/* Menu déroulant des actions requises */}
              {hasRequiredActions && (
                <div className="mt-0">
                  <ListItem
                    className="cursor-pointer"
                    onClick={handleToggleActions}
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <ListItemPrefix>
                          <ClipboardDocumentListIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
                        </ListItemPrefix>
                        <span style={{ color: colors.dimoPrimary }}>Actions à mener</span>
                      </div>
                      <div className="flex items-center gap-2">
                        {hasRequiredActions && (
                          <span className="w-2 h-2 bg-red-500 rounded-full opacity-75"></span>
                        )}
                        {isActionsOpen ? (
                          <ChevronUpIcon className="h-4 w-4" style={{ color: colors.dimoPrimary }} />
                        ) : (
                          <ChevronDownIcon className="h-4 w-4" style={{ color: colors.dimoPrimary }} />
                        )}
                      </div>
                    </div>
                  </ListItem>
                  
                  {isActionsOpen && (
                    <div className="ml-4">
                      {memoizedRequiredActions.map((action) => action.showIf && renderNavItem(action))}
                    </div>
                  )}
                </div>
              )}

              {/* Reste du menu principal */}
              {memoizedNavItems.slice(2).map((item) => (
                (item.alwaysShow || item.showIf) && renderNavItem(item)
              ))}
            </>
          )}
        </List>
      </div>
      <div>
        <List className="gap-1">
          {memoizedFooterItems.map(item => renderNavItem(item))}
        </List>
      </div>
    </aside>
  );
}
