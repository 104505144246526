import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Typography,
  Button,
  Card,
  IconButton,
} from "@material-tailwind/react";
import { 
  XMarkIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { colors } from "../../utils/colors";
import { trackEvent } from "../../utils/analytics";

export default function TipSuccessModal({ isOpen, onClose, dealId, amount, technicien }) {
  const handleClose = () => {
    trackEvent('tip_success_modal_closed', {
      propertyId: dealId,
      amount: amount
    });
    onClose();
  };

  return (
    <Dialog 
      open={isOpen} 
      handler={handleClose} 
      size="sm"
      className="bg-white shadow-xl rounded-2xl overflow-hidden"
    >
      <DialogHeader className="px-0 pt-0 pb-0 relative overflow-hidden">
        <div className="absolute top-0 right-0 z-20">
          <IconButton variant="text" color="white" onClick={handleClose} className="mt-2 mr-2">
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </div>
        {/* Bannière supérieure avec gradient */}
        <div 
          className="w-full bg-gradient-to-r from-blue-500 to-green-500 pt-12 pb-20 px-8 text-white relative z-0"
          style={{ 
            backgroundImage: `linear-gradient(135deg, ${colors.dimoPrimary} 0%, #38a169 100%)`,
          }}
        >
          <Typography variant="h4" className="font-bold mb-2 text-center">
            Merci pour votre générosité !
          </Typography>
          <Typography className="text-center text-white/80 font-light">
            Votre pourboire a bien été reçu
          </Typography>
        </div>
      </DialogHeader>
      
      <DialogBody className="px-6 pt-0 pb-8 -mt-14">
        <Card className="shadow-lg p-0 pb-6 overflow-hidden">
          {/* Profil du technicien sur la card, qui déborde dans la bannière */}
          <div className="flex flex-col items-center mt-6">
            <div className="relative z-10 mb-4 flex flex-wrap items-center gap-4 justify-center sm:justify-start">
              {technicien?.photoUrl ? (
                <img 
                  src={technicien.photoUrl} 
                  alt={`${technicien.prenom} ${technicien.nom}`}
                  className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full object-cover border-4 border-white shadow-xl relative z-10"
                />
              ) : (
                <div className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 rounded-full bg-blue-500 flex items-center justify-center border-4 border-white shadow-xl relative z-10">
                  <Typography className="text-white text-xl sm:text-2xl md:text-3xl font-bold">
                    {technicien?.prenom?.[0]}{technicien?.nom?.[0] || "D"}
                  </Typography>
                </div>
              )}
              
              <div className="flex-1 text-center sm:text-left">
                <Typography variant="h6" className="font-medium text-gray-800">
                  {technicien?.prenom} {technicien?.nom || "Votre diagnostiqueur"}
                </Typography>
                <Typography color="blue-gray" className="text-xs font-medium mb-1">
                  Votre diagnostiqueur
                </Typography>
              </div>
            </div>
            
            {/* Message de confirmation */}
            <div className="flex flex-col items-center mb-6 mt-4">
              <Typography color="gray" className="text-sm mb-2">
                Vous avez donné un pourboire de
              </Typography>
              <div className="flex items-center justify-center bg-green-100 px-5 sm:px-6 md:px-7 py-3 sm:py-4 rounded-full shadow-sm">
                <Typography className="font-bold text-green-800 text-2xl sm:text-3xl">
                  {amount}€
                </Typography>
              </div>
              <Typography color="gray" className="text-xs mt-3 flex items-center">
                <CheckIcon className="h-4 w-4 mr-1 text-green-500" />
                100% reversé au diagnostiqueur
              </Typography>
            </div>
            
            <div className="w-full px-4 sm:w-[80%] mx-auto text-center">
              <Typography color="gray" className="text-sm mb-6">
                Votre diagnostiqueur vous remercie pour votre geste. Ce pourboire est une reconnaissance de la qualité du service qui lui a été fourni.
              </Typography>
              
              <Button
                variant="filled"
                size="lg"
                className="w-full text-sm"
                style={{ backgroundColor: colors.dimoPrimary }}
                onClick={handleClose}
              >
                Retour à mes diagnostics
              </Button>
            </div>
          </div>
        </Card>
      </DialogBody>
    </Dialog>
  );
} 