import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Card,
  Typography,
  Spinner,
} from "@material-tailwind/react";
import Layout from '../components/layout/Layout';
import { colors } from '../utils/colors';
import axiosInstance from '../utils/axiosInstance';
import { DocumentCheckIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { trackEvent } from "../utils/analytics";

const Certification = () => {
  const [techs, setTechs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTechs = async () => {
      setLoading(true);
      
      trackEvent('certification_load_technicians', {});
      
      try {
        const { data } = await axiosInstance.get('/certification');
        // Trier les techniciens par nombre de certifications décroissant
        const sortedTechs = data.sort((a, b) => (b.Certifs?.length || 0) - (a.Certifs?.length || 0));
        setTechs(sortedTechs);
        
        trackEvent('certification_load_success', {
          technicianCount: sortedTechs.length,
          certificationCount: sortedTechs.reduce((sum, tech) => sum + (tech.Certifs?.length || 0), 0)
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des techniciens:', error);
        
        trackEvent('certification_load_error', {
          error: error.message || 'unknown'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTechs();
  }, []);

  // Fonction pour obtenir la certification la plus fréquente ou la première
  const getMainCertification = (certifs) => {
    if (!certifs || certifs.length === 0) return null;
    
    // Compter les occurrences de chaque type de certification
    const certifCounts = certifs.reduce((acc, certif) => {
      acc[certif.type_certif] = (acc[certif.type_certif] || 0) + 1;
      return acc;
    }, {});

    // Trouver le type de certification le plus fréquent
    const mostFrequentType = Object.entries(certifCounts)
      .sort(([,a], [,b]) => b - a)[0][0];

    return certifs.find(c => c.type_certif === mostFrequentType);
  };

  // Fonction pour obtenir la date d'expiration la plus éloignée
  const getLatestExpiryDate = (certifs) => {
    if (!certifs || certifs.length === 0) return null;
    return new Date(Math.max(...certifs.map(c => new Date(c.date_fin_validite))));
  };

  return (
    <Layout>
      <Helmet>
        <title>Vérification des certifications - DIMO Diagnostic</title>
      </Helmet>
      <div className="mx-auto mb-4">
        <Card className="w-full p-8 md:p-12 bg-white shadow-lg">
          <div className="max-w-7xl mx-auto">
            {loading ? (
              <div className="animate-pulse">
                <div className="text-center mb-12">
                  <div className="h-8 w-64 bg-gray-200 rounded mx-auto mb-4"></div>
                  <div className="h-4 w-full max-w-3xl bg-gray-200 rounded mx-auto"></div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
                  {[...Array(6)].map((_, i) => (
                    <div key={i} className="bg-gray-50 shadow-md border border-gray-100 p-6">
                      <div className="flex items-start gap-4">
                        <div className="shrink-0">
                          <div className="w-12 h-12 rounded-full bg-gray-200"></div>
                        </div>
                        <div className="flex-grow min-w-0">
                          <div className="h-6 w-40 bg-gray-200 rounded mb-2"></div>
                          <div className="h-4 w-32 bg-gray-200 rounded mb-2"></div>
                          <div className="h-4 w-48 bg-gray-200 rounded"></div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="flex flex-wrap gap-2">
                          {[...Array(3)].map((_, j) => (
                            <div key={j} className="h-7 w-24 bg-gray-200 rounded-xl"></div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="text-center mb-12">
                <Typography variant="h2" className="text-black mb-4">
                  Nos experts certifiés
                </Typography>
                <Typography className="text-gray-700 text-lg max-w-3xl mx-auto">
                  Découvrez les certifications de nos diagnostiqueurs immobiliers. 
                  Chaque expert est rigoureusement formé et certifié pour garantir des diagnostics de qualité.
                </Typography>
              </div>
            )}

            {loading ? (
              <div className="flex justify-center py-12">
                <Spinner className="h-12 w-12" style={{ color: colors.dimoPrimary }} />
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
                {techs
                  .filter(tech => tech.Certifs && tech.Certifs.length > 0)
                  .map((tech) => {
                  const mainCertif = getMainCertification(tech.Certifs);
                  const latestExpiry = getLatestExpiryDate(tech.Certifs);

                  return (
                    <Card 
                      key={tech.id} 
                      className="bg-gray-50 shadow-md border border-gray-100"
                      onClick={() => {
                        trackEvent('certification_view_technician', {
                          technicianId: tech.id,
                          technicianName: `${tech.prenom} ${tech.nom}`,
                          certificationCount: tech.Certifs?.length || 0
                        });
                      }}
                    >
                      <div className="p-6">
                        <div className="flex items-start gap-4">
                          <div className="shrink-0">
                            <UserCircleIcon className="h-12 w-12" style={{ color: colors.dimoPrimary }} />
                          </div>
                          <div className="flex-grow min-w-0">
                            <Typography variant="h6" className="text-black mb-1">
                              {tech.prenom} {tech.nom}
                            </Typography>
                            {mainCertif && (
                              <Typography variant="small" className="text-gray-600 mb-2">
                                N° {mainCertif.numero_certif_short}
                              </Typography>
                            )}
                            {latestExpiry && (
                              <Typography variant="small" className="text-gray-500 text-xs">
                                Certifié jusqu'au {latestExpiry.toLocaleDateString('fr-FR')}
                              </Typography>
                            )}
                          </div>
                        </div>

                        {tech.Certifs && tech.Certifs.length > 0 && (
                          <div className="mt-4">
                            <div className="flex flex-wrap gap-2">
                              {tech.Certifs.map((certif, index) => (
                                <span
                                  key={index}
                                  className="px-3 py-1 bg-white text-gray-700 rounded-xl text-xs flex items-center border border-gray-200"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    trackEvent('certification_view_specific', {
                                      technicianId: tech.id,
                                      certificationType: certif.type_certif
                                    });
                                  }}
                                >
                                  <DocumentCheckIcon className="h-3.5 w-3.5 mr-1" style={{ color: colors.dimoPrimary }} />
                                  {certif.type_certif}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </Card>
                  );
                })}

                {techs.length === 0 && !loading && (
                  <div className="col-span-full">
                    <Card className="p-12 text-center bg-gray-50 border border-gray-100">
                      <Typography className="text-gray-700 text-lg">
                        Aucun technicien trouvé
                      </Typography>
                    </Card>
                  </div>
                )}
              </div>
            )}
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default Certification; 