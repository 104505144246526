import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import { PropertiesProvider } from "./contexts/PropertiesContext.js";

import Diagnostics from "./pages/Diagnostics";
import Documents from "./pages/Documents";
import Invoices from "./pages/Invoices";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import DPE from "./pages/DPE";
import FiscalId from "./pages/FiscalId";
import Appointment from "./pages/Appointment";
import Quote from "./pages/Quote";
import SignQuote from "./pages/SignQuote";
import TechnicalHelp from "./pages/TechnicalHelp.js";
import Support from "./pages/Support.js";
import ConsentShare from "./pages/ConsentShare.js";
import Deals from "./pages/Deals";
import Suggestion from "./pages/Suggestion";
import Certification from "./pages/Certification";
import Reviews from "./pages/Reviews";
import Referral from "./pages/Referral";
import SimulationDPE from "./pages/SimulationDPE";

function App() {
  return (
    <ThemeProvider>
      <Helmet>
        <title>Espace client - Dimo Diagnostic</title>
        <meta name="description" content="Bienvenue sur l'application Dimo Diagnostic." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://client.dimo-diagnostic.net" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-LEBBYZ33NK"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LEBBYZ33NK');
          `}
        </script>
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/consent/:token/:action" element={<ConsentShare />} />
          <Route path="/public-quote/:token" element={<Quote isPublic={true} />} />
          <Route path="/public-quote/:token/sign" element={<SignQuote isPublic={true} />} />
          <Route
            path="/*"
            element={
              <PropertiesProvider>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/diagnostics/:id" element={<Diagnostics />} />
                  <Route path="/diagnostics" element={<Diagnostics />} />
                  <Route path="/fiscal-id/:id" element={<FiscalId />} />
                  <Route path="/fiscal-id" element={<FiscalId />} />
                  <Route path="/dpe/:id" element={<DPE />} />
                  <Route path="/dpe" element={<DPE />} />
                  <Route path="/appointment/:id" element={<Appointment />} />
                  <Route path="/appointment" element={<Appointment />} />
                  <Route path="/documents/:id" element={<Documents />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/quote/:id/sign" element={<SignQuote isPublic={false} />} />
                  <Route path="/quote/:id" element={<Quote isPublic={false} />} />
                  <Route path="/quote" element={<Quote isPublic={false} />} />
                  <Route path="/technical-help" element={<TechnicalHelp />} />
                  <Route path="/suggestion" element={<Suggestion />} />
                  <Route path="/support/:id" element={<Support />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/deals" element={<Deals />} />
                  <Route path="/certification/:departement?" element={<Certification />} />
                  <Route path="/reviews" element={<Reviews />} />
                  <Route path="/referral" element={<Referral />} />
                  <Route path="/simulation-dpe" element={<SimulationDPE />} />
                  <Route path="*" element={<NotFound />} status={404} />
                </Routes>
              </PropertiesProvider>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;