import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Card } from "@material-tailwind/react";
import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import DiagnosticsContent from "../components/DiagnosticsContent";
import PrintModal from "../components/modals/PrintModal";
import PaymentModal from "../components/modals/PaymentModal";
import SendModal from "../components/modals/SendModal";
import ShareAccessModal from "../components/modals/ShareAccessModal";
import DownloadModal from "../components/modals/DownloadModal";
import TipModal from "../components/modals/TipModal";
import TipSuccessModal from "../components/modals/TipSuccessModal";
import TipErrorModal from "../components/modals/TipErrorModal";
import axiosInstance from '../utils/axiosInstance';
import { Helmet } from 'react-helmet';
import { useProperties } from "../contexts/PropertiesContext";
import RecommendedDeals from "../components/deals/RecommendedDeals";
import { trackEvent } from "../utils/analytics";
import ReactDOM from 'react-dom';

function LoadingSkeleton() {
  return (
    <div className="mb-6">
      {/* Première carte - Informations du diagnostic */}
      <Card className="p-6 shadow-md mb-6">
        <div className="space-y-6 animate-pulse">
          {/* Titre */}
          <div className="h-8 bg-gray-200 rounded w-1/3"></div>

          {/* Grille d'informations */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Première colonne - Dossier/Nom/Type */}
            <div className="space-y-4">
              {[...Array(3)].map((_, i) => (
                <div key={`col1-${i}`} className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              ))}
            </div>

            {/* Deuxième colonne - Date/Superficie/Année */}
            <div className="space-y-4">
              {[...Array(3)].map((_, i) => (
                <div key={`col2-${i}`} className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              ))}
            </div>

            {/* Troisième colonne - Adresse/Diagnostics */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              </div>
              <div className="flex items-start gap-2">
                <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                <div className="flex-1">
                  <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                  <div className="flex flex-wrap gap-2">
                    {[...Array(3)].map((_, i) => (
                      <div key={`diag-${i}`} className="h-4 bg-gray-200 rounded w-20"></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* Structure en colonnes sur desktop */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Colonne gauche - Rapport et Bons plans */}
        <div className="lg:w-3/5 flex flex-col gap-6 order-1">
          {/* Bloc Rapport de diagnostics */}
          <Card className="p-6 shadow-md w-full">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/3"></div>
                </div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                <div className="flex gap-4">
                  <div className="h-10 bg-gray-200 rounded w-32"></div>
                  <div className="h-10 bg-gray-200 rounded w-32"></div>
                </div>
              </div>
            </div>
          </Card>

          {/* Bloc Amélioration DPE (mobile) */}
          <Card className="p-6 shadow-md flex flex-col lg:hidden order-2">
            <div className="animate-pulse">
              <div className="h-6 bg-gray-200 rounded w-2/5 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="aspect-[4/3] bg-gray-200 rounded mb-6"></div>
              <div className="flex justify-center">
                <div className="h-10 bg-gray-200 rounded w-40"></div>
              </div>
            </div>
          </Card>

          {/* Bloc Bons plans */}
          <div className="order-3 w-full -mb-6 lg:mb-0">
            <Card className="p-6 shadow-md">
              <div className="animate-pulse">
                <div className="h-6 bg-gray-200 rounded w-2/5 mb-6"></div>
                <div className="flex gap-4 overflow-x-hidden">
                  {[...Array(3)].map((_, i) => (
                    <div key={`deal-${i}`} className="flex-shrink-0 w-[280px]">
                      <div className="aspect-[4/3] bg-gray-200 rounded mb-4"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
                      <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                      <div className="h-10 bg-gray-200 rounded w-full"></div>
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          </div>
        </div>

        {/* Colonne droite - Amélioration DPE (desktop) */}
        <Card className="lg:w-2/5 p-6 shadow-md hidden lg:flex flex-col order-2">
          <div className="animate-pulse">
            <div className="h-6 bg-gray-200 rounded w-2/5 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
            <div className="aspect-[4/3] bg-gray-200 rounded mb-6"></div>
            <div className="flex justify-center">
              <div className="h-10 bg-gray-200 rounded w-40"></div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default function Diagnostics() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { properties, technicians, loading: propertiesLoading, refreshProperties } = useProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isShareAccessModalOpen, setIsShareAccessModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isTipModalOpen, setIsTipModalOpen] = useState(false);
  const [isTipSuccessModalOpen, setIsTipSuccessModalOpen] = useState(false);
  const [isTipErrorModalOpen, setIsTipErrorModalOpen] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [documentsWithoutInvoice, setDocumentsWithoutInvoice] = useState(null);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [isChangingProperty, setIsChangingProperty] = useState(false);
  const [tipAmount, setTipAmount] = useState(null);
  const recommendedDealsRef = useRef(null);

  // Injecter les bons plans dans le conteneur approprié
  useEffect(() => {
    const injectRecommendedDeals = () => {
      const container = document.getElementById('recommended-deals-container');
      if (container && !container.hasChildNodes()) {
        const dealsElement = document.createElement('div');
        container.appendChild(dealsElement);
        
        ReactDOM.render(
          <RecommendedDeals source="diagnostics" />,
          dealsElement
        );
        
        // Garder une référence pour le nettoyage
        recommendedDealsRef.current = dealsElement;
      }
    };

    // Essayer d'injecter immédiatement, puis à intervalles réguliers
    // jusqu'à ce que le conteneur soit disponible
    const intervalId = setInterval(() => {
      injectRecommendedDeals();
    }, 200);

    // Nettoyer après 5 secondes pour éviter des tentatives infinies
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 5000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
      
      // Nettoyer le ReactDOM si nécessaire
      if (recommendedDealsRef.current) {
        ReactDOM.unmountComponentAtNode(recommendedDealsRef.current);
      }
    };
  }, [selectedProperty]);

  // Vérifier les paramètres d'URL pour les actions liées aux pourboires
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    
    // Vérifier si un pourboire a été effectué avec succès
    if (searchParams.has('tip_success') && searchParams.has('amount')) {
      const amount = searchParams.get('amount');
      setTipAmount(amount);
      setIsTipSuccessModalOpen(true);
      
      // Tracker l'événement
      trackEvent('tip_success_redirect', {
        propertyId: id,
        amount: amount
      });
      
      // Nettoyer l'URL pour éviter de réafficher le modal lors des rechargements
      navigate(`/diagnostics/${id}`, { replace: true });
    }
    
    // Vérifier s'il y a eu une erreur avec le pourboire
    if (searchParams.has('tip_error')) {
      setIsTipErrorModalOpen(true);
      
      // Tracker l'événement
      trackEvent('tip_error_redirect', {
        propertyId: id
      });
      
      // Nettoyer l'URL pour éviter de réafficher le modal lors des rechargements
      navigate(`/diagnostics/${id}`, { replace: true });
    }
  }, [location, navigate, id]);

  useEffect(() => {
    if (!propertiesLoading && !isChangingProperty) {
      if (id) {
        const propertyFromSummary = properties.find(prop => prop.id.toString() === id);
        if (propertyFromSummary) {
          setSelectedProperty(propertyFromSummary);
          
          // Tracking de la sélection d'une propriété spécifique
          trackEvent('diagnostic_view_property', {
            propertyId: propertyFromSummary.id
          });
        } else {
          navigate("/diagnostics", { replace: true });
          
          // Tracking de la redirection (propriété non trouvée)
          trackEvent('diagnostic_redirect_property_not_found', {
            attemptedId: id
          });
        }
      } else if (properties.length > 0) {
        navigate(`/diagnostics/${properties[0].id}`, { replace: true });
        
        // Tracking de la redirection automatique vers la première propriété
        trackEvent('diagnostic_auto_select_property', {
          propertyId: properties[0].id
        });
      }
    }
  }, [id, properties, propertiesLoading, isChangingProperty, navigate]);

  useEffect(() => {
    let isCancelled = false;

    const fetchDocuments = async () => {
      if (!selectedProperty || isChangingProperty) return;

      setIsLoadingDocuments(true);
      
      trackEvent('diagnostic_load_documents', {
        propertyId: selectedProperty.id
      });

      try {
        const response = await axiosInstance.get(`/documents/${selectedProperty.id}`);
        if (!isCancelled) {
          setDocuments(response.data.documents || []);
          const documentsWithoutInvoice = response.data.documents.filter(doc => doc.type !== 'invoice');
          setDocumentsWithoutInvoice(documentsWithoutInvoice);
          
          trackEvent('diagnostic_documents_loaded', {
            propertyId: selectedProperty.id,
            documentCount: response.data.documents?.length || 0
          });
        }
      } catch (error) {
        if (!isCancelled) {
          if (error.response && error.response.status === 404) {
            setDocuments([]);
            setDocumentsWithoutInvoice([]);
          } else {
            console.error('Erreur lors de la récupération des documents:', error);
            
            trackEvent('diagnostic_documents_error', {
              propertyId: selectedProperty.id,
              error: error.message || 'unknown'
            });
          }
        }
      } finally {
        if (!isCancelled) {
          setIsLoadingDocuments(false);
        }
      }
    };

    fetchDocuments();

    return () => {
      isCancelled = true;
    };
  }, [selectedProperty?.id, isChangingProperty, selectedProperty]);

  const handlePrint = (printData) => {
    trackEvent('diagnostic_print', {
      propertyId: selectedProperty?.id,
      documentTypes: printData?.documentTypes || []
    });
    
    console.log("Print data:", printData);
  };

  const handleSend = (sendData) => {
    trackEvent('diagnostic_send', {
      propertyId: selectedProperty?.id,
      recipientCount: sendData?.recipients?.length || 0
    });
    
    console.log("Send data:", sendData);
  };

  const handleShareAccess = (shareData) => {
    trackEvent('diagnostic_share_access', {
      propertyId: selectedProperty?.id,
      recipientEmail: shareData?.email ? 'provided' : 'not_provided'
    });
    
    console.log("Share access data:", shareData);
  };

  const handlePropertyChange = (newPropertyId) => {
    if (!propertiesLoading && selectedProperty && newPropertyId !== selectedProperty.id) {
      trackEvent('diagnostic_change_property', {
        fromPropertyId: selectedProperty.id,
        toPropertyId: newPropertyId
      });
      
      setIsChangingProperty(true);
      // Réinitialiser les documents avant de changer de propriété
      setDocuments(null);
      setDocumentsWithoutInvoice(null);
      setIsLoadingDocuments(true);
      
      navigate(`/diagnostics/${newPropertyId}`, { replace: true });
      
      // Attendre que la navigation soit terminée avant de désactiver le loading
      setTimeout(() => {
        setIsChangingProperty(false);
      }, 400);
    }
  };

  const handleCannotProvideDocument = async (type) => {
    trackEvent('diagnostic_cannot_provide_document', {
      propertyId: selectedProperty?.id,
      documentType: type
    });
    
    try {
      await axiosInstance.post(`/properties/${selectedProperty.id}/cannot-provide`, {
        type: type
      });
      refreshProperties();
    } catch (error) {
      console.error('Erreur lors de la notification de document non fourni:', error);
      
      trackEvent('diagnostic_cannot_provide_error', {
        propertyId: selectedProperty?.id,
        documentType: type,
        error: error.message || 'unknown'
      });
    }
  };

  const handleOpenPrintModal = () => {
    trackEvent('diagnostic_open_print_modal', { propertyId: selectedProperty?.id });
    setIsPrintModalOpen(true);
  };

  const handleOpenPaymentModal = () => {
    trackEvent('diagnostic_open_payment_modal', { propertyId: selectedProperty?.id });
    setIsPaymentModalOpen(true);
  };

  const handleOpenSendModal = () => {
    trackEvent('diagnostic_open_send_modal', { propertyId: selectedProperty?.id });
    setIsSendModalOpen(true);
  };

  const handleOpenShareAccessModal = () => {
    trackEvent('diagnostic_open_share_modal', { propertyId: selectedProperty?.id });
    setIsShareAccessModalOpen(true);
  };

  const handleOpenDownloadModal = () => {
    trackEvent('diagnostic_open_download_modal', { propertyId: selectedProperty?.id });
    setIsDownloadModalOpen(true);
  };

  const handleNavigateToSupport = () => {
    trackEvent('diagnostic_navigate_to_support', { propertyId: selectedProperty?.id });
    navigate("/support/" + selectedProperty.id);
  };

  const handleOpenTipModal = () => {
    trackEvent('diagnostic_open_tip_modal', { propertyId: selectedProperty?.id });
    setIsTipModalOpen(true);
  };

  // Trouver le technicien correspondant à la propriété sélectionnée
  const getTechnicianForProperty = (property) => {
    if (!property || !property.technicianId || !technicians) return null;
    return technicians.find(tech => tech.id === property.technicianId);
  };

  const handleCloseTipSuccessModal = () => {
    setIsTipSuccessModalOpen(false);
  };

  const handleCloseTipErrorModal = () => {
    setIsTipErrorModalOpen(false);
  };

  return (
    <Layout>
      <Helmet>
        <title>Diagnostics - Dimo Diagnostic</title>
        <meta name="description" content="Gérez vos dossier de diagnostics immobiliers" />
        <link rel="canonical" href={`https://client.dimo-diagnostic.net/diagnostics${id ? `/${id}` : ''}`} />
      </Helmet>

      {(propertiesLoading || isChangingProperty) ? (
        <LoadingSkeleton />
      ) : (
        <>
          {selectedProperty && (
            <DiagnosticsContent
              key={selectedProperty.id}
              propertyInfo={selectedProperty}
              onPrintClick={handleOpenPrintModal}
              onPayClick={handleOpenPaymentModal}
              onSendClick={handleOpenSendModal}
              onShareClick={handleOpenShareAccessModal}
              onDownloadClick={handleOpenDownloadModal}
              onHelpClick={handleNavigateToSupport}
              onCannotProvideDocument={handleCannotProvideDocument}
              isLoadingDocuments={isLoadingDocuments}
              hasReportDocuments={documents ? documents.some(doc => doc.type === 'report') : false}
              technician={selectedProperty ? getTechnicianForProperty(selectedProperty) : null}
              onTipClick={handleOpenTipModal}
              PropertySelectorComponent={
                properties.length > 1 ? (
                  <PropertySelector
                    selectedProperty={selectedProperty ? selectedProperty.id : null}
                    setSelectedProperty={handlePropertyChange}
                    properties={properties}
                    isLoading={false}
                  />
                ) : null
              }
            />
          )}
          <PrintModal
            isOpen={isPrintModalOpen}
            onClose={() => {
              trackEvent('diagnostic_close_print_modal', { propertyId: selectedProperty?.id });
              setIsPrintModalOpen(false);
            }}
            onPrint={handlePrint}
            documents={documentsWithoutInvoice}
            isLoadingDocuments={isLoadingDocuments}
            dealId={selectedProperty ? selectedProperty.id : null}
            printAsked={selectedProperty ? selectedProperty.print : false}
          />
          <PaymentModal
            isOpen={isPaymentModalOpen}
            onClose={() => {
              trackEvent('diagnostic_close_payment_modal', { propertyId: selectedProperty?.id });
              setIsPaymentModalOpen(false);
            }}
            dealId={selectedProperty ? selectedProperty.id : null}
            paymentLink={selectedProperty ? selectedProperty.paymentLink : null}
            technician={selectedProperty ? getTechnicianForProperty(selectedProperty) : null}
          />
          <SendModal
            isOpen={isSendModalOpen}
            onClose={() => {
              trackEvent('diagnostic_close_send_modal', { propertyId: selectedProperty?.id });
              setIsSendModalOpen(false);
            }}
            onSend={handleSend}
            documents={documents}
            isLoadingDocuments={isLoadingDocuments}
            dealId={selectedProperty ? selectedProperty.id : null}
          />
          <ShareAccessModal
            isOpen={isShareAccessModalOpen}
            onClose={() => {
              trackEvent('diagnostic_close_share_modal', { propertyId: selectedProperty?.id });
              setIsShareAccessModalOpen(false);
            }}
            dealId={selectedProperty ? selectedProperty.id : null}
            onShareAccess={handleShareAccess}
          />
          <DownloadModal
            isOpen={isDownloadModalOpen}
            amount={selectedProperty ? selectedProperty.amount : null}
            onClose={() => {
              trackEvent('diagnostic_close_download_modal', { propertyId: selectedProperty?.id });
              setIsDownloadModalOpen(false);
            }}
            documents={documents}
            isLoadingDocuments={isLoadingDocuments}
            dealId={selectedProperty ? selectedProperty.id : null}
            canReview={selectedProperty?.actions?.review || false}
            technician={selectedProperty ? getTechnicianForProperty(selectedProperty) : null}
          />
          <TipModal
            isOpen={isTipModalOpen}
            onClose={() => setIsTipModalOpen(false)}
            dealId={selectedProperty ? selectedProperty.id : null}
            technician={selectedProperty ? getTechnicianForProperty(selectedProperty) : null}
          />
          
          {/* Nouveaux modaux pour le succès/erreur de pourboire */}
          <TipSuccessModal
            isOpen={isTipSuccessModalOpen}
            onClose={handleCloseTipSuccessModal}
            dealId={selectedProperty ? selectedProperty.id : null}
            amount={tipAmount}
            technicien={selectedProperty ? getTechnicianForProperty(selectedProperty) : null}
          />
          <TipErrorModal
            isOpen={isTipErrorModalOpen}
            onClose={handleCloseTipErrorModal}
            dealId={selectedProperty ? selectedProperty.id : null}
          />

          {properties.length === 0 && !propertiesLoading && (
            <Card className="mb-6 shadow-md">
              <div className="p-6 text-center">
                <p className="text-red-500">Aucun dossier n'est actuellement disponible pour votre compte.</p>
              </div>
            </Card>
          )}
        </>
      )}
    </Layout>
  );
}

