import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import axios from '../utils/axiosInstance';
import ChatWidget from '../components/ElfsightWidget';
import { useLocation } from 'react-router-dom';

const PropertiesContext = createContext();

export function PropertiesProvider({ children }) {
  const [state, setState] = useState({
    properties: [],
    quotes: [],
    technicians: [],
    loading: true,
    error: null
  });
  const location = useLocation();

  const fetchProperties = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      const response = await axios.get('/properties');
      setState(prev => ({
        ...prev,
        properties: response.data.properties || [],
        quotes: response.data.quotes || [],
        technicians: response.data.technicians || [],
        loading: false,
        error: null
      }));
    } catch (err) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: 'Une erreur est survenue lors de la récupération des dossiers, merci de réessayer plus tard'
      }));
      console.error('Error fetching properties:', err);
    }
  }, []);

  const refreshProperties = useCallback(() => {
    fetchProperties();
  }, [fetchProperties]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties, location.pathname]);

  const value = useMemo(() => ({
    ...state,
    refreshProperties
  }), [state, refreshProperties]);

  return (
    <PropertiesContext.Provider value={value}>
      {children}
      <ChatWidget />
    </PropertiesContext.Provider>
  );
}

export function useProperties() {
  const context = useContext(PropertiesContext);
  if (context === undefined) {
    throw new Error('useProperties must be used within a PropertiesProvider');
  }
  return context;
}