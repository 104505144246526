/**
 * Utilitaire pour envoyer des événements à Google Analytics 4
 * 
 * Nomenclature à suivre pour les noms d'événements: page_action_detail
 * Exemples:
 * - diagnostic_download_report
 * - diagnostic_print_report
 * - dpe_submit_form
 * - document_upload_file
 * - referral_submit_form
 * - deal_click_offer
 * - navigation_change_property
 * 
 * @param {string} eventName - Nom de l'événement suivant la nomenclature
 * @param {object} params - Paramètres (garder simple, principalement propertyId)
 */
export const trackEvent = (eventName, params = {}) => {
  // Vérification que gtag existe
  if (typeof window.gtag === 'function') {
    // Envoi direct à GA4
    window.gtag('event', eventName, params);
    
    // Log en développement pour faciliter le débogage
    console.log('🔍 GA Event :', { event: eventName, ...params });
  } else {
    // Log d'erreur si gtag n'est pas disponible
    console.warn('gtag n\'est pas disponible. L\'événement n\'a pas été envoyé :', eventName);
  }
};