import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography, Button, Radio, Textarea, Stepper, Step } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import axiosInstance from "../utils/axiosInstance";
import { trackEvent } from "../utils/analytics";
import { ComputerDesktopIcon, DocumentTextIcon, ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

export default function TechnicalHelp() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [issueType, setIssueType] = useState("");
  const [specificIssue, setSpecificIssue] = useState("");
  const [issueDetails, setIssueDetails] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Première étape: Classification du type de problème (seulement 2 options)
  const issueTypes = [
    {
      id: "content",
      label: "Problème avec le contenu de mon diagnostic",
      description: "Questions sur les résultats, informations manquantes ou incorrectes dans le rapport...",
      icon: DocumentTextIcon,
      redirectToSupport: true
    },    
    {
      id: "technical",
      label: "Problème technique avec l'espace client",
      description: "Interface qui ne fonctionne pas correctement, boutons qui ne répondent pas, erreurs de chargement...",
      icon: ComputerDesktopIcon
    }
  ];

  // Deuxième étape: Problèmes techniques spécifiques à l'application
  const technicalIssues = [
    "Je ne peux pas télécharger mon diagnostic",
    "Je ne peux pas ajouter des documents demandés",
    "Je rencontre des erreurs lors du paiement",
    "La page s'affiche mal sur mon appareil",
    "Autre problème technique"
  ];

  // Validation de chaque étape
  const validateStep = () => {
    setError("");
    
    if (activeStep === 0 && !issueType) {
      setError("Veuillez sélectionner un type de problème");
      return false;
    }
    
    if (activeStep === 1 && !specificIssue) {
      setError("Veuillez sélectionner un problème spécifique");
      return false;
    }
    
    if (activeStep === 1 && !issueDetails.trim()) {
      setError("Veuillez décrire votre problème en détail pour nous aider à le résoudre");
      return false;
    }
    
    return true;
  };

  // Navigation entre les étapes
  const handleNext = () => {
    if (validateStep()) {
      // Si l'utilisateur choisit un problème lié au contenu, rediriger vers la page support
      if (issueType === "content") {
        trackEvent('techhelp_redirect_to_support', {
          reason: 'content_issue'
        });
        navigate("/support");
        return;
      }
      
      // Tracking de la progression
      trackEvent('techhelp_step_complete', {
        step: activeStep,
        selection: activeStep === 0 ? issueType : specificIssue
      });
      
      // Faire défiler la page vers le haut
      window.scrollTo(0, 0);
      
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    trackEvent('techhelp_step_back', {
      fromStep: activeStep
    });
    setActiveStep(activeStep - 1);
  };

  const handleSelectIssueType = (type) => {
    trackEvent('techhelp_select_issue_type', {
      issueType: type
    });
    setIssueType(type);
  };

  const handleSelectSpecificIssue = (issue) => {
    trackEvent('techhelp_select_specific_issue', {
      specificIssue: issue
    });
    setSpecificIssue(issue);
    // Vider le champ de détails lorsqu'on change de problème spécifique
    setIssueDetails("");
  };

  const handleReturnToDashboard = () => {
    trackEvent('techhelp_return_dashboard', {});
    navigate("/dashboard");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateStep()) {
      return;
    }
    
    setIsSubmitting(true);

    // Tracking avant soumission
    trackEvent('techhelp_submit', {
      issueType: issueType,
      specificIssue: specificIssue,
      detailsLength: issueDetails.length
    });

    try {
      // Préparation des données à envoyer
      await axiosInstance.post("/technical-help", {
        issueType: issueType,
        issue: specificIssue,
        details: issueDetails
      });
      
      // Tracking succès
      trackEvent('techhelp_submit_success', {});
      
      setSuccess(true);
    } catch (error) {
      // Tracking erreur
      trackEvent('techhelp_submit_error', {
        error: error.message
      });
      
      console.error("Erreur lors de l'envoi du problème technique:", error);
      setError("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Rendu du contenu en fonction de l'étape active
  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className="space-y-6">
            <Typography className="font-medium text-gray-800">
              Choisissez le type de problème que vous rencontrez :
            </Typography>
            
            <div className="space-y-4">
              {issueTypes.map((type) => (
                <div 
                  key={type.id}
                  className={`p-4 border rounded-lg cursor-pointer transition-all ${
                    issueType === type.id 
                      ? `border-${colors.dimoPrimary} bg-green-50 shadow-md` 
                      : "border-gray-200 hover:border-gray-300 hover:bg-gray-50"
                  }`}
                  onClick={() => handleSelectIssueType(type.id)}
                  style={issueType === type.id ? {borderColor: colors.dimoPrimary, backgroundColor: '#f0f9f6'} : {}}
                >
                  <div className="flex items-start gap-3">
                    <div className="mt-1">
                      <type.icon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
                    </div>
                    <div>
                      <Typography className="font-medium text-black">
                        {type.label}
                      </Typography>
                      <Typography variant="small" color="gray" className="mt-1">
                        {type.description}
                      </Typography>
                      {type.redirectToSupport && (
                        <Typography variant="small" className="mt-1" style={{ color: colors.dimoDark3 }}>
                          Ce type de problème sera traité par notre support
                        </Typography>
                      )}
                    </div>
                    <div className="ml-auto">
                      <Radio
                        checked={issueType === type.id}
                        onChange={() => {}}
                        className="pointer-events-none"
                        color="green"
                        containerProps={{ style: { color: colors.dimoPrimary } }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      
      case 1:
        return (
          <div className="space-y-6">
            <Typography className="font-medium text-gray-800">
              Précisez le problème technique que vous rencontrez :
            </Typography>
            
            <div className="space-y-4">
              {technicalIssues.map((issue, index) => (
                <div key={index} className="space-y-3">
                  <div 
                    className={`p-4 border rounded-lg cursor-pointer transition-all`}
                    onClick={() => handleSelectSpecificIssue(issue)}
                    style={specificIssue === issue ? 
                      {borderColor: colors.dimoPrimary, backgroundColor: '#f0f9f6'} : 
                      {borderColor: '#e5e7eb'}}
                  >
                    <div className="flex items-start gap-3">
                      <div className="mt-1">
                        <ComputerDesktopIcon className="h-6 w-6" style={{ color: colors.dimoPrimary }} />
                      </div>
                      <div>
                        <Typography className="font-medium text-black">
                          {issue}
                        </Typography>
                      </div>
                      <div className="ml-auto">
                        <Radio
                          checked={specificIssue === issue}
                          onChange={() => {}}
                          className="pointer-events-none"
                          containerProps={{ style: { color: colors.dimoPrimary } }}
                        />
                      </div>
                    </div>
                  </div>
                  
                  {specificIssue === issue && (
                    <div className="p-4 border border-gray-200 rounded-lg bg-gray-50 ml-8">
                      <Typography className="font-medium text-gray-800 mb-2">
                        Décrivez votre problème en détail :
                      </Typography>
                      <Typography variant="small" color="gray" className="mb-3">
                        Plus vous fournissez de détails, plus il sera facile pour nous de résoudre votre problème.
                        Précisez les étapes que vous avez suivies, le message d'erreur éventuel, et sur quel appareil vous naviguez.
                      </Typography>
                      <Textarea
                        label="Description détaillée du problème"
                        value={issueDetails}
                        onChange={(e) => {
                          setIssueDetails(e.target.value);
                        }}
                        className="mt-2"
                        labelProps={{
                          style: { color: colors.dimoDark }
                        }}
                        required
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      
      case 2:
        return (
          <div className="space-y-6">
            <Typography className="font-medium text-gray-800">
              Confirmez les informations de votre demande :
            </Typography>
            
            <Card className="p-4 bg-gray-50">
              <Typography className="font-medium mb-2">Type de problème :</Typography>
              <Typography className="mb-4">
                {issueTypes.find(t => t.id === issueType)?.label}
              </Typography>
              
              <Typography className="font-medium mb-2">Problème spécifique :</Typography>
              <Typography className="mb-4">
                {specificIssue}
              </Typography>
              
              <Typography className="font-medium mb-2">Description détaillée :</Typography>
              <Typography className="whitespace-pre-wrap mb-4 p-3 bg-white border border-gray-200 rounded">
                {issueDetails}
              </Typography>
              
              <Typography variant="small" className="italic" style={{ color: colors.dimoDark3 }}>
                Ces informations nous aideront à résoudre votre problème technique plus rapidement.
              </Typography>
            </Card>
          </div>
        );
        
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Aide technique - Espace client Dimo Diagnostic</title>
        <meta name="description" content="Signalez un problème technique avec votre espace client Dimo Diagnostic." />
      </Helmet>
      <Card className="mb-6 shadow-md">
        <div className="p-6">
          <Typography variant="h3" className="mb-6" style={{ color: colors.dimoPrimary }}>
            Assistance technique
          </Typography>
          
          {success ? (
            <div className="text-center py-8">
              <div className="w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4" 
                   style={{ backgroundColor: '#e6f7f1' }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     style={{ color: colors.dimoPrimary }}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <Typography variant="h5" className="mb-4 text-gray-800">
                Votre problème technique a bien été signalé
              </Typography>
              <Typography color="gray" className="mb-6 max-w-md mx-auto">
                Merci pour votre signalement. Notre équipe technique traitera votre demande dans les plus brefs délais.
              </Typography>
              <Button
                style={{ backgroundColor: colors.dimoPrimary }}
                onClick={handleReturnToDashboard}
                className="mt-2"
              >
                Retour à l'accueil
              </Button>
            </div>
          ) : (
            <div>
              <div className="mb-8">
                <Stepper
                  activeStep={activeStep}
                  lineClassName="bg-gray-200"
                  activeLineClassName="bg-green-500"
                >
                  <Step 
                    className={activeStep >= 0 ? "" : "bg-gray-300"}
                    style={activeStep >= 0 ? {backgroundColor: colors.dimoPrimary} : {}}
                  >
                    <span className="text-white font-bold">1</span>
                  </Step>
                  <Step 
                    className={activeStep >= 1 ? "" : "bg-gray-300"}
                    style={activeStep >= 1 ? {backgroundColor: colors.dimoPrimary} : {}}
                  >
                    <span className="text-white font-bold">2</span>
                  </Step>
                  <Step 
                    className={activeStep >= 2 ? "" : "bg-gray-300"}
                    style={activeStep >= 2 ? {backgroundColor: colors.dimoPrimary} : {}}
                  >
                    <span className="text-white font-bold">3</span>
                  </Step>
                </Stepper>
              </div>
              
              <div className="mb-6">
                <div className="bg-blue-50 border-l-4 p-4 mb-6" style={{borderLeftColor: colors.dimoPrimary}}>
                  <Typography style={{ color: colors.dimoDark }}>
                    <span className="font-bold">Assistance technique</span> : Ce formulaire est destiné <span className="font-bold">uniquement</span> aux problèmes techniques avec l'espace client.
                    Pour les questions sur le contenu de votre diagnostic, veuillez utiliser la page Contact & Aide.
                  </Typography>
                </div>
                
                {renderStepContent()}
                
                {error && (
                  <div className="mt-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
                    {error}
                  </div>
                )}
              </div>
              
              <div className="flex justify-between mt-8">
                {activeStep > 0 ? (
                  <Button
                    variant="outlined"
                    style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
                    onClick={handleBack}
                    className="flex items-center gap-2"
                  >
                    <ArrowLeftIcon className="h-4 w-4" /> Retour
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
                    onClick={() => navigate("/support")}
                    className="flex items-center gap-2"
                  >
                    Page Support
                  </Button>
                )}
                
                {activeStep < 2 ? (
                  <Button
                    style={{ backgroundColor: colors.dimoPrimary }}
                    onClick={handleNext}
                    className="flex items-center gap-2"
                  >
                    Continuer <ArrowRightIcon className="h-4 w-4" />
                  </Button>
                ) : (
                  <Button
                    style={{ backgroundColor: colors.dimoPrimary }}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Envoi en cours..." : "Envoyer ma demande"}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Card>
    </Layout>
  );
}