import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Typography,
  IconButton,
  Spinner,
  Card,
  Input,
  Alert,
  Checkbox,
} from "@material-tailwind/react"
import {
  ArrowDownTrayIcon,
  DocumentTextIcon,
  XMarkIcon,
  DocumentDuplicateIcon,
  EyeIcon,
  CreditCardIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  GiftIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline"
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid"
import axios from "axios"
import axiosInstance from "../../utils/axiosInstance"
import { colors } from "../../utils/colors";
import { trackEvent } from "../../utils/analytics";

const TipOption = ({ amount, percent, isCustom = false, selected, onSelect, isPopular = false, handleTipSubmit, isDefaultAmount = false, isProcessing }) => (
  <div 
    className="relative w-full mb-3"
    onClick={() => {
      onSelect(amount);
      if (!isCustom) {
        handleTipSubmit(amount);
      }
    }}
  >
    <div className={`relative overflow-hidden rounded-lg p-4 border transition-all duration-300 ${
      selected ? 'border-2 border-green-500 bg-green-50/50 shadow-lg transform scale-105' : 
      'border-gray-200 hover:border-gray-300 hover:shadow-md hover:bg-gray-50/50'
    } ${isPopular ? 'ring-2 ring-green-400 ring-offset-1' : ''}`}>
      {isPopular && (
        <div className="absolute -top-1 -right-1">
          <div className="bg-green-500 text-white text-xs py-1 px-2 rounded-bl-lg rounded-tr-lg font-medium flex items-center">
            <SparklesIcon className="h-3 w-3 mr-1" />
            Populaire
          </div>
        </div>
      )}
      
      <div className="flex flex-col items-center justify-center">
        {isProcessing && selected ? (
          <Spinner className="h-6 w-6" />
        ) : (
          <>
            <Typography variant="h5" className="font-bold">
              {isDefaultAmount ? `${amount}€` : (percent || "Personnalisé")}
            </Typography>
            <Typography variant="small" className="text-gray-600">
              {amount && !isDefaultAmount ? `${amount}€` : ""}
            </Typography>
          </>
        )}
      </div>
    </div>
  </div>
);

export default function DownloadModal({ isOpen, onClose, documents = [], isLoadingDocuments, dealId, canReview = false, technician, amount }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [downloadingDoc, setDownloadingDoc] = useState(null)
  const [isArchiveOpen, setIsArchiveOpen] = useState(false)
  const [invitationLink, setInvitationLink] = useState(null)
  const [selectedTip, setSelectedTip] = useState(null);
  const [customTip, setCustomTip] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [tipError, setTipError] = useState(false);
  const [showTipSystemError, setShowTipSystemError] = useState(false);
  const [tipAttempted, setTipAttempted] = useState(false);
  const [neverShowTip, setNeverShowTip] = useState(false);
  const [isProcessingTip, setIsProcessingTip] = useState(false);

  // Calculer les montants de pourboire suggérés
  const defaultAmount = amount || 100;
  const smallPercent = Math.round(defaultAmount * 0.05);
  const mediumPercent = Math.round(defaultAmount * 0.10);
  const largePercent = Math.round(defaultAmount * 0.15);
  
  // Vérifier si on doit utiliser les montants par défaut
  const useSmallDefault = smallPercent < 5;
  const useMediumDefault = mediumPercent < 10;
  const useLargeDefault = largePercent < 15;
  
  const tipAmounts = {
    small: useSmallDefault ? 5 : smallPercent,
    medium: useMediumDefault ? 10 : mediumPercent,
    large: useLargeDefault ? 15 : largePercent
  };

  useEffect(() => {
    if (isOpen) {
      // Vérifier si l'utilisateur a déjà vu l'étape pourboire
      const tipStepSeen = document.cookie.split('; ').find(row => row.startsWith('tipStepSeen='));
      
      if (tipStepSeen && tipStepSeen.split('=')[1] === 'true') {
        // Si l'utilisateur a déjà vu l'étape pourboire, passer directement à l'étape 2
        setCurrentStep(2);
      } else {
        // Sinon, commencer par l'étape 1 (pourboire) et tracker l'événement
        setCurrentStep(1);
        trackEvent('modal_tip_step_shown', {
          propertyId: dealId
        });
      }
      
      setSelectedTip(null);
      setCustomTip("");
      setShowCustomInput(false);
      setTipError(false);
      setShowTipSystemError(false);
      setTipAttempted(false);
      setNeverShowTip(false);
    }
  }, [isOpen, dealId]);

  useEffect(() => {
    if (canReview && dealId) {
      // Récupérer le lien d'invitation Trustpilot
      const fetchInvitationLink = async () => {
        try {
          const response = await axios.get(`/api/trustpilot/invitation/${dealId}`);
          if (response.data.success) {
            setInvitationLink(response.data.invitationLink);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération du lien d\'invitation:', error);
        }
      };
      fetchInvitationLink();
    }
  }, [dealId, canReview]);

  const handleTipSubmit = async (tipAmount) => {
    if (tipAmount === 'custom' && !customTip) {
      return;
    }

    setIsProcessingTip(true);

    // Conversion du montant en nombre avec gestion des décimales
    const amount = tipAmount === 'custom' ? 
      parseFloat(customTip.toString().replace(',', '.')) : 
      tipAmount;
    
    // Vérifier que le montant est au moins 1€
    if (amount < 1) {
      setIsProcessingTip(false);
      alert("Le montant du pourboire doit être d'au moins 1€");
      return;
    }
    
    setTipAttempted(true);
    
    if (amount) {
      try {
        const response = await axiosInstance.post("/tip", {
          amount: amount,
          dealId,
          source: 'Téléchargement rapport'
        });
        
        // Tracking du pourboire
        trackEvent('modal_tip_submitted', { 
          propertyId: dealId,
          amount: amount
        });

        // Rediriger vers l'URL de paiement Stripe
        if (response.data.url) {
          window.location.href = response.data.url;
          return;
        }
      } catch (error) {
        console.error("Erreur lors de l'envoi du pourboire:", error);
        setTipError(true);
        setIsProcessingTip(false);
        
        // Tracking de l'erreur
        trackEvent('modal_tip_error', { 
          propertyId: dealId,
          amount: amount
        });
      }
    }
    
    // Si la case "Ne plus afficher" est cochée, définir le cookie
    if (neverShowTip) {
      document.cookie = "tipStepSeen=true; max-age=5259600; path=/"; // 2 mois
    }
    
    // Passer à l'étape suivante (seulement si nous n'avons pas redirigé vers Stripe)
    window.scrollTo(0, 0);
    setCurrentStep(2);
    setShowTipSystemError(true);
  };

  const handleSubmitCustomTip = () => {
    if (customTip) {
      // Conversion avec gestion de la virgule ou du point comme séparateur décimal
      const amount = parseFloat(customTip.toString().replace(',', '.'));
      
      // Vérification du montant minimum
      if (amount < 1) {
        alert("Le montant du pourboire doit être d'au moins 1€");
        return;
      }
      
      handleTipSubmit('custom');
    }
  };

  const handleSkipTip = () => {
    // Si la case "Ne plus afficher" est cochée, définir le cookie
    if (neverShowTip) {
      document.cookie = "tipStepSeen=true; max-age=5259600; path=/"; // 2 mois
    }
    
    // Tracking de l'action "Passer"
    trackEvent('modal_tip_skipped', { 
      propertyId: dealId
    });
    
    setCurrentStep(2);
  };

  const handleDownload = (doc) => {
    // Tracking du téléchargement
    trackEvent('modal_download_document', { 
      propertyId: dealId,
      documentType: doc.type,
      documentName: doc.name
    });
    
    setDownloadingDoc(doc.id)
    window.open(doc.downloadLink || doc.file, "_blank")
    setTimeout(() => setDownloadingDoc(null), 1000)
  }

  const handleConsult = (doc) => {
    // Tracking de la consultation
    trackEvent('modal_view_document', { 
      propertyId: dealId,
      documentType: doc.type,
      documentName: doc.name
    });
    
    window.open(doc.webViewLink || doc.file, "_blank")
  }

  const handleClose = (e) => {
    e?.stopPropagation?.();
    onClose();
  };

  const handleReviewParticipation = () => {
    // Tracking de la participation à l'avis
    trackEvent('modal_review_participation_clicked', { 
      propertyId: dealId
    });
    
    window.open(invitationLink, '_blank');
  };

  const renderDocumentIcon = (type) => {
    return type === "report" ? (
      <DocumentDuplicateIcon className="h-10 w-10" style={{ color: colors.dimoPrimary }} />
    ) : (
      <DocumentTextIcon className="h-10 w-10" style={{ color: colors.dimoPrimary }} />
    )
  }

  const renderActionButtons = (doc) => {
    const isInvoice = doc.type === "invoice"
    const isUnpaidInvoice = isInvoice && doc.status !== "paid"

    return (
      <div className="flex flex-col md:flex-row items-center gap-2 w-full md:w-auto mt-2 md:mt-0">
        {isInvoice && (
          <div className="flex items-center gap-2 mr-2">
            <CheckCircleIcon className={`h-4 w-4 ${doc.status === "paid" ? "text-green-500" : "text-orange-500"}`} />
            <Typography variant="small" className={doc.status === "paid" ? "text-green-500" : "text-orange-500"}>
              {doc.status === "paid" ? "Payée" : "En attente"}
            </Typography>
          </div>
        )}
        {isUnpaidInvoice && doc.payLink && (
          <a href={doc.payLink} target="_blank" rel="noopener noreferrer" className="w-full md:w-auto">
            <Button
              size="sm"
              className="w-full flex items-center justify-center gap-2"
              style={{ backgroundColor: colors.dimoPrimary }}
            >
              <CreditCardIcon className="h-4 w-4" />
              Payer
            </Button>
          </a>
        )}
        <Button
          variant="outlined" 
          style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
          size="sm"
          className="w-full md:w-auto flex items-center justify-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-md hover:opacity-100"
          onClick={(e) => {
            e.preventDefault();
            handleConsult(doc);
          }}
        >
          <EyeIcon className="h-4 w-4" />
          Consulter
        </Button>
        <Button
          variant={isUnpaidInvoice ? "outlined" : "filled"}
          size="sm"
          className="w-full md:w-auto flex items-center justify-center gap-2"
          style={
            isUnpaidInvoice
              ? { borderColor: colors.dimoPrimary, color: colors.dimoPrimary }
              : { backgroundColor: colors.dimoPrimary }
          }
          onClick={(e) => {
            e.preventDefault();
            handleDownload(doc);
          }}
          disabled={downloadingDoc === doc.id}
        >
          {downloadingDoc === doc.id ? (
            <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
          ) : (
            <ArrowDownTrayIcon className="h-4 w-4" />
          )}
          {downloadingDoc === doc.id ? "..." : "Télécharger"}
        </Button>
      </div>
    )
  }

  const renderDocumentCard = (doc, index) => (
    <Card key={index} className="p-4 border border-gray-200 hover:border-gray-300 transition-all duration-300">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="flex items-start gap-4 flex-1">
          {renderDocumentIcon(doc.type)}
          <div className="flex-1 min-w-0">
            <Typography variant="h6" className="mb-1 text-gray-900 break-words">
              {doc.name}
            </Typography>
            {doc.amount && (
              <Typography variant="small" color="gray" className="mb-2">
                Montant: {doc.amount}€
              </Typography>
            )}
          </div>
        </div>
        {renderActionButtons(doc)}
      </div>
    </Card>
  )

  const activeDocuments = documents ? documents.filter((doc) => !doc.name.startsWith("[ARCHIVE]")) : []
  const archivedDocuments = documents ? documents.filter((doc) => doc.name.startsWith("[ARCHIVE]")) : []

  const renderStarRating = (rating = 4.8) => {
    // Arrondir à 0.5 près pour faciliter l'affichage
    const roundedRating = Math.round(rating * 2) / 2;
    const fullStars = Math.floor(roundedRating);
    const hasHalfStar = roundedRating % 1 !== 0;
    
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => {
          if (i < fullStars) {
            return <StarIconSolid key={`star-${i}`} className="h-4 w-4 text-yellow-400" />;
          } else if (i === fullStars && hasHalfStar) {
            return (
              <div key={`star-${i}`} className="relative h-4 w-4">
                <StarIconSolid className="absolute h-4 w-4 text-yellow-400 overflow-hidden" style={{ clipPath: "inset(0 50% 0 0)" }} />
                <StarIconSolid className="h-4 w-4 text-gray-300" />
              </div>
            );
          } else {
            return <StarIconSolid key={`star-${i}`} className="h-4 w-4 text-gray-300" />;
          }
        })}
        <span className="ml-1 text-xs font-medium text-gray-700">{rating}/5</span>
      </div>
    );
  };

  const renderTipStep = () => (
    <>
      <DialogHeader className="border-b-0 px-6 pt-5 pb-0">
        <div className="w-full flex justify-between items-center">
          <Typography variant="h5" className="font-bold text-gray-800">
            Remercier votre diagnostiqueur
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </div>
      </DialogHeader>
      <DialogBody className="p-0 overflow-y-auto">
        <div className="px-6 pt-2 pb-4">
          {/* Section Technicien - Plus compacte */}
          {technician && (
            <div className="bg-white rounded-lg p-4 mb-4 shadow-sm border border-gray-200">
              <div className="flex items-center gap-3">
                {technician.photoUrl ? (
                  <img 
                    src={technician.photoUrl} 
                    alt={`${technician.prenom} ${technician.nom}`}
                    className="w-16 h-16 rounded-full object-cover border-2 border-white shadow-sm"
                  />
                ) : (
                  <div className="w-16 h-16 rounded-full bg-blue-500 flex items-center justify-center border-2 border-white shadow-sm">
                    <Typography className="text-white text-lg font-bold">
                      {technician.prenom?.[0]}{technician.nom?.[0]}
                    </Typography>
                  </div>
                )}
                
                <div className="flex-1">
                  <Typography variant="h6" className="font-medium text-gray-800">
                    {technician.prenom} {technician.nom}
                  </Typography>
                  <Typography color="blue-gray" className="text-xs font-medium mb-1">
                    Votre diagnostiqueur
                  </Typography>
                  {renderStarRating(4.8)}
                </div>
              </div>
            </div>
          )}

          {/* Section Citation - Plus concise */}
          <div className="text-center mb-5">
            <Typography color="gray" className="text-sm mb-1">
              {technician ? 
                `Vous avez apprécié le service de ${technician.prenom} ? Laissez-lui un pourboire pour le remercier.` :
                "Vous avez apprécié notre service ? Laissez un pourboire pour remercier votre diagnostiqueur."
              }
            </Typography>
            <Typography color="green" className="text-xs font-medium">
              100% du pourboire est directement reversé au diagnostiqueur
            </Typography>
          </div>
          
          {/* Options de pourboire */}
          <div className="grid grid-cols-3 gap-3 mb-4">
            <TipOption 
              amount={tipAmounts.small} 
              percent="5%"
              selected={selectedTip === tipAmounts.small} 
              onSelect={setSelectedTip}
              handleTipSubmit={handleTipSubmit}
              isDefaultAmount={useSmallDefault}
              isProcessing={isProcessingTip}
            />
            <TipOption 
              amount={tipAmounts.medium} 
              percent="10%"
              selected={selectedTip === tipAmounts.medium} 
              onSelect={setSelectedTip}
              isPopular={true}
              handleTipSubmit={handleTipSubmit}
              isDefaultAmount={useMediumDefault}
              isProcessing={isProcessingTip}
            />
            <TipOption 
              amount={tipAmounts.large} 
              percent="15%"
              selected={selectedTip === tipAmounts.large} 
              onSelect={setSelectedTip}
              handleTipSubmit={handleTipSubmit}
              isDefaultAmount={useLargeDefault}
              isProcessing={isProcessingTip}
            />
          </div>
          
          {/* Montant personnalisé */}
          <div className="text-center mb-5">
            {!showCustomInput ? (
              <Button
                variant="text"
                className="normal-case text-sm bg-gray-100 hover:bg-gray-200 text-gray-700"
                onClick={() => {
                  setSelectedTip('custom');
                  setShowCustomInput(true);
                }}
              >
                Montant libre
              </Button>
            ) : (
              <div className="max-w-xs mx-auto">
                <div className="flex items-center gap-2">
                  <Input
                    type="text"
                    label="Montant personnalisé (€)"
                    min="1"
                    value={customTip}
                    onChange={(e) => setCustomTip(e.target.value)}
                    className="text-center"
                    placeholder="Ex: 1,5"
                    containerProps={{ className: "flex-1" }}
                  />
                  <Button 
                    color="green"
                    onClick={handleSubmitCustomTip}
                    disabled={!customTip}
                    style={{ backgroundColor: colors.dimoPrimary }}
                    className="px-4"
                  >
                    Valider
                  </Button>
                </div>
              </div>
            )}
          </div>
          
          {/* Option Ne plus afficher */}
          <div className="flex items-center justify-center">
            <Checkbox 
              id="neverShowTip" 
              checked={neverShowTip} 
              onChange={() => setNeverShowTip(!neverShowTip)} 
              color="green"
              className="h-4 w-4"
            />
            <label htmlFor="neverShowTip" className="text-xs text-gray-600 cursor-pointer ml-2">
              Ne plus me proposer de pourboire
            </label>
          </div>
        </div>
        
        {/* Bouton Passer */}
        <div className="bg-gray-50 px-6 py-3 rounded-b-xl border-t border-gray-200">
          <Button 
            variant="text" 
            onClick={handleSkipTip}
            className="text-sm text-gray-500 w-full"
          >
            Accéder aux documents sans pourboire
          </Button>
        </div>
      </DialogBody>
    </>
  );

  const renderDocumentsStep = () => (
    <>
        <DialogHeader className="flex items-center justify-between">
          <Typography variant="h5" className="flex items-center">
            <DocumentTextIcon className="h-6 w-6 mr-2" />
            Liste des documents
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={handleClose}>
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </DialogHeader>
        <DialogBody divider className="overflow-y-auto max-h-[60vh]">
        {tipAttempted && showTipSystemError && (
          <Alert 
            color="red" 
            variant="outlined" 
            className="mb-4 rounded-lg border-red-500"
            onClose={() => setShowTipSystemError(false)}
          >
            <Typography variant="small" className="font-medium">
              La fonctionnalité de pourboire rencontre actuellement un problème technique. Nous nous excusons pour ce désagrément.
            </Typography>
          </Alert>
        )}
        
        {tipError && (
          <Alert 
            color="red" 
            variant="ghost" 
            className="mb-4 rounded-lg border-red-500 text-xs sm:text-sm"
          >
            Une erreur est survenue lors de l'enregistrement du pourboire.
          </Alert>
        )}
        
          {isLoadingDocuments || !documents ? (
            <div className="flex justify-center items-center py-10">
              <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              <Typography variant="small" color="gray" className="ml-2">
                Chargement des documents, cela peut prendre jusqu'à 30 secondes...
              </Typography>
            </div>
          ) : documents.length > 0 ? (
            <div className="space-y-4">
              {activeDocuments.map((doc, index) => renderDocumentCard(doc, index))}

              {archivedDocuments.length > 0 && (
                <Card className="p-4 border border-gray-200 hover:border-gray-300 transition-all duration-300">
                  <button
                    className="w-full flex items-center justify-between"
                    onClick={() => setIsArchiveOpen(!isArchiveOpen)}
                    aria-expanded={isArchiveOpen}
                    aria-controls="archived-documents"
                  >
                    <Typography variant="h6" className="text-gray-900">
                      Documents archivés ({archivedDocuments.length})
                    </Typography>
                    {isArchiveOpen ? (
                      <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  {isArchiveOpen && (
                    <div id="archived-documents" className="mt-4 space-y-4">
                      <Typography variant="small" color="gray">
                      Les documents archivés correspondent à d'anciennes versions désormais remplacées par des versions plus récentes, disponibles ci-dessus. Ces documents ne sont plus valides et ne doivent pas être utilisés. Ils sont conservés ici uniquement à titre de référence. 
                      </Typography>
                      {archivedDocuments.map((doc, index) => renderDocumentCard(doc, index))}
                    </div>
                  )}
                </Card>
              )}

              {/* Nouvelle section Jeu concours */}
              {canReview && invitationLink && (
                <div className="mt-6 pt-6 border-t border-gray-100">
                  <div className="flex flex-col md:flex-row items-start md:items-center gap-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
                    <GiftIcon className="h-12 w-12 flex-shrink-0" style={{ color: colors.dimoPrimary }} />

                    <div className="flex-1">
                      <Typography variant="h6" className="mb-2 text-gray-900">
                        Participez à notre tirage au sort mensuel !
                      </Typography>
                      <Typography variant="small" className="text-gray-600">
                        Gagnez le remboursement de votre diagnostic en partageant votre avis sur votre expérience avec Dimo Diagnostic.
                      </Typography>
                      <Typography variant="small" className="text-gray-800">
                        <a href="https://www.dimo-diagnostic.net/jeu-concours" target="_blank" rel="noopener noreferrer">
                          Voir le règlement du concours
                        </a>
                      </Typography>
                    </div>

                    <Button
                      size="sm"
                      className="w-full md:w-auto mt-2 md:mt-0 flex items-center justify-center gap-2 text-center mx-auto"
                      style={{ backgroundColor: colors.dimoPrimary }}
                      onClick={(e) => {
                        e.stopPropagation();
                      handleReviewParticipation();
                      }}
                    >
                      Je participe
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Card className="p-8 bg-gray-50">
              <div className="text-center">
                <DocumentTextIcon className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                <Typography variant="h6" color="gray" className="mb-2">
                  Aucun document disponible
                </Typography>
                <Typography color="gray">Les documents apparaîtront ici une fois générés.</Typography>
              </div>
            </Card>
          )}
        </DialogBody>
        <DialogFooter>
        <div className="flex justify-between w-full">
          <Button 
            variant="outlined" 
            style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }} 
            className="flex items-center gap-2" 
            onClick={handleClose}
          >
            Fermer
          </Button>
        </div>
        </DialogFooter>
    </>
  );

  return (
    <Dialog 
      open={isOpen} 
      handler={(e) => {
        e?.stopPropagation?.();
        onClose();
      }}
      size="lg" 
      className="bg-white shadow-xl rounded-xl overflow-hidden test-modal"
    >
      {currentStep === 1 ? renderTipStep() : renderDocumentsStep()}
    </Dialog>
  )
}