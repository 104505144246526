import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-tailwind/react';
import { colors } from '../utils/colors';
import { Helmet } from 'react-helmet';
import { trackEvent } from '../utils/analytics';

const NotFound = () => {
    useEffect(() => {
        // Traquer que l'utilisateur a atterri sur la page 404
        trackEvent('notfound_view', {
            url: window.location.pathname,
            referrer: document.referrer
        });
    }, []);

    const handleHomeReturn = () => {
        trackEvent('notfound_return_home', {
            fromUrl: window.location.pathname
        });
    };

    return (
        <div className="min-h-dvh flex flex-col items-center justify-center bg-gray-100 p-4">
            <Helmet>
                <title>Erreur 404 - Dimo Diagnostic</title>
                <meta name="description" content="La page que vous cherchez n'existe pas ou a été déplacée." />
            </Helmet>
            <div className="bg-white shadow-md rounded-lg p-8 max-w-lg text-center">
                <h1 className="text-6xl font-bold text-blue-gray-800 mb-4">404</h1>
                <h2 className="text-2xl font-semibold text-blue-gray-700 mb-2">Page Introuvable</h2>
                <p className="text-blue-gray-500 mb-6">
                    Désolé, la page que vous cherchez n'existe pas ou a été déplacée.
                </p>
                <Link to="/" onClick={handleHomeReturn}>
                    <Button 
                        style={{ backgroundColor: colors.dimoPrimary }}
                        ripple={true}
                        className="text-white font-semibold px-6 py-3 rounded-lg"
                    >
                        Retour à la Page d'Accueil
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
