import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, Typography } from "@material-tailwind/react";
import { XCircleIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { colors } from '../utils/colors';
import Logo from '../utils/logo';
import { trackEvent } from '../utils/analytics';

function ConsentShare() {
    const { token, action } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [alreadyProcessed, setAlreadyProcessed] = useState(false);

    useEffect(() => {
        const handleConsent = async () => {
            try {
                setLoading(true);
                
                // Tracking du début de l'action
                trackEvent('consent_process', {
                    action: action,
                    hasToken: !!token
                });
                
                const response = await axios.post(`/api/consent/${token}/${action}`);
                
                if (response.data.success) {
                    setSuccess(true);
                    
                    // Tracking du succès
                    trackEvent('consent_success', {
                        action: action
                    });
                } else {
                    setError(response.data.error);
                    if (response.data.alreadyProcessed) {
                        setAlreadyProcessed(true);
                        
                        // Tracking d'une demande déjà traitée
                        trackEvent('consent_already_processed', {
                            action: action
                        });
                    } else {
                        // Tracking d'une erreur
                        trackEvent('consent_error', {
                            action: action,
                            error: response.data.error
                        });
                    }
                }
            } catch (error) {
                setError(error.response?.data?.error || 'Une erreur est survenue');
                if (error.response?.data?.alreadyProcessed) {
                    setAlreadyProcessed(true);
                    
                    // Tracking d'une demande déjà traitée (erreur)
                    trackEvent('consent_already_processed', {
                        action: action
                    });
                } else {
                    // Tracking d'une erreur réseau ou serveur
                    trackEvent('consent_server_error', {
                        action: action,
                        error: error.message || 'unknown'
                    });
                }
            } finally {
                setLoading(false);
            }
        };

        if (token && (action === 'accept' || action === 'reject')) {
            handleConsent();
        }
    }, [token, action]);

    const handleReturnHome = () => {
        // Tracking du retour à l'accueil
        trackEvent('consent_return_home', {
            fromAction: action,
            status: success ? 'success' : (alreadyProcessed ? 'already_processed' : 'error')
        });
        
        navigate('/');
    };

    if (loading) {
        return (
            <div className="min-h-dvh flex items-center justify-center bg-gray-100">
                <Card className="w-96">
                    <CardBody className="flex flex-col items-center">
                        <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                            Traitement en cours...
                        </Typography>
                    </CardBody>
                </Card>
            </div>
        );
    }

    return (
        <div className="min-h-dvh flex items-center justify-center bg-gray-100">
            <Card className="w-96">
                <CardBody className="flex flex-col items-center">
                    {success ? (
                        <>
                            {action === 'accept' ? (
                                <>
                                    <div className="mb-4">
                                        <Logo />
                                    </div>
                                    <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                                        Demande de partage acceptée
                                    </Typography>
                                    <Typography color="gray" className="text-center">
                                        Vous avez accepté la demande de partage. Le demandeur a maintenant accès à votre dossier.
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <div className="mb-4">
                                        <Logo />
                                    </div>
                                    <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                                        Demande de partage refusée
                                    </Typography>
                                    <Typography color="gray" className="text-center">
                                       Vous avez refusé la demande de partage. Le demandeur a été informé de votre décision.
                                    </Typography>
                                </>
                            )}
                        </>
                    ) : alreadyProcessed ? (
                        <>
                            <div className="mb-4">
                                <Logo />
                            </div>
                            <Typography variant="h5" className="mb-2 text-center" color="blue-gray">
                                Demande déjà traitée
                            </Typography>
                            <Typography color="gray" className="text-center">
                                {error}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <XCircleIcon className="h-12 w-12 text-red-500 mb-4" />
                            <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                                Une erreur est survenue
                            </Typography>
                            <Typography color="gray" className="text-center">
                                {error}
                            </Typography>
                        </>
                    )}
                </CardBody>
                <CardFooter className="pt-0">
                    <Button
                        style={{ backgroundColor: colors.dimoPrimary, color: "white" }}
                        fullWidth
                        onClick={handleReturnHome}
                    >
                        Retourner à l'accueil
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}

export default ConsentShare; 