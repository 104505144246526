import React from "react";
import { Typography, Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { trackEvent } from "../../utils/analytics";

export default function Footer() {
  const handleCookieManagement = () => {
    // Tracking de l'ouverture de la gestion des cookies
    trackEvent('footer_cookie_management', {});
    
    if (window.iubenda && typeof window.iubenda.prompt === "function") {
      window.iubenda.prompt();
    } else {
      console.error("iubenda.prompt() n'est pas disponible.");
    }
  };

  const handleFooterLinkClick = (linkType) => {
    // Tracking des clics sur les liens du footer
    trackEvent('footer_link_click', {
      linkType: linkType
    });
  };

  const handleSocialClick = (platform) => {
    // Tracking des clics sur les réseaux sociaux
    trackEvent('footer_social_click', {
      platform: platform
    });
  };

  return (
    <footer className="bg-white py-8 px-4 border-t border-gray-200 shadow-md rounded-xl">
      <div className="container mx-auto">
        <div className="flex flex-wrap justify-between items-center mb-6">
          <div className="flex flex-wrap justify-center space-x-2 md:space-x-4 mb-4 md:mb-0">
            <Button
              variant="text"
              size="sm"
              color="blue-gray"
              className="iubenda-cs-preferences-link" 
              onClick={handleCookieManagement}
            >
              Gestion des Cookies
            </Button>

            <a 
              href="https://www.dimo-diagnostic.net/mentions-legales" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => handleFooterLinkClick('legal')}
            >
              <Button variant="text" size="sm" color="blue-gray">
                Mentions légales
              </Button>
            </a>
            <a 
              href="https://www.dimo-diagnostic.net/cgvu" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => handleFooterLinkClick('terms')}
            >
              <Button variant="text" size="sm" color="blue-gray">
                Conditions générales
              </Button>
            </a>
            <a 
              href="https://www.dimo-diagnostic.net/protection-des-donnees" 
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => handleFooterLinkClick('privacy')}
            >
              <Button variant="text" size="sm" color="blue-gray">
                Confidentialité
              </Button>
            </a>
          </div>

          <div className="flex flex-wrap justify-center md:justify-start space-x-2 md:space-x-4 w-full md:w-auto">
            {['x', 'facebook', 'instagram', 'linkedin', 'youtube'].map((social) => {
              const socialUrls = {
                x: 'https://x.com/Dimodiagnostic',
                facebook: 'https://www.facebook.com/DIMODiagnostic',
                instagram: 'https://www.instagram.com/dimo_diagnostic/',
                linkedin: 'https://www.linkedin.com/company/dimodiagnostic/',
                youtube: 'https://www.youtube.com/channel/UCWeh2wFw0wtSbTPhBjbT5uA',
              };

              return (
                <a
                  key={social}
                  href={socialUrls[social]}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleSocialClick(social)}
                >
                  <Button variant="text" size="sm" color="blue-gray" className="p-2">
                    <svg className="h-5 w-5 md:h-6 md:w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                      {social === 'x' && <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />}
                      {social === 'facebook' && <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />}
                      {social === 'instagram' && <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />}
                      {social === 'linkedin' && <path fillRule="evenodd" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" clipRule="evenodd" />}
                      {social === 'youtube' && <path fillRule="evenodd" d="M23.3,7.3c0-0.2-0.3-1.8-1-2.5c-0.9-1-1.9-1.1-2.4-1.1l-0.1,0c-3.1-0.2-7.7-0.2-7.8-0.2c0,0-4.7,0-7.8,0.2l-0.1,0   c-0.5,0-1.5,0.1-2.4,1.1c-0.7,0.8-1,2.4-1,2.6c0,0.1-0.2,1.9-0.2,3.8v1.7c0,1.9,0.2,3.7,0.2,3.8c0,0.2,0.3,1.8,1,2.5   c0.8,0.9,1.8,1,2.4,1.1c0.1,0,0.2,0,0.3,0c1.8,0.2,7.3,0.2,7.5,0.2c0,0,0,0,0,0c0,0,4.7,0,7.8-0.2l0.1,0c0.5-0.1,1.5-0.2,2.4-1.1   c0.7-0.8,1-2.4,1-2.6c0-0.1,0.2-1.9,0.2-3.8v-1.7C23.5,9.3,23.3,7.4,23.3,7.3z M15.9,12.2l-6,3.2c-0.1,0-0.1,0.1-0.2,0.1   c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-6.5c0-0.2,0.1-0.3,0.2-0.4S9.8,8,10,8.1l6,3.2c0.2,0.1,0.3,0.2,0.3,0.4   S16.1,12.1,15.9,12.2z" clipRule="evenodd" />}
                    </svg>
                  </Button>
                </a>
              );
            })}
          </div>
        </div>

        <Typography className="text-xs text-gray-600 text-center md:text-left">
          DIMO Diagnostic est une Société À Responsabilité Limitée au capital de 6000€, inscrite au registre du commerce et des sociétés de Montpellier sous le numéro 829 642 370. Opérant sous la marque DIMO Diagnostic®. DIMO Diagnostic est distributeur de diagnostics immobiliers. Plus d'informations sur les{" "}
          <Link 
            to="https://www.dimo-diagnostic.net/cgvu" 
            className="text-blue-600" 
            onClick={() => handleFooterLinkClick('cgvu_footer')}
          >
            Conditions Générales de Vente et d'Utilisation (CGVU)
          </Link>{" "}
          et l'utilisation de vos données personnelles sur notre{" "}
          <Link 
            to="https://www.dimo-diagnostic.net/protection-des-donnees" 
            className="text-blue-600"
            onClick={() => handleFooterLinkClick('privacy_footer')}
          >
            politique des données personnelles
          </Link>
          .
        </Typography>
      </div>
    </footer>
  );
}