import React, { useState } from "react";
import { Card, Typography, Button } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import { 
  UserPlusIcon, 
  CheckIcon
} from "@heroicons/react/24/outline";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import RecommendedDeals from "../components/deals/RecommendedDeals";
import axiosInstance from "../utils/axiosInstance";
import { trackEvent } from "../utils/analytics";

export default function Referral() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  
  // Données du formulaire
  const [referralForm, setReferralForm] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: ""
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReferralForm(prev => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };
  
  const handleNewReferral = () => {
    trackEvent('referral_new_form', {});
    setFormSubmitted(false);
  };
  
  const submitReferralForm = async (e) => {
    e.preventDefault();
    
    // Validation basique
    if (!referralForm.firstName || !referralForm.lastName || !referralForm.phone || !referralForm.email) {
      setError("Veuillez remplir tous les champs obligatoires");
      trackEvent('referral_validation_error', {});
      return;
    }
    
    setIsLoading(true);
    
    // Tracking avant soumission
    trackEvent('referral_submit', {
      emailDomain: referralForm.email.split('@')[1] || 'invalid'
    });
    
    try {
      // Envoi des données au serveur
      await axiosInstance.post('/referral', {
        firstName: referralForm.firstName,
        lastName: referralForm.lastName,
        phone: referralForm.phone,
        email: referralForm.email,
      });
      
      // Tracking succès
      trackEvent('referral_submit_success', {});
      
      // Si succès
      setFormSubmitted(true);
      
      // Reset du formulaire
      setReferralForm({
        firstName: "",
        lastName: "",
        phone: "",
        email: ""
      });
    } catch (err) {
      // Tracking erreur
      trackEvent('referral_submit_error', {
        error: err.message || 'unknown'
      });
      
      console.error("Erreur lors de l'envoi du parrainage:", err);
      setError("Une erreur est survenue lors de l'envoi du parrainage. Veuillez réessayer ultérieurement.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Programme de parrainage - Dimo Diagnostic</title>
        <meta name="description" content="Parrainez vos proches et tentez de gagner le remboursement de votre diagnostic immobilier." />
        <link rel="canonical" href="https://client.dimo-diagnostic.net/referral" />
      </Helmet>
      
      <Card className="mb-6 p-6 shadow-md">
        <div className="flex items-center mb-6">
          <UserPlusIcon className="h-8 w-8 mr-3" style={{ color: colors.dimoPrimary }} />
          <div>
            <Typography variant="h3" className="text-gray-900">
              Programme de parrainage
            </Typography>
            <Typography variant="small" className="text-gray-800">
              Parrainez vos proches et soyez récompensé
            </Typography>
          </div>
        </div>
        
        {formSubmitted ? (
          <div className="py-8">
            <div className="text-center max-w-lg mx-auto">
              <div className="w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4" style={{ backgroundColor: `${colors.dimoPop}30` }}>
                <CheckIcon className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              </div>
              <Typography variant="h4" className="text-black mb-4">
                Merci pour votre parrainage !
              </Typography>
              <Typography className="text-gray-700 mb-6">
                Les coordonnées de votre proche ont bien été enregistrées. Nous vous contacterons prochainement si vous êtes tiré au sort pour le remboursement de votre diagnostic.
              </Typography>
              <Button
                style={{ backgroundColor: colors.dimoPrimary }}
                className="mt-4"
                onClick={handleNewReferral}
              >
                Parrainer une autre personne
              </Button>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div>
              <Typography variant="h4" className="text-black mb-4">
                Comment ça marche ?
              </Typography>
              
              <Typography className="text-gray-700 mb-4">
                Notre programme de parrainage vous permet de nous recommander auprès de vos proches et d'être récompensé !
              </Typography>
              
              <div className="space-y-6 mb-6">
                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0" style={{ backgroundColor: `${colors.dimoPop}30` }}>
                    <Typography className="font-semibold" style={{ color: colors.dimoPrimary }}>1</Typography>
                  </div>
                  <div>
                    <Typography variant="h6" className="text-black">
                      Parrainez un proche
                    </Typography>
                    <Typography className="text-gray-700">
                      Indiquez-nous les coordonnées d'un proche qui pourrait être intéressé par nos services de diagnostic immobilier.
                    </Typography>
                  </div>
                </div>
                
                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0" style={{ backgroundColor: `${colors.dimoPop}30` }}>
                    <Typography className="font-semibold" style={{ color: colors.dimoPrimary }}>2</Typography>
                  </div>
                  <div>
                    <Typography variant="h6" className="text-black">
                      Nous contactons votre filleul
                    </Typography>
                    <Typography className="text-gray-700">
                      Nous présentons nos services à votre proche et lui proposons une offre spéciale en tant que filleul.
                    </Typography>
                  </div>
                </div>
                
                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0" style={{ backgroundColor: `${colors.dimoPop}30` }}>
                    <Typography className="font-semibold" style={{ color: colors.dimoPrimary }}>3</Typography>
                  </div>
                  <div>
                    <Typography variant="h6" className="text-black">
                      Recevez votre récompense
                    </Typography>
                    <Typography className="text-gray-700">
                      Si votre filleul devient client, vous pouvez gagner le remboursement de votre diagnostic lors de notre tirage au sort mensuel.
                    </Typography>
                  </div>
                </div>
              </div>
              
              <Typography variant="small" className="text-gray-500 mb-2">
                Plus d'informations sur le programme dans notre <a
                  href="https://www.dimo-diagnostic.net/jeu-concours" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-600 hover:text-gray-900"
                  onClick={() => trackEvent('referral_rules_click', {})}
                >règlement de parrainage</a>.
              </Typography>
            </div>
            
            <div>
              <Typography variant="h4" className="text-black mb-4">
                Qui souhaitez-vous parrainer ?
              </Typography>
              
              <Typography className="text-gray-700 mb-4">
                Nous utilisons ces informations uniquement pour contacter votre proche au sujet de nos services.
              </Typography>
              
              <form onSubmit={submitReferralForm} className="space-y-5">
                <div>
                  <Typography variant="small" className="text-gray-700 mb-1 font-medium">
                    Prénom *
                  </Typography>
                  <input
                    type="text"
                    name="firstName"
                    value={referralForm.firstName}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dimoPrimary focus:border-transparent"
                    placeholder="Marie"
                  />
                </div>
                
                <div>
                  <Typography variant="small" className="text-gray-700 mb-1 font-medium">
                    Nom *
                  </Typography>
                  <input
                    type="text"
                    name="lastName"
                    value={referralForm.lastName}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dimoPrimary focus:border-transparent"
                    placeholder="Dupont"
                  />
                </div>
                
                <div>
                  <Typography variant="small" className="text-gray-700 mb-1 font-medium">
                    Email *
                  </Typography>
                  <input
                    type="email"
                    name="email"
                    value={referralForm.email}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dimoPrimary focus:border-transparent"
                    placeholder="marie.dupont@exemple.fr"
                  />
                </div>
                
                <div>
                  <Typography variant="small" className="text-gray-700 mb-1 font-medium">
                    Numéro de téléphone *
                  </Typography>
                  <input
                    type="tel"
                    name="phone"
                    value={referralForm.phone}
                    onChange={handleInputChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-dimoPrimary focus:border-transparent"
                    placeholder="+33 6 12 34 56 78"
                  />
                </div>
                
                {error && (
                  <Typography className="text-red-500">
                    {error}
                  </Typography>
                )}
                
                <div className="pt-2">
                  <Button
                    type="submit"
                    size="lg"
                    fullWidth
                    style={{ backgroundColor: colors.dimoPrimary }}
                    disabled={isLoading}
                    className="flex items-center justify-center gap-2"
                  >
                    {isLoading ? "Traitement en cours..." : "Parrainer maintenant"}
                  </Button>
                  
                  <Typography variant="small" className="text-gray-500 mt-3 text-center">
                    En parrainant, vous acceptez que nous contactions la personne mentionnée ci-dessus au sujet de nos services de diagnostic immobilier.
                  </Typography>
                </div>
              </form>
            </div>
          </div>
        )}
      </Card>
      
      {/* Composant RecommendedDeals */}
      <RecommendedDeals source="referral" />
    </Layout>
  );
} 