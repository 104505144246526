import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Typography, Card, Chip, Dialog, DialogHeader, DialogBody, DialogFooter, Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import {
  CheckIcon,
  XMarkIcon,
  ChartBarIcon,
  LightBulbIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  ArrowRightIcon,
  ExclamationTriangleIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  PlusIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import axiosInstance from "../utils/axiosInstance";
import { trackEvent } from "../utils/analytics";

export default function SimulationDPE() {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const offersRef = useRef(null);

  // Fonction pour gérer les accordions
  const handleOpenAccordion = (value) => {
    setOpenAccordion(openAccordion === value ? null : value);
  };

  // Fonction pour scroller vers les offres
  const scrollToOffers = () => {
    offersRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Fonction pour gérer le clic sur une offre
  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
    setShowDialog(true);
  };

  // Fonction pour le tracking du clic pour voir tous les avis Trustpilot
  const handleTrustpilotClick = () => {
    trackEvent('reviews_click_trustpilot', {});
    window.open('https://fr.trustpilot.com/review/dimo-diagnostic.net', '_blank');
  };

  // Fonction pour envoyer la demande
  const handleSubmitOffer = async () => {
    if (!selectedOffer) return;
    
    setIsSubmitting(true);
    
    try {
      // Appel à l'API pour envoyer un email 
      await axiosInstance.post('/dpe-simulation', {
        offerType: selectedOffer.title,
        price: selectedOffer.price,
      });
      
      setSubmitted(true);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Script Trustpilot
  useEffect(() => {
    const trustpilotScript = document.createElement("script");
    trustpilotScript.type = "text/javascript";
    trustpilotScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    trustpilotScript.async = true;
    document.head.appendChild(trustpilotScript);

    return () => {
      if (document.head.contains(trustpilotScript)) {
        document.head.removeChild(trustpilotScript);
      }
    };
  }, []);

  // Questions fréquentes
  const faqs = [
    {
      question: "Quelle est la différence entre l'Offre Autonomie et l'Offre Premium ?",
      answer: "Avec l'Offre Premium, vous êtes accompagné par un de nos collaborateurs, tandis qu'avec l'Offre Autonomie, le client réalise lui-même sa simulation. Les outils utilisés diffèrent également : l'Offre Premium utilise le moteur de calcul officiel pour les DPE (3CL-2021), alors que l'Offre Autonomie propose un moteur de calcul permettant d'obtenir une estimation précise, mais qui n'est pas le moteur officiel de calcul DPE."
    },
    {
      question: "Est-ce qu'en changeant ma chaudière, mon logement passera enfin de F à C ?",
      answer: "Chaque logement est unique, et cette amélioration dépend de nombreux facteurs. Notre simulation précise vous indiquera exactement l'impact d'un changement de chaudière sur votre classement DPE, et si d'autres travaux seraient nécessaires pour atteindre la classe C."
    },
    {
      question: "Faut-il privilégier l'isolation ou le chauffage pour une meilleure note DPE ?",
      answer: "Cette décision dépend des caractéristiques spécifiques de votre logement. Notre simulation identifie précisément les travaux qui auront le plus d'impact sur votre DPE, en vous indiquant le meilleur rapport qualité/prix pour améliorer votre classement."
    },
    {
      question: "Mon appartement est classé F, comment éviter une interdiction de mise en location dès 2025 ?",
      answer: "Pour éviter l'interdiction de location des logements F en 2025, vous devez atteindre au minimum la classe E. Notre simulation vous proposera les travaux les plus efficaces et économiques pour atteindre cet objectif réglementaire dans les délais."
    },
    {
      question: "Dois-je investir dans des travaux avant de vendre, ou bien vendre en l'état malgré un mauvais DPE ?",
      answer: "Cela dépend de votre situation et du marché local. Notre simulation vous permettra de comparer la valorisation potentielle de votre bien après travaux par rapport au coût des travaux, pour vous aider à prendre la décision la plus rentable."
    },
    {
      question: "Pourquoi réaliser une simulation avant d'entamer mes travaux ?",
      answer: "Pour connaître précisément quels travaux amélioreront réellement votre DPE et éviter les dépenses inutiles ou peu efficaces. Notre simulation vous permet d'optimiser votre budget en ciblant uniquement les travaux qui auront un réel impact."
    },
    {
      question: "Votre prestation remplace-t-elle un DPE officiel ?",
      answer: "Non. Notre analyse permet d'anticiper et de cibler précisément les travaux nécessaires avant de faire réaliser un DPE officiel obligatoire par un diagnostiqueur agréé."
    },
    {
      question: "Quel est le délai pour recevoir mon rapport ?",
      answer: "Vous recevez votre rapport complet sous 24h à 72h, selon la formule choisie et la complexité de votre logement."
    },
    {
      question: "Ce service peut-il être fiscalement déductible ?",
      answer: "Oui, dans certains cas spécifiques, notamment lorsqu'il s'agit d'une étude préalable à des travaux de rénovation énergétique. Nous pouvons vous fournir les justificatifs nécessaires."
    }
  ];

  // Définition des offres
  const offers = [
    {
      id: 1,
      title: "Offre Autonomie",
      price: "Indisponible",
      recommended: false,
      description: "Realisez vous-même votre simulation DPE avec notre outil en ligne.",
      features: {
        "Étude": [
          { included: true, text: "1 étude complète et détaillée" },
          { included: true, text: "3 scénarios de travaux optimisés" },
        ],
        "Estimation": [
          { included: true, text: "Chiffrage estimatif du coût de chaque scénario" },
          { included: true, text: "Estimation des aides potentielles" },
          { included: true, text: "Estimation des économies d'énergies post travaux" },
        ],
        "Accompagnement": [
          { included: false, text: "Réalisation par un expert" },
          { included: false, text: "Compte rendu de l'expert" },
        ]
      },
      disabled: true
    },
    {
      id: 2,
      title: "Offre Premium",
      price: "89€",
      recommended: true,
      description: "Étude complète réalisée par un expert avec compte rendu personnalisé.",
      features: {
        "Étude": [
          { included: true, text: "1 étude complète et détaillée" },
          { included: true, text: "3 scénarios de travaux optimisés" },
        ],
        "Estimation": [
          { included: true, text: "Chiffrage estimatif du coût de chaque scénario" },
          { included: true, text: "Estimation des aides potentielles" },
          { included: true, text: "Estimation des économies d'énergies post travaux" },
        ],
        "Accompagnement": [
          { included: true, text: "Réalisation par un expert" },
          { included: true, text: "Compte rendu de l'expert" },
        ]
      }
    },
    {
      id: 3,
      title: "Offre Expert",
      price: "349€",
      recommended: false,
      description: "Solution complète avec 2 études détaillées et 6 scénarios optimisés pour vos projets immobiliers.",
      features: {
        "Étude": [
          { included: true, text: "2 études complètes et détaillées" },
          { included: true, text: "6 scénarios de travaux optimisés" },
          { included: true, text: "DPE officiel après travaux inclus" },
        ],
        "Estimation": [
          { included: true, text: "Chiffrage estimatif du coût de chaque scénario" },
          { included: true, text: "Estimation des aides potentielles" },
          { included: true, text: "Estimation des économies d'énergies post travaux" },
        ],
        "Accompagnement": [
          { included: true, text: "Réalisation par un expert" },
          { included: true, text: "Compte rendu de l'expert" },
        ]
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Simulation DPE - Améliorez votre classement énergétique - Dimo Diagnostic</title>
        <meta name="description" content="Simulez l'impact de vos travaux sur votre DPE et évitez les dépenses inutiles. Solutions personnalisées pour propriétaires, bailleurs et investisseurs." />
      </Helmet>

      <Layout>
          {/* En-tête de la page */}
        <Card className="mb-8 p-6 shadow-md text-black">
          <div className="text-center mb-6">
            <Typography variant="h2" className="font-bold mb-3">
              Améliorer votre DPE
            </Typography>
            <Typography variant="paragraph" className="max-w-2xl mx-auto">
              Réalisez des simulations pour identifier exactement les travaux à effectuer
              et améliorez votre classement énergétique au meilleur coût.
            </Typography>
          </div>

          <div className="container mx-auto mt-10" ref={offersRef}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {offers.map((offer) => (
                <div 
                  key={offer.id}
                  className="relative"
                >
                  {offer.recommended && (
                    <div className="absolute -top-5 left-1/2 transform -translate-x-1/2 z-10">
                      <div className="bg-green-500 text-white font-medium text-sm px-4 py-1 rounded-lg">
                        Le plus populaire
                      </div>
                    </div>
                  )}
                  <div 
                    className={`border rounded-lg overflow-hidden flex flex-col ${
                      offer.recommended ? 'bg-green-50 border-green-200' : 
                      offer.disabled ? 'bg-gray-100 border-gray-200 opacity-75' : 'bg-gray-50 border-gray-200'
                    }`}
                  >
                    <div className="p-6 flex flex-col h-full">
                      <h2 className="text-2xl font-bold mb-4">{offer.title}</h2>
                      <p className="mb-6">
                        {offer.description}
                      </p>
                      
                      <div className="mt-auto">
                        <div className="mb-2">
                          <span className="text-4xl font-bold">{offer.price}</span>
                        </div>
                        {!offer.disabled && <p className="mb-6">Paiement unique</p>}
                        <Button
                          className={`w-full mb-6 ${
                            offer.recommended 
                              ? 'bg-green-500 hover:bg-green-600' 
                              : ''
                          }`}
                          variant={offer.recommended ? 'filled' : 'outlined'}
                          style={
                            !offer.recommended 
                              ? { borderColor: colors.dimoPrimary, color: colors.dimoPrimary } 
                              : {}
                          }
                          onClick={() => handleOfferClick(offer)}
                          disabled={offer.disabled}
                        >
                          {offer.disabled ? "Temporairement indisponible" : "Choisir cette offre"}
                        </Button>
                      </div>
                      
                      <div className={`border-t ${offer.recommended ? 'border-green-200' : ''} pt-6`}>
                        {Object.entries(offer.features).map(([category, items]) => (
                          <div key={category}>
                            <h3 className="font-semibold text-lg mb-4">{category}</h3>
                            <ul className="space-y-3 mb-6">
                              {items.map((feature, idx) => (
                                <li key={idx} className="flex items-start">
                                  {feature.plus ? (
                                    <PlusIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                                  ) : feature.included ? (
                                    <CheckIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                                  ) : (
                                    <XMarkIcon className="h-5 w-5 text-gray-400 mr-2 flex-shrink-0 mt-0.5" />
                                  )}
                                  <span className={`${!feature.included && !feature.plus ? 'text-gray-500' : ''}`}>
                                    {feature.text}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="bg-green-50 p-4 rounded-lg border border-green-200 mt-6">
              <div className="flex items-start">
                <ShieldCheckIcon className="h-6 w-6 mr-3 text-green-600 flex-shrink-0" />
                <Typography className="">
                  <span className="font-semibold">Chaque formule vous garantit</span> des recommandations concrètes pour optimiser votre DPE et votre investissement.
                </Typography>
              </div>
            </div>
          </div>
        </Card>

        {/* PREUVES & TÉMOIGNAGES */}
        <Card className="mb-8 p-6 shadow-md text-black">
          <div
            className="trustpilot-widget"
            data-locale="fr-FR"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5f4375bc26242f0001c97b8b"
            data-style-height="130px"
            data-style-width="100%"
            data-theme="light"
            data-stars="5"
            data-review-languages="fr"
          >
            <a
              href="https://fr.trustpilot.com/review/dimo-diagnostic.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </Card>

        {/* PROBLÉMATIQUE & FAQ */}
        <Card className="mb-8 p-6 shadow-md text-black">
          <div className="flex items-center mb-6">
            <QuestionMarkCircleIcon className="h-6 w-6 mr-2" style={{ color: colors.dimoPrimary }} />
            <Typography variant="h4" className="font-bold">
              Questions fréquentes
            </Typography>
          </div>
          
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <Accordion
                key={index}
                open={openAccordion === index}
                icon={
                  <ChevronDownIcon 
                    strokeWidth={2.5} 
                    className={`h-5 w-5 transition-transform ${openAccordion === index ? "rotate-180" : ""}`} 
                  />
                }
              >
                <AccordionHeader 
                  onClick={() => handleOpenAccordion(index)} 
                  className="border border-gray-200 rounded-t-lg px-4 py-3 text-base font-medium bg-gray-50 hover:bg-gray-100"
                >
                  {faq.question}
                </AccordionHeader>
                <AccordionBody className="px-4 py-3 border border-t-0 border-gray-200 rounded-b-lg bg-white">
                  <Typography color="gray" className="text-sm">
                    {faq.answer}
                  </Typography>
                </AccordionBody>
              </Accordion>
            ))}
          </div>
        </Card>

        {/* HERO SECTION */}
        <Card className="mb-8 p-6 shadow-md text-black">
          <div className="bg-white p-6 rounded-lg">
            <Typography variant="h2" className="text-black font-bold mb-6 text-2xl text-center">
                Votre DPE vous bloque pour vendre, louer ou investir ?
              </Typography>
              
            <div className="mb-8 space-y-4">
              <div className="p-4 border border-gray-200 rounded-lg">
                <div className="flex items-start">
                  <div className="flex-shrink-0 mt-1">
                    <CheckIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
                  </div>
                  <Typography className="ml-3">
                    <span className="font-semibold">Vous souhaitez vendre au meilleur prix</span>, mais votre classement DPE freine les acheteurs ?
                  </Typography>
                </div>
              </div>
              <div className="p-4 border border-gray-200 rounded-lg">
                <div className="flex items-start">
                  <div className="flex-shrink-0 mt-1">
                    <CheckIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
                  </div>
                  <Typography className="ml-3">
                    <span className="font-semibold">Vous êtes bailleur et craignez une interdiction de location</span> faute de rénovation adaptée ?
                  </Typography>
                </div>
              </div>
              <div className="p-4 border border-gray-200 rounded-lg">
                <div className="flex items-start">
                  <div className="flex-shrink-0 mt-1">
                    <CheckIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
                  </div>
                  <Typography className="ml-3">
                    <span className="font-semibold">Vous êtes investisseur et voulez vérifier le potentiel énergétique</span> d'un bien avant achat ?
                  </Typography>
                </div>
                </div>
              </div>
              
            <div className="bg-amber-50 p-4 rounded-lg border border-amber-200 mb-8">
                <div className="flex items-start">
                <ExclamationTriangleIcon className="h-6 w-6 mr-3 text-amber-600 flex-shrink-0" />
                <Typography className="">
                  Sans simulation préalable, vous risquez de dépenser des milliers d'euros sans améliorer votre DPE ou votre situation réglementaire.
                  </Typography>
                </div>
              </div>
              
              <div className="text-center">
                <Button
                  size="lg"
                  className="normal-case text-base px-6 py-3 flex items-center mx-auto gap-2"
                  style={{ backgroundColor: colors.dimoPrimary }}
                  onClick={scrollToOffers}
                >
                  Je lance ma simulation DPE
                  <ArrowRightIcon className="h-4 w-4" />
                </Button>
              </div>
              </div>
            </Card>
            
        {/* CTA FINAL */}
        <Card className="mb-8 p-6 shadow-md text-black">
              <div className="flex flex-col md:flex-row items-center gap-6">
                <div className="md:w-3/4">
              <Typography variant="h3" className="font-bold mb-4">
                    Ne perdez plus d'argent dans des travaux inefficaces !
                  </Typography>
              <Typography variant="paragraph" className="mb-6">
                    Lancez dès aujourd'hui votre simulation DPE et sécurisez votre projet immobilier en faisant les bons choix.
                  </Typography>
                </div>
                <div className="md:w-1/4">
                  <div className="flex justify-center">
                    <DocumentTextIcon className="h-24 w-24" style={{ color: colors.dimoPrimary }} />
                  </div>
                </div>
              </div>
              <div className="text-center mt-6">
                <Button
                  size="lg"
                  style={{ backgroundColor: colors.dimoPrimary }}
                  className="normal-case text-base px-8 py-3 flex items-center mx-auto gap-2"
                  onClick={scrollToOffers}
                >
                  Je choisis mon offre maintenant
                  <ArrowRightIcon className="h-4 w-4" />
                </Button>
              </div>
            </Card>

          {/* Modal de confirmation */}
          <Dialog open={showDialog} handler={() => !isSubmitting && setShowDialog(false)} size="sm">
            <DialogHeader className="flex items-center">
              {!submitted ? (
                <>
                  <HomeIcon className="h-6 w-6 mr-2" style={{ color: colors.dimoPrimary }} />
                  <Typography variant="h5">
                    {selectedOffer ? selectedOffer.title : "Demande de simulation DPE"}
                  </Typography>
                </>
              ) : (
                <>
                  <CheckIcon className="h-6 w-6 mr-2 text-green-500" />
                  <Typography variant="h5">
                    Votre demande a bien été envoyée !
                  </Typography>
                </>
              )}
            </DialogHeader>
            <DialogBody className="px-6">
              {!submitted ? (
                <>
                <Typography className="mb-4">
                    Vous avez choisi l'offre <span className="font-semibold">{selectedOffer?.title}</span> à {selectedOffer?.price}.
                  </Typography>
                <Typography className="">
                    En confirmant, un expert vous contactera dans les plus brefs délais pour préparer votre simulation DPE personnalisée.
                  </Typography>
                </>
              ) : (
                <div className="text-center py-4">
                  <div className="mb-4 flex justify-center">
                    <EnvelopeIcon className="h-16 w-16 text-green-500" />
                  </div>
                <Typography className="mb-2 font-semibold">
                    Merci pour votre confiance !
                  </Typography>
                <Typography className="">
                    Un de nos experts vous contactera par email sous 24 à 48h ouvrées pour démarrer votre simulation DPE.
                  </Typography>
                </div>
              )}
            </DialogBody>
            <DialogFooter className="flex justify-center gap-2">
              {!submitted ? (
                <>
                  <Button 
                    variant="outlined" 
                    color="gray" 
                    onClick={() => setShowDialog(false)}
                    disabled={isSubmitting}
                  >
                    Annuler
                  </Button>
                  <Button 
                    style={{ backgroundColor: colors.dimoPrimary }} 
                    className="normal-case" 
                    onClick={handleSubmitOffer}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Traitement en cours..." : "Confirmer ma demande"}
                  </Button>
                </>
              ) : (
                <Button 
                  style={{ backgroundColor: colors.dimoPrimary }} 
                  className="normal-case" 
                  onClick={() => {
                    setShowDialog(false);
                    setSubmitted(false);
                  }}
                >
                  Fermer
                </Button>
              )}
            </DialogFooter>
          </Dialog>
      </Layout>
    </>
  );
}