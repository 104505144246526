import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Typography,
  Button,
  Card,
  IconButton,
} from "@material-tailwind/react";
import { 
  XMarkIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { colors } from "../../utils/colors";
import { trackEvent } from "../../utils/analytics";

export default function TipErrorModal({ isOpen, onClose, dealId }) {
  const handleClose = () => {
    trackEvent('tip_error_modal_closed', {
      propertyId: dealId
    });
    onClose();
  };

  return (
    <Dialog 
      open={isOpen} 
      handler={handleClose} 
      size="sm"
      className="bg-white shadow-xl rounded-2xl overflow-hidden"
    >
      <DialogHeader className="px-0 pt-0 pb-0 relative overflow-hidden">
        <div className="absolute top-0 right-0 z-20">
          <IconButton variant="text" color="white" onClick={handleClose} className="mt-2 mr-2">
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </div>
        {/* Bannière supérieure avec gradient */}
        <div 
          className="w-full bg-gradient-to-r from-red-500 to-orange-500 pt-12 pb-20 px-8 text-white relative z-0"
        >
          <Typography variant="h4" className="font-bold mb-2 text-center">
            Oups, un problème est survenu
          </Typography>
          <Typography className="text-center text-white/80 font-light">
            Nous n'avons pas pu traiter votre pourboire
          </Typography>
        </div>
      </DialogHeader>
      
      <DialogBody className="px-6 pt-0 pb-8 -mt-14">
        <Card className="shadow-lg p-6 text-center">
          <div className="flex flex-col items-center justify-center space-y-5">
            <div className="h-16 w-16 bg-red-50 rounded-full flex items-center justify-center mb-2 border-4 border-white shadow-md">
              <ExclamationCircleIcon className="h-8 w-8 text-red-500" />
            </div>
            
            <div className="mt-[-3rem] space-y-4">
              <Typography color="gray" className="max-w-md">
                En raison d'un problème technique, nous n'avons pas pu enregistrer votre pourboire.
                Vous pouvez réessayer ultérieurement ou contacter notre service facturation.
              </Typography>
              
              <div className="flex flex-col gap-3 w-full max-w-sm mx-auto mt-6">
                <Button
                  size="lg"
                  className="w-full shadow-md hover:shadow-lg transition-all"
                  style={{ backgroundColor: colors.dimoPrimary }}
                  onClick={handleClose}
                >
                  Retour à mes diagnostics
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </DialogBody>
    </Dialog>
  );
} 