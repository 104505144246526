import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Card,
  Typography,
  Button,
  Spinner,
  Textarea,
  Select,
  Option,
} from "@material-tailwind/react";
import {
  UserIcon,
  IdentificationIcon,
  MapPinIcon,
  CalendarIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  ArrowDownTrayIcon,
  EyeIcon,
  CheckCircleIcon,
  CreditCardIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
  FolderIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  PhoneIcon,
  ExclamationTriangleIcon,
  EllipsisHorizontalCircleIcon,
  HomeIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  ArrowPathIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";

import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import StyledDocumentUpload from "../components/FileUpload";
import axiosInstance from "../utils/axiosInstance";
import { useProperties } from "../contexts/PropertiesContext";
import { colors } from "../utils/colors";
import { trackEvent } from "../utils/analytics";

/** Liste des motifs (9 au total). */
const MOTIFS = [
  {
    title: "Recevoir mon rapport d'expertise",
    value: "report",
    icon: DocumentDuplicateIcon,
  },
  {
    title: "Recevoir ma facture",
    value: "invoice",
    icon: DocumentTextIcon,
  },
  //payer mon diag
  {
    title: "Payer mon diagnostic",
    value: "pay_diag",
    icon: CreditCardIcon,
  },
  {
    title: "Fournir des documents",
    value: "provide_documents",
    icon: FolderIcon,
  },
  {
    title: "Signaler une erreur",
    value: "ask_modif",
    icon: PencilSquareIcon,
  },
  {
    title: "Comprendre un diagnostic immobilier",
    value: "understand_diagnostic",
    icon: QuestionMarkCircleIcon,
  },
  {
    title: "Visite complémentaire",
    value: "additional_visit",
    icon: CalendarIcon,
  },
  {
    title: "Contacter le service commercial",
    value: "commercial",
    icon: PhoneIcon,
  },
  {
    title: "Résultat amiante",
    value: "asbestos_result",
    icon: ExclamationTriangleIcon,
  },
  {
    title: "Autre",
    value: "other",
    icon: EllipsisHorizontalCircleIcon,
  },
];

const ADDITIONAL_VISIT_REASONS = [
  {
    title: "Pièce(s) ou espace(s) non visité(s)",
    value: "unvisited_spaces",
    icon: HomeIcon,
  },
  {
    title: "Contre-expertise",
    value: "counter_expertise",
    icon: ClipboardDocumentCheckIcon,
  },
  {
    title: "Diagnostic manquants",
    value: "missing_diagnostics",
    icon: ClipboardDocumentListIcon,
  },
  {
    title: "Prolongation ou mise à jour de diagnostics",
    value: "update_diagnostics",
    icon: ArrowPathIcon,
  },
];

// Ajout du composant Skeleton
const SupportSkeleton = () => {
  return (
    <Card className="w-full mb-6 shadow-md animate-pulse">
      <div className="p-6 space-y-6">
        {/* Skeleton pour le titre principal */}
        <div className="h-8 bg-gray-200 rounded-lg w-1/4"></div>

        {/* Skeleton pour la carte de propriété */}
        <Card className="p-4 bg-gray-50 border border-gray-200">
          <div className="space-y-4">
            {/* Ligne nom */}
            <div className="flex items-center space-x-2">
              <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
              <div className="h-6 bg-gray-200 rounded w-1/3"></div>
            </div>
            {/* Ligne dossier */}
            <div className="flex items-center space-x-2">
              <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
              <div className="h-5 bg-gray-200 rounded w-1/4"></div>
            </div>
            {/* Ligne adresse */}
            <div className="flex items-center space-x-2">
              <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
              <div className="h-5 bg-gray-200 rounded w-2/3"></div>
            </div>
            {/* Ligne rendez-vous */}
            <div className="flex items-center space-x-2">
              <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
              <div className="h-5 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>
        </Card>

        {/* Skeleton pour la grille des motifs */}
        <div className="space-y-4">
          <div className="h-6 bg-gray-200 rounded w-2/5"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
            {[...Array(10)].map((_, index) => (
              <Card key={index} className="p-4 bg-gray-50 border border-gray-200">
                <div className="flex flex-col items-center space-y-3">
                  <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
                  <div className="h-5 bg-gray-200 rounded w-3/4"></div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default function Support() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { properties, loading, error } = useProperties();

  // Propriété sélectionnée
  const [selectedProperty, setSelectedProperty] = useState(id || "");

  // Motif sélectionné
  const [motif, setMotif] = useState("");

  // Sous-motif pour visite complémentaire
  const [additionalVisitReason, setAdditionalVisitReason] = useState("");
  const [selectedAdditionalVisitReason, setSelectedAdditionalVisitReason] = useState("");

  // Message texte (pour certains motifs)
  const [message, setMessage] = useState("");

  // Documents chargés (pour invoice / report)
  const [documents, setDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [documentsError, setDocumentsError] = useState(false);

  // Gestion d'archives
  const [isArchiveOpen, setIsArchiveOpen] = useState(false);

  // Pour le téléchargement
  const [downloadingDoc, setDownloadingDoc] = useState(null);

  // Soumission
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  // Fichiers uploadés
  const [files, setFiles] = useState([]);

  // Indique si on doit afficher le bloc "message + upload"
  const [showHelpForm, setShowHelpForm] = useState(false);
  const [showDontNeedHelp, setShowDontNeedHelp] = useState(false);

  // iframe URL
  const [iframeUrl, setIframeUrl] = useState("");
  const [iframeLoading, setIframeLoading] = useState(true);

  const [paymentLink, setPaymentLink] = useState(null);
  const [paymentError, setPaymentError] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState("");

  useEffect(() => {
    if (location.pathname === "/support") {
      setSelectedProperty("");
    } else if (id) {
      setSelectedProperty(id);
    }
  }, [location, id]);

  useEffect(() => {
    if (properties.length === 1) {
      setSelectedProperty(properties[0].id);
      navigate(`/support/${properties[0].id}`);
    }
  }, [properties, navigate]);

  const handlePropertySelect = (propertyId) => {
    trackEvent('support_select_property', { 
      propertyId: propertyId 
    });
    
    setSelectedProperty(propertyId);
    setDocuments([]);
    setPaymentLink(null);
    setPaymentError("");
    setPaymentSuccess("");
    setLoadingDocuments(false);
    setDocumentsError(false);
    setIsArchiveOpen(false);
    setIframeUrl("");

    navigate(`/support/${propertyId}`);
    if (motif === "report" || motif === "invoice") {
      loadDocuments(motif, propertyId);
    } else if (motif === "pay_diag") {
      handlePaymentMotif(propertyId);
    }
  };

  const handlePaymentMotif = async (propertyId = selectedProperty) => {
    trackEvent('support_payment_issue', { 
      propertyId: propertyId 
    });
    
    try {
      setLoadingDocuments(true);
      setPaymentError("");
      setPaymentSuccess("");
      setPaymentLink(null);
      setDocuments([]);

      const response = await axiosInstance.get(`/documents/${propertyId}?type=invoice`);
      const docs = response?.data?.documents;

      if (!Array.isArray(docs) || docs.length === 0) {
        setPaymentError("La facture n'est pas encore disponible.");
        setShowHelpForm(true);
        return;
      }

      // Trier les factures par date (la plus récente en premier)
      const sortedInvoices = docs
        .filter(doc => doc.type === "invoice")
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      if (sortedInvoices.length === 0) {
        setPaymentError("Aucune facture trouvée.");
        setShowHelpForm(true);
        return;
      }

      // Vérifier si la dernière facture est déjà payée
      const latestInvoice = sortedInvoices[0];
      if (latestInvoice.status === "paid") {
        setPaymentSuccess("Votre dernière facture a bien été réglée.");
        setDocuments([latestInvoice]); // Afficher la facture
        return;
      }

      // Vérifier la présence du lien de paiement
      if (!latestInvoice.payLink) {
        setPaymentError("Le lien de paiement n'est pas encore disponible.");
        setShowHelpForm(true);
        return;
      }

      // Tout est bon, on peut afficher la facture et le lien de paiement
      setDocuments([latestInvoice]);
      setPaymentLink(latestInvoice.payLink);

    } catch (error) {
      console.error("Erreur lors de la récupération de la facture:", error);
      setPaymentError(error.response?.data?.error || "Une erreur est survenue lors de la récupération de la facture.");
      setShowHelpForm(true);
    } finally {
      setLoadingDocuments(false);
    }
  };

  const handleMotifCardClick = async (selectedMotif) => {
    trackEvent('support_issue_select', { 
      issueType: selectedMotif 
    });
    
    setMotif(selectedMotif);
    setFiles([]);
    setDocuments([]);
    setDocumentsError(false);
    setShowHelpForm(false);
    setShowDontNeedHelp(false);
    setIsArchiveOpen(false);
    setIframeUrl("");
    setIframeLoading(true);
    setAdditionalVisitReason("");
    setSelectedAdditionalVisitReason("");
    setPaymentError("");
    setPaymentSuccess("");

    if (selectedMotif === "pay_diag") {
      await handlePaymentMotif();
    } else if (selectedMotif === "report" || selectedMotif === "invoice") {
      await loadDocuments(selectedMotif);
    } else if (selectedMotif === "provide_documents") {
      const property = properties.find(p => p.id.toString() === selectedProperty);
      if (property && property.customerDocument) {
        setIframeUrl(property.customerDocument);
      } else {
        setShowHelpForm(true);
      }
    } else if (selectedMotif === "additional_visit") {
      // Ne rien faire, attendre la sélection du type de visite
    } else {
      setShowHelpForm(true);
    }
  };

  const handleMotifSelectChange = async (newValue) => {
    trackEvent('support_issue_change', { 
      issueType: newValue 
    });
    
    setMotif(newValue);
    setFiles([]);
    setDocuments([]);
    setDocumentsError(false);
    setShowHelpForm(false);
    setShowDontNeedHelp(false);
    setIsArchiveOpen(false);
    setIframeUrl("");
    setIframeLoading(true);
    setAdditionalVisitReason("");
    setSelectedAdditionalVisitReason("");
    setPaymentError("");


    if (newValue === "pay_diag") {
      await handlePaymentMotif();
    } else if (newValue === "report" || newValue === "invoice") {
      setLoadingDocuments(true);
      await loadDocuments(newValue);
    } else if (newValue === "provide_documents") {
      const property = properties.find(p => p.id.toString() === selectedProperty);
      if (property && property.customerDocument) {
        setIframeUrl(property.customerDocument);
      } else {
        setShowHelpForm(true);
      }
    } else if (newValue === "additional_visit") {
      // Ne rien faire, attendre la sélection du type de visite
    } else {
      setShowHelpForm(true);
    }
  };

  const handleAdditionalVisitReasonClick = (reason) => {
    trackEvent('support_visit_reason_select', { 
      reason: reason 
    });
    
    setAdditionalVisitReason(reason);
    setSelectedAdditionalVisitReason(reason);
    setShowHelpForm(true);

  };

  const loadDocuments = async (type, propertyId = selectedProperty) => {
    trackEvent('support_load_documents', { 
      documentType: type,
      propertyId: propertyId
    });
    
    if (!propertyId) return;
    try {
      setLoadingDocuments(true);
      setDocuments([]);
      setDocumentsError(false);

      const response = await axiosInstance.get(
        `/documents/${propertyId}?type=${type}`
      );
      const docs = response?.data?.documents;
      if (Array.isArray(docs)) {
        setDocuments(docs);
        if (docs.length === 0) {
          setShowHelpForm(true);
        }
      } else {
        setDocumentsError(true);
        setShowHelpForm(true);
      }
    } catch (err) {
      console.error("Erreur lors du chargement des documents:", err);
      setDocumentsError(true);
      setShowHelpForm(true);
    } finally {
      setLoadingDocuments(false);
    }
  };

  const handleDownload = (doc) => {
    trackEvent('support_download_document', { 
      documentName: doc.name,
      documentType: doc.type
    });
    
    setDownloadingDoc(doc.id);
    window.open(doc.downloadLink || doc.file, "_blank");
    setTimeout(() => setDownloadingDoc(null), 1000);
  };

  const handleConsult = (doc) => {
    trackEvent('support_view_document', { 
      documentName: doc.name,
      documentType: doc.type
    });
    
    window.open(doc.webViewLink || doc.file, "_blank");
  };

  const renderDocumentCard = (doc, index) => {
    const isInvoice = doc.type === "invoice";
    const isUnpaidInvoice = isInvoice && doc.status !== "paid";

    return (
      <Card
        key={index}
        className="p-4 border border-gray-200 hover:border-gray-300 transition-all duration-300"
      >
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div className="flex items-start gap-4 flex-1">
            {doc.type === "report" ? (
              <DocumentDuplicateIcon
                className="h-10 w-10"
                style={{ color: colors.dimoPrimary }}
              />
            ) : (
              <DocumentTextIcon
                className="h-10 w-10"
                style={{ color: colors.dimoPrimary }}
              />
            )}
            <div className="flex-1 min-w-0">
              <Typography variant="h6" className="mb-1 text-gray-900 break-words">
                {doc.name}
              </Typography>
              {doc.amount && (
                <Typography variant="small" color="gray" className="mb-2">
                  Montant: {doc.amount}€
                </Typography>
              )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto mt-2 sm:mt-0">
            {isInvoice && (
              <div className="flex items-center gap-2 mr-2">
                <CheckCircleIcon
                  className={`h-4 w-4 ${doc.status === "paid" ? "text-green-500" : "text-orange-500"
                  }`}
                />
                <Typography
                  variant="small"
                  className={
                    doc.status === "paid" ? "text-green-500" : "text-orange-500"
                  }
                >
                  {doc.status === "paid" ? "Payée" : "En attente"}
                </Typography>
              </div>
            )}
            {isUnpaidInvoice && doc.payLink && (
              <a
                href={doc.payLink}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full sm:w-auto"
              >
                <Button
                  size="sm"
                  className="w-full flex items-center justify-center gap-2"
                  style={{ backgroundColor: colors.dimoPrimary }}
                >
                  <CreditCardIcon className="h-4 w-4" />
                  Payer
                </Button>
              </a>
            )}
            <Button
              variant="outlined"
              style={{
                borderColor: colors.dimoPrimary,
                color: colors.dimoPrimary,
              }}
              size="sm"
              className="w-full sm:w-auto flex items-center justify-center gap-2 hover:bg-gray-50 cursor-pointer hover:shadow-md hover:opacity-100"
              onClick={() => handleConsult(doc)}
            >
              <EyeIcon className="h-4 w-4" />
              Consulter
            </Button>
            <Button
              variant={isUnpaidInvoice ? "outlined" : "filled"}
              size="sm"
              className="w-full sm:w-auto flex items-center justify-center gap-2"
              style={
                isUnpaidInvoice
                  ? { borderColor: colors.dimoPrimary, color: colors.dimoPrimary }
                  : { backgroundColor: colors.dimoPrimary }
              }
              onClick={() => handleDownload(doc)}
              disabled={downloadingDoc === doc.id}
            >
              {downloadingDoc === doc.id ? (
                <Spinner
                  className="h-8 w-8"
                  style={{ color: colors.dimoPrimary }}
                />
              ) : (
                <ArrowDownTrayIcon className="h-4 w-4" />
              )}
              {downloadingDoc === doc.id ? "..." : "Télécharger"}
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError("");

    trackEvent('support_submit_request', { 
      propertyId: selectedProperty,
      issueType: motif
    });

    try {
      // Créer un FormData pour envoyer les fichiers
      const formData = new FormData();
      
      // Ajouter les champs du formulaire
      formData.append("propertyId", selectedProperty);
      formData.append("subject", motif);
      formData.append("additionalVisitReason", selectedAdditionalVisitReason || "");
      formData.append("message", message);
      
      // Ajouter chaque fichier individuellement
      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("files", file);
        });
      }

      // Debug - vérifier le contenu du FormData
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      // Envoyer la requête avec le bon Content-Type
      const response = await axiosInstance.post("/support", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      if (response.data.success) {
        trackEvent('support_submit_success', { 
          propertyId: selectedProperty
        });
        setSubmitSuccess(true);
      } else {
        throw new Error(response.data.message || "Erreur lors de l'envoi");
      }
    } catch (err) {
      console.error("Erreur en soumettant la demande:", err);
      trackEvent('support_submit_error', {
        propertyId: selectedProperty,
        error: err.response?.data?.message || 'unknown'
      });
      setSubmitError(
        err.response?.data?.message || 
        "Une erreur s'est produite lors de l'envoi de votre demande."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Layout>
        <SupportSkeleton />
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Typography color="red" className="text-center">
          {error}
        </Typography>
      </Layout>
    );
  }

  const activeDocuments = Array.isArray(documents)
    ? documents.filter((doc) => !doc.name?.startsWith("[ARCHIVE]"))
    : [];
  const archivedDocuments = Array.isArray(documents)
    ? documents.filter((doc) => doc.name?.startsWith("[ARCHIVE]"))
    : [];

  return (
    <Layout>
      <Helmet>
        <title>Support - Espace client Dimo Diagnostic</title>
        <meta
          name="description"
          content="Contactez le support Dimo Diagnostic pour toute question ou problème."
        />
      </Helmet>

      <Card className="w-full mb-6 shadow-md">
        <div className="p-6">
          <Typography variant="h4" color="blue-gray" className="mb-6">
            Support
          </Typography>

          {!selectedProperty && properties.length > 1 ? (
            <>
              <Typography color="blue-gray" className="mb-4">
                Veuillez sélectionner le dossier concerné par votre demande de support :
              </Typography>
              <div className="space-y-4">
                {properties.map((property) => (
                  <Card
                    key={property.id}
                    className="p-4 bg-gray-50 border border-gray-200 hover:bg-gray-100 hover:border-gray-300 hover:shadow-md transition-all duration-300 cursor-pointer"
                    onClick={() => handlePropertySelect(property.id)}
                  >
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <UserIcon
                          className="h-5 w-5"
                          style={{ color: colors.dimoDark3 }}
                        />
                        <Typography variant="h6" color="blue-gray">
                          {property.name}
                        </Typography>
                      </div>
                      <div className="flex items-center space-x-2">
                        <IdentificationIcon
                          className="h-5 w-5"
                          style={{ color: colors.dimoDark3 }}
                        />
                        <Typography variant="small" color="gray">
                          Dossier: {property.id}
                        </Typography>
                      </div>
                      <div className="flex items-center space-x-2">
                        <MapPinIcon
                          className="h-5 w-5"
                          style={{ color: colors.dimoDark3 }}
                        />
                        <Typography variant="small" color="gray">
                          Adresse: {property.address}
                        </Typography>
                      </div>
                      <div className="flex items-center space-x-2">
                        <CalendarIcon
                          className="h-5 w-5"
                          style={{ color: colors.dimoDark3 }}
                        />
                        <Typography variant="small" color="gray">
                          Date du rendez-vous: {property.appointmentText || "Non planifié"}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </>
          ) : (
            <>
              {properties.length > 1 && selectedProperty && (
                <div className="mb-4">
                  <PropertySelector
                    selectedProperty={selectedProperty}
                    setSelectedProperty={handlePropertySelect}
                    properties={properties}
                    isLoading={loading}
                  />
                </div>
              )}

              {submitSuccess ? (
                <div className="max-w-4xl mx-auto">
                  <div className="text-center mb-8">
                    <CheckCircleIcon className="h-24 w-24 text-green-500 mx-auto mb-6" />
                    <Typography variant="h3" color="green" className="mb-3">
                      Votre demande a bien été envoyée !
                    </Typography>
                    <Typography variant="h5" color="blue-gray">
                      Notre équipe vous répondra dans les plus brefs délais.
                  </Typography>
                  </div>

                  <Card className="p-8 bg-gray-50 border border-gray-200">
                    <Typography variant="h4" color="blue-gray" className="mb-6">
                      Récapitulatif de votre demande
                    </Typography>
                    <div className="space-y-6">
                      <div className="flex items-start gap-4">
                        <IdentificationIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                        <div>
                          <Typography variant="paragraph" className="text-gray-600 font-medium mb-1">
                            Dossier
                          </Typography>
                          <Typography variant="h6" className="text-gray-900">
                            {selectedProperty}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <MapPinIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                        <div>
                          <Typography variant="paragraph" className="text-gray-600 font-medium mb-1">
                            Adresse
                          </Typography>
                          <Typography variant="h6" className="text-gray-900">
                            {properties.find(p => p.id.toString() === selectedProperty)?.address}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex items-start gap-4">
                        <DocumentTextIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                        <div>
                          <Typography variant="paragraph" className="text-gray-600 font-medium mb-1">
                            Motif
                          </Typography>
                          <Typography variant="h6" className="text-gray-900">
                            {MOTIFS.find(m => m.value === motif)?.title || motif}
                          </Typography>
                        </div>
                      </div>
                      {message && (
                        <div className="flex items-start gap-4">
                          <DocumentDuplicateIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                          <div>
                            <Typography variant="paragraph" className="text-gray-600 font-medium mb-1">
                              Message
                            </Typography>
                            <Typography variant="h6" className="text-gray-900 whitespace-pre-wrap">
                              {message}
                            </Typography>
                          </div>
                        </div>
                      )}
                      {files.length > 0 && (
                        <div className="flex items-start gap-4">
                          <FolderIcon className="h-6 w-6 mt-1" style={{ color: colors.dimoPrimary }} />
                          <div>
                            <Typography variant="paragraph" className="text-gray-600 font-medium mb-1">
                              Documents joints
                            </Typography>
                            <Typography variant="h6" className="text-gray-900">
                              {files.length} fichier(s)
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>

                  <div className="text-center mt-8">
                    <Typography variant="h5" color="blue-gray" className="mb-6">
                      Notre équipe vous répondra dans les plus brefs délais.
                    </Typography>
                    <Button
                      onClick={() => navigate("/dashboard")}
                      style={{ backgroundColor: colors.dimoPrimary }}
                      className="flex items-center gap-2 mx-auto px-6 py-3"
                      size="lg"
                    >
                      <HomeIcon className="h-5 w-5" />
                    Retour à l'accueil
                  </Button>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                  {!motif && (
                    <div className="space-y-4">
                      {properties.length === 1 && selectedProperty && (
                        <Card className="p-4 bg-gray-50 border border-gray-200 mb-6">
                          <div className="space-y-2">
                            <div className="flex items-center space-x-2">
                              <UserIcon
                                className="h-5 w-5"
                                style={{ color: colors.dimoDark3 }}
                              />
                              <Typography variant="h6" color="blue-gray">
                                {properties[0].name}
                              </Typography>
                            </div>
                            <div className="flex items-center space-x-2">
                              <IdentificationIcon
                                className="h-5 w-5"
                                style={{ color: colors.dimoDark3 }}
                              />
                              <Typography variant="small" color="gray">
                                Dossier: {properties[0].id}
                              </Typography>
                            </div>
                            <div className="flex items-center space-x-2">
                              <MapPinIcon
                                className="h-5 w-5"
                                style={{ color: colors.dimoDark3 }}
                              />
                              <Typography variant="small" color="gray">
                                Adresse: {properties[0].address}
                              </Typography>
                            </div>
                            <div className="flex items-center space-x-2">
                              <CalendarIcon
                                className="h-5 w-5"
                                style={{ color: colors.dimoDark3 }}
                              />
                              <Typography variant="small" color="gray">
                                Date du rendez-vous: {properties[0].appointmentText || "Non planifié"}
                              </Typography>
                            </div>
                          </div>
                        </Card>
                      )}
                      <Typography variant="h5" color="blue-gray">
                        Sélectionnez le motif de votre demande :
                      </Typography>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                        {MOTIFS.map((m, index) => {
                          const IconComp = m.icon;
                          return (
                            <Card
                              key={index}
                              className="p-4 flex flex-col items-center text-center bg-gray-50 border border-gray-200 hover:bg-gray-200 hover:border-gray-300 hover:shadow-md transition-all duration-300 cursor-pointer"
                              onClick={() => handleMotifCardClick(m.value)}
                            >
                              <IconComp
                                className="h-10 w-10 mb-2"
                                style={{ color: colors.dimoPrimary }}
                              />
                              <Typography
                                variant="h6"
                                color="blue-gray"
                                className="mb-1"
                              >
                                {m.title}
                              </Typography>
                            </Card>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {motif && (
                    <>
                      <div className="flex flex-col md:flex-row items-center gap-4">
                        <Select
                          label="Motif"
                          value={motif}
                          onChange={handleMotifSelectChange}
                          required
                          className="w-full"
                        >
                          {MOTIFS.map((m) => (
                            <Option key={m.value} value={m.value}>
                              {m.title}
                            </Option>
                          ))}
                        </Select>

                        <Button
                          variant="outlined"
                          onClick={() => {
                            setMotif("");
                            setShowHelpForm(false);
                            setAdditionalVisitReason("");
                            setSelectedAdditionalVisitReason("");
                          }}
                          className="w-fit px-4 h-10 flex items-center gap-2"
                          style={{
                            borderColor: colors.dimoPrimary,
                            color: colors.dimoPrimary,
                          }}
                        >
                          <XMarkIcon className="h-4 w-4" />
                          Changer de motif
                        </Button>
                      </div>

                      {(motif === "report" || motif === "invoice" || motif === "provide_documents" || motif === "pay_diag") && (
                        <div className="mt-4 space-y-4">
                          <Typography variant="h5" color="blue-gray">
                            {motif === "report" && "Votre rapport d'expertise"}
                            {(motif === "invoice" || motif === "pay_diag") && "Votre facture"}
                            {motif === "provide_documents" && "Documents à fournir"}
                          </Typography>

                          {loadingDocuments && (
                            <div className="flex justify-center items-center py-6">
                              <Spinner
                                className="h-8 w-8"
                                style={{ color: colors.dimoPrimary }}
                              />
                              <Typography
                                variant="small"
                                color="gray"
                                className="ml-2"
                              >
                                Chargement des documents...
                              </Typography>
                            </div>
                          )}

                          {!loadingDocuments && !documentsError && activeDocuments.length > 0 && (
                            <div className="space-y-4">
                              {activeDocuments.map((doc, index) =>
                                renderDocumentCard(doc, index)
                              )}
                            </div>
                          )}

                          {!loadingDocuments && !documentsError && archivedDocuments.length > 0 && (
                            <Card className="p-4 border border-gray-200 hover:border-gray-300 transition-all duration-300">
                              <button
                                className="w-full flex items-center justify-between"
                                onClick={() => setIsArchiveOpen(!isArchiveOpen)}
                                aria-expanded={isArchiveOpen}
                                aria-controls="archived-documents"
                                type="button"
                              >
                                <Typography variant="h6" className="text-gray-900">
                                  Documents archivés ({archivedDocuments.length})
                                </Typography>
                                {isArchiveOpen ? (
                                  <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                                ) : (
                                  <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                )}
                              </button>
                              {isArchiveOpen && (
                                <div
                                  id="archived-documents"
                                  className="mt-4 space-y-4"
                                >
                                  {archivedDocuments.map((doc, index) =>
                                    renderDocumentCard(doc, index)
                                  )}
                                </div>
                              )}
                            </Card>
                          )}

                          {!loadingDocuments && motif !== "provide_documents" && (documentsError || documents.length === 0) && motif !== "pay_diag" && (
                            <Card className="p-8 bg-gray-50">
                              <div className="text-center">
                                <DocumentTextIcon className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                                <Typography
                                  variant="h6"
                                  color="gray"
                                  className="mb-2"
                                >
                                  Aucun document disponible
                                </Typography>
                                <Typography color="gray">
                                  Les documents apparaîtront ici une fois générés.
                                </Typography>
                              </div>
                            </Card>
                          )}

                          {motif === "provide_documents" && iframeUrl && (
                            <div className="mt-4 space-y-4">
                              <div className="relative w-full h-[calc(100vh-200px)]">
                                {iframeLoading && (
                                  <div className="absolute inset-0 flex justify-center items-center bg-gray-100 bg-opacity-75">
                                    <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
                                  </div>
                                )}
                                <iframe
                                  src={iframeUrl}
                                  title="Documents à fournir"
                                  className="w-full h-full border-0"
                                  onLoad={() => setIframeLoading(false)}
                                />
                              </div>
                            </div>
                          )}

                          {motif === "pay_diag" && !paymentError && !loadingDocuments && (
                            <div className="space-y-6">


                              {/* S'il y a plusieurs documents, on les mappe tous comme pour "report"/"invoice". */}
                              {documents.length > 0 ? (
                                <>

                                  {/* On vérifie si au moins une facture est impayée */}
                                  {documents.some((doc) => doc.type === "invoice" && doc.status !== "paid") && (
                                    <div className="space-y-4 pt-4 border-t border-gray-200">
                                      <Typography variant="h6" color="blue-gray">
                                        Options de paiement
                                      </Typography>

                                      <Card
                                        className="p-4 hover:shadow-lg transition-all duration-300 cursor-pointer bg-white"
                                        onClick={() => window.open(paymentLink, "_blank")}
                                      >
                                        <div className="flex items-start gap-4">
                                          <div className="rounded-full bg-blue-50 p-3">
                                            <CreditCardIcon className="h-6 w-6 text-blue-500" />
                                          </div>
                                          <div>
                                            <Typography variant="h6" color="blue-gray" className="mb-1">
                                              Carte bancaire
                                            </Typography>
                                            <Typography variant="small" color="gray">
                                              Payez par carte bancaire. Service fourni par Stripe. Votre rapport sera disponible sous 30 secondes*
                                            </Typography>
                                          </div>
                                        </div>
                                      </Card>

                                      <Card
                                        className="p-4 hover:shadow-lg transition-all duration-300 cursor-pointer bg-white"
                                        onClick={() => window.open(paymentLink, "_blank")}
                                      >
                                        <div className="flex items-start gap-4">
                                          <div className="rounded-full bg-green-50 p-3">
                                            <ArrowPathIcon className="h-6 w-6 text-green-500" />
                                          </div>
                                          <div className="flex-grow">
                                            <div className="flex items-center gap-2">
                                              <Typography variant="h6" color="blue-gray" className="mb-1">
                                                E-Virement
                                              </Typography>
                                              <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded">
                                                Le plus utilisé
                                              </span>
                                            </div>
                                            <Typography variant="small" color="gray">
                                              Un virement classique en mieux ! La transaction est directement rapprochée et votre rapport sera disponible sous 30 secondes*
                                            </Typography>
                                          </div>
                                        </div>
                                      </Card>

                                      <Card
                                        className="p-4 hover:shadow-lg transition-all duration-300 cursor-pointer bg-white"
                                        onClick={() => window.open(paymentLink, "_blank")}
                                      >
                                        <div className="flex items-start gap-4">
                                          <div className="rounded-full bg-gray-50 p-3">
                                            <BanknotesIcon className="h-6 w-6 text-gray-500" />
                                          </div>
                                          <div>
                                            <Typography variant="h6" color="blue-gray" className="mb-1">
                                              Virement
                                            </Typography>
                                            <Typography variant="small" color="gray">
                                              Le virement classique. Sa gestion prend plus de temps que les autres solutions. Votre rapport sera disponible dès traitement par nos équipes.
                                            </Typography>
                                          </div>
                                        </div>
                                      </Card>

                                      <Typography variant="small" color="gray" className="text-xs italic">
                                        *Sous réserve de la finalisation par nos équipes ou absence d'élément(s) manquant(s) du donneur d'ordre.
                                      </Typography>
                                    </div>
                                  )}
                                </>
                              ) : (
                                // Aucun document disponible
                                <Card className="p-8 bg-gray-50">
                                  <div className="text-center">
                                    <DocumentTextIcon className="h-12 w-12 mx-auto mb-4 text-gray-400" />
                                    <Typography variant="h6" color="gray" className="mb-2">
                                      Aucun document de facturation disponible
                                    </Typography>
                                    <Typography color="gray">
                                      La facture apparaîtra ici lorsqu'elle sera générée.
                                    </Typography>
                                  </div>
                                </Card>
                              )}
                            </div>
                          )}

                          {/* Gestion de l'erreur liée à la facture ou au paiement */}
                          {motif === "pay_diag" && paymentError && (
                            <div className="flex items-start gap-4 bg-red-50 p-4 rounded-lg">
                              <ExclamationTriangleIcon className="h-6 w-6 text-red-500 flex-shrink-0" />
                              <div>
                                <Typography variant="h6" color="red" className="mb-2">
                                  Impossible de procéder au paiement
                                </Typography>
                                <Typography color="red" className="opacity-75">
                                  {paymentError}
                                </Typography>
                              </div>
                            </div>
                          )}

                          {motif === "pay_diag" && paymentSuccess && (
                            <div className="flex items-start gap-4 bg-green-50 p-4 rounded-lg mb-4">
                              <CheckCircleIcon className="h-6 w-6 text-green-500 flex-shrink-0" />
                              <div>
                                <Typography variant="h6" color="green" className="mb-2">
                                  Paiement confirmé
                                </Typography>
                                <Typography color="green" className="opacity-75">
                                  {paymentSuccess}
                                </Typography>
                              </div>
                            </div>
                          )}

                          <div className="pt-6 border-t border-gray-200">
                              <Typography variant="h5" color="blue-gray" className="mb-4">
                                Avez-vous toujours besoin d'aide ?
                            </Typography>
                            <div className="flex gap-4">
                              <Button
                                  onClick={() => { setShowDontNeedHelp(true); setShowHelpForm(false); }}
                                  className="flex items-center gap-2"
                                  style={{ backgroundColor: colors.dimoPrimary }}
                                >
                                  Non
                                </Button>
                                <Button
                                  onClick={() => { setShowHelpForm(true); setShowDontNeedHelp(false); }}
                                className="flex items-center gap-2"
                                variant="outlined"
                                style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
                              >
                                Oui
                              </Button>
                              </div>
                            </div>
                     </div>
                    
                    )}

                      {motif === "additional_visit" && additionalVisitReason && (
                        <div className="mt-4 space-y-4">
                          <div className="flex flex-col md:flex-row items-center gap-4">
                            <Select
                              label="Raison de la visite complémentaire"
                              value={selectedAdditionalVisitReason}
                              onChange={(value) => setSelectedAdditionalVisitReason(value)}
                              required
                              className="w-full"
                            >
                              {ADDITIONAL_VISIT_REASONS.map((reason) => (
                                <Option key={reason.value} value={reason.value}>
                                  {reason.title}
                                </Option>
                              ))}
                            </Select>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setAdditionalVisitReason("");
                                setSelectedAdditionalVisitReason("");
                                setShowHelpForm(false);
                              }}
                              className="w-fit px-4 h-10 flex items-center gap-2"
                              style={{
                                borderColor: colors.dimoPrimary,
                                color: colors.dimoPrimary,
                              }}
                            >
                              <XMarkIcon className="h-4 w-4" />
                              Changer de raison
                            </Button>
                          </div>

                        </div>
                      )}


                      {showHelpForm && (
                        <div className="mt-6 space-y-4">
                          <Typography variant="h5" color="blue-gray">
                            Comment pouvons-nous vous aider ?
                          </Typography>
                          <Textarea
                            label="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            rows={6}
                            required
                          />

                          <StyledDocumentUpload
                            onFileUpload={(uploadedFiles) => {
                              setFiles(uploadedFiles);
                            }}
                          />

                          {submitError && (
                            <Typography color="red" className="mt-2">
                              {submitError}
                            </Typography>
                          )}

                              <Button
                            type="submit"
                                className="flex items-center gap-2"
                                style={{ backgroundColor: colors.dimoPrimary }}
                            disabled={isSubmitting}
                              >
                            {isSubmitting ? "Envoi en cours..." : "Envoyer"}
                              </Button>
                            </div>
                      )}

                      {showDontNeedHelp && (
                        <div className="mt-4">
                          <Typography variant="h5" color="green">
                            Nous sommes ravis de vous avoir aidé !
                          </Typography>
                          <Typography color="blue-gray">
                            Si vous avez besoin d'aide ultérieurement, n'hésitez pas à nous contacter.
                          </Typography>
                        </div>
                      )}





                      {motif === "additional_visit" && !additionalVisitReason && (
                        <div className="mt-4 space-y-4">
                          <Typography variant="h5" color="blue-gray">
                            Sélectionnez la raison de votre visite complémentaire :
                          </Typography>
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                            {ADDITIONAL_VISIT_REASONS.map((reason, index) => {
                              const IconComp = reason.icon;
                              return (
                                <Card
                                  key={index}
                                  className="p-4 flex flex-col items-center text-center bg-gray-50 border border-gray-200 hover:bg-gray-200 hover:border-gray-300 hover:shadow-md transition-all duration-300 cursor-pointer"
                                  onClick={() => handleAdditionalVisitReasonClick(reason.value)}
                                >
                                  <IconComp
                                    className="h-10 w-10 mb-2"
                                    style={{ color: colors.dimoPrimary }}
                                  />
                                  <Typography
                                    variant="h6"
                                    color="blue-gray"
                                    className="mb-1"
                                  >
                                    {reason.title}
                                  </Typography>
                                </Card>
                              );
                            })}
                          </div>
                        </div>
                      )}


                    </>
                  )}
                </form>
              )}
            </>
          )}
        </div>
      </Card>
    </Layout>
  );
}

