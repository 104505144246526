import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Spinner, Typography } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import { useProperties } from "../contexts/PropertiesContext";
import { colors } from "../utils/colors";
import { UserIcon, IdentificationIcon, MapPinIcon, CalendarIcon } from "@heroicons/react/24/outline";
import { trackEvent } from "../utils/analytics";

export default function Documents() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { properties, loading, error } = useProperties();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [iframeLoading, setIframeLoading] = useState(true);

  useEffect(() => {
    if (!loading && properties.length > 0) {
      if (id) {
        const property = properties.find(p => p.id.toString() === id);
        if (property && property.actionsNeeded.customerDocument) {
          setSelectedProperty(property);
          
          // Tracking de la visualisation des documents pour une propriété
          trackEvent('documents_view_property', {
            propertyId: property.id
          });
        } else {
          navigate("/documents", { replace: true });
          
          // Tracking de la redirection (propriété non trouvée ou sans document)
          trackEvent('documents_redirect', {
            fromId: id,
            reason: property ? 'no_documents_needed' : 'property_not_found'
          });
        }
      } else {
        const propertyWithDocumentNeed = properties.find(p => p.actionsNeeded.customerDocument);
        if (propertyWithDocumentNeed) {
          navigate(`/documents/${propertyWithDocumentNeed.id}`, { replace: true });
          
          // Tracking de la redirection automatique vers la première propriété avec documents
          trackEvent('documents_auto_select', {
            propertyId: propertyWithDocumentNeed.id
          });
        } else {
          setSelectedProperty(null);
          
          // Tracking aucun document à fournir
          trackEvent('documents_none_needed', {});
        }
      }
    }
  }, [id, properties, loading, navigate]);

  const propertiesNeedingDocuments = properties.filter(p => p.actionsNeeded.customerDocument);

  const handlePropertyChange = (newId) => {
    const property = properties.find(p => p.id.toString() === newId.toString());
    
    // Tracking du changement de propriété
    trackEvent('documents_change_property', {
      fromPropertyId: selectedProperty ? selectedProperty.id : null,
      toPropertyId: newId
    });
    
    setSelectedProperty(property);
    navigate(`/documents/${newId}`, { replace: true });
    setIframeLoading(true);
  };

  const handleIframeLoad = () => {
    setIframeLoading(false);
    
    // Tracking du chargement de l'iframe
    if (selectedProperty) {
      trackEvent('documents_iframe_loaded', {
        propertyId: selectedProperty.id
      });
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Documents à fournir - Dimo Diagnostic</title>
        <meta name="description" content="Consultez et fournissez les documents nécessaires pour vos diagnostics immobiliers." />
        <link rel="canonical" href={`https://client.dimo-diagnostic.net/documents${id ? `/${id}` : ''}`} />
      </Helmet>
      <Card className="mb-6 shadow-md">
        <div className="p-6 space-y-6">
          {loading ? (
            <div className="flex justify-center items-center h-[calc(100vh-200px)]">
              <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
            </div>
          ) : error ? (
            <p className="text-red-500">Une erreur s'est produite lors du chargement des données. Veuillez réessayer plus tard.</p>
          ) : propertiesNeedingDocuments.length === 0 ? (
            <p>Il n'y a actuellement aucun dossier nécessitant l'ajout de documents.</p>
          ) : (
            <>
              {propertiesNeedingDocuments.length > 1 && (
                <PropertySelector
                  selectedProperty={selectedProperty ? selectedProperty.id : null}
                  setSelectedProperty={handlePropertyChange}
                  properties={propertiesNeedingDocuments}
                  isLoading={loading}
                />
              )}

              {/* Titre de la page */}
              <Typography variant="h4" color="blue-gray" className="mb-5">
                Documents à fournir
              </Typography>

              {/* Boîte d'information sur le dossier */}
              {selectedProperty && (
                <Card className="p-4 bg-gray-50 border border-gray-200 mb-6">
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <UserIcon
                        className="h-5 w-5"
                        style={{ color: colors.dimoDark3 }}
                      />
                      <Typography variant="h6" color="blue-gray">
                        {selectedProperty.name}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <IdentificationIcon
                        className="h-5 w-5"
                        style={{ color: colors.dimoDark3 }}
                      />
                      <Typography variant="small" color="gray">
                        Dossier: {selectedProperty.id}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <MapPinIcon
                        className="h-5 w-5"
                        style={{ color: colors.dimoDark3 }}
                      />
                      <Typography variant="small" color="gray">
                        Adresse: {selectedProperty.address}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CalendarIcon
                        className="h-5 w-5"
                        style={{ color: colors.dimoDark3 }}
                      />
                      <Typography variant="small" color="gray">
                        Date du rendez-vous: {selectedProperty.appointmentText || "Rendez-vous non défini"}
                      </Typography>
                    </div>
                  </div>
                </Card>
              )}

              {selectedProperty && (
                <div className="relative w-full h-[calc(100vh-100px)]">
                  {iframeLoading && (
                    <div className="absolute inset-0 flex justify-center items-center bg-gray-100 bg-opacity-75">
                      <Spinner className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
                    </div>
                  )}
                  <iframe
                    src={selectedProperty.customerDocument}
                    title="Documents à fournir"
                    className="w-full h-full border-0"
                    onLoad={handleIframeLoad}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Card>
    </Layout>
  );
}