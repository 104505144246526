import React from "react";
import { Link } from "react-router-dom";
import { Card, Typography, Button } from "@material-tailwind/react";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import {
  DocumentTextIcon,
  CreditCardIcon,
  QuestionMarkCircleIcon,
  ArrowUpTrayIcon,
  HomeIcon,
  MapPinIcon,
  IdentificationIcon,
  ClockIcon,
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  UserIcon,
  RocketLaunchIcon
} from "@heroicons/react/24/outline";
import { useProperties } from "../contexts/PropertiesContext";
import RecommendedDeals from "../components/deals/RecommendedDeals";
import { trackEvent } from "../utils/analytics";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const { properties, quotes, loading, error } = useProperties();
  const navigate = useNavigate();
  console.log("quotes", quotes);

  // Fonction pour gérer le clic sur un service avec tracking
  const handleServiceClick = (service) => (e) => {
    e.preventDefault();
    
    // Déterminer le nom de l'événement en fonction du chemin du service
    let eventName;
    switch(service.path) {
      case '/diagnostics':
        eventName = 'dashboard_service_diagnostics';
        break;
      case '/documents':
        eventName = 'dashboard_service_documents';
        break;
      case '/invoices':
        eventName = 'dashboard_service_invoices';
        break;
      case '/support':
        eventName = 'dashboard_service_support';
        break;
      default:
        eventName = `dashboard_service_${service.path.substring(1)}`;
    }
    
    // Tracker l'événement avec le nom spécifique au service
    trackEvent(eventName, {
      service: service.title
    });
    
    // Naviguer vers la page du service
    navigate(service.path);
  };

  const services = [
    {
      icon: DocumentTextIcon,
      title: "Consulter vos diagnostics",
      description: "Accédez à tous vos diagnostics immobiliers en ligne.",
      path: "/diagnostics",
    },
    {
      icon: ArrowUpTrayIcon,
      title: "Échanger des documents",
      description: "Échangez facilement vos documents avec nos services.",
      path: "/documents",
    },
    {
      icon: CreditCardIcon,
      title: "Accéder à vos factures",
      description: "Consultez et gérez toutes vos factures en quelques clics.",
      path: "/invoices",
    },
    {
      icon: QuestionMarkCircleIcon,
      title: "Page d'aide",
      description: "Trouvez les réponses à vos questions sur la page d'aide.",
      path: "/support",
    },
  ];

  const renderActionItems = (property) => {
    const actions = [];
    return actions;
  };

  if (loading) {
    return (
      <Layout>
        <Card className="mb-6 shadow-md">
          <div className="p-6 space-y-6 animate-pulse">
            {/* En-tête */}
            <div className="space-y-2">
              <div className="h-8 bg-gray-200 rounded w-48"></div>
              <div className="h-5 bg-gray-200 rounded w-64"></div>
            </div>

            {/* Services */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {[...Array(4)].map((_, index) => (
                <Card key={index} className="p-4 bg-gray-50 border border-gray-200">
                  <div className="flex flex-col items-center text-center">
                    <div className="w-10 h-10 rounded-full bg-gray-200 mb-2"></div>
                    <div className="h-5 bg-gray-200 rounded w-32 mb-2"></div>
                    <div className="h-4 bg-gray-200 rounded w-full"></div>
                  </div>
                </Card>
              ))}
            </div>

            {/* Section Devis */}
            <div className="space-y-4">
              <div className="h-6 bg-gray-200 rounded w-48"></div>
              <Card className="p-6 bg-gray-50 border border-gray-200">
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <div className="w-6 h-6 rounded-full bg-gray-200"></div>
                      <div className="h-6 bg-gray-200 rounded w-32"></div>
                    </div>
                    <div className="w-24 h-10 bg-gray-200 rounded"></div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {[...Array(4)].map((_, i) => (
                      <div key={i} className="flex items-center gap-2">
                        <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4">
                    <div className="h-4 bg-gray-200 rounded w-36 mb-2"></div>
                    <div className="flex flex-wrap gap-2">
                      {[...Array(3)].map((_, i) => (
                        <div key={i} className="h-6 bg-gray-200 rounded w-20"></div>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {/* Section Dossiers */}
            <div className="space-y-4">
              <div className="h-6 bg-gray-200 rounded w-36"></div>
              <Card className="p-6 bg-gray-50 border border-gray-200">
                <div className="space-y-4">
                  <div className="flex items-center gap-2 mb-4">
                    <div className="w-6 h-6 rounded-full bg-gray-200"></div>
                    <div className="h-6 bg-gray-200 rounded w-48"></div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {[...Array(6)].map((_, i) => (
                      <div key={i} className="flex items-center gap-2">
                        <div className="w-5 h-5 rounded-full bg-gray-200"></div>
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4">
                    <div className="w-full h-10 bg-gray-200 rounded"></div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Card>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Typography color="red" className="text-center">{error}</Typography>
      </Layout>
    );
  }

  return (
    <Layout>
      <Card className="mb-6 shadow-md">
        <div className="p-6 space-y-6">
          <div className="">
            <Typography variant="h3" className="mb-2 text-black">Bonjour 👋</Typography>
            <Typography variant="paragraph" className="mb-2 text-black">Bienvenue sur votre espace client !</Typography>
          </div>

          {properties.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {services.map((service, index) => (
                <a 
                  href={service.path} 
                  key={index} 
                  className="block h-full" 
                  onClick={handleServiceClick(service)}
                >
                  <Card
                    className="p-4 flex flex-col items-center text-center bg-gray-50 border border-gray-200 hover:bg-gray-100 hover:border-gray-300 hover:shadow-md transition-all duration-300 h-full"
                  >
                    <div className="flex flex-col items-center justify-between h-full">
                      <div className="flex flex-col items-center">
                        <service.icon className="h-10 w-10 mb-2" style={{ color: colors.dimoPrimary }} />
                        <Typography variant="h6" color="blue-gray" className="mb-1">{service.title}</Typography>
                      </div>
                      <Typography variant="small" color="gray" className="font-medium mt-2">{service.description}</Typography>
                    </div>
                  </Card>
                </a>
              ))}
            </div>
          )}

          {/* Section Devis */}
          {quotes && quotes.length > 0 && (
            <>
              <Typography variant="h4" className="mb-4 text-black">
                Vos devis en cours
              </Typography>
              <div className="grid grid-cols-1 md:grid-cols-1 gap-6 mb-6">
                {quotes.map((quote) => (
                  <Card key={quote.id} className="p-6 bg-gray-50 border border-gray-200 shadow-md transition-all duration-300">
                    <div className="mb-4">
                      <Typography variant="h5" color="blue-gray" className="flex items-center">
                        <RocketLaunchIcon className="h-6 w-6 mr-2" style={{ color: colors.dimoPrimary }} />
                        Devis {quote.name}
                      </Typography>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                      <div className="flex items-center space-x-2">
                        <IdentificationIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                        <Typography variant="small" className="text-black">
                          Dossier : {quote.id}
                        </Typography>
                      </div>
                      <div className="flex items-center space-x-2">
                        <HomeIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                        <Typography variant="small" className="text-black">
                          Type de bien : {quote.typeProperty}
                        </Typography>
                      </div>
                      <div className="flex items-center space-x-2">
                        <MapPinIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                        <Typography variant="small" className="text-black">
                          Adresse : {quote.address}
                        </Typography>
                      </div>
                      <div className="flex items-center space-x-2">
                        <CalendarIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                        <Typography variant="small" className="text-black">
                          Année de construction : {quote.buildingYear}
                        </Typography>
                      </div>
                      <div className="flex items-center space-x-2">
                        <ArrowTopRightOnSquareIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                        <Typography variant="small" className="text-black">
                          Superficie : {quote.square} m²
                        </Typography>
                      </div>
                    </div>
                    {quote.diagnostics && quote.diagnostics.length > 0 && (
                      <div className="mt-4">
                        <Typography variant="h6" color="blue-gray" className="mb-2">
                          Diagnostics demandés :
                        </Typography>
                        <div className="flex flex-wrap gap-2">
                          {quote.diagnostics.map((diagnostic, index) => (
                            <span
                              key={index}
                              className="px-2 py-1 bg-blue-gray-50 text-blue-gray-700 rounded-md text-xs"
                            >
                              {diagnostic}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="mt-4">
                      <Link to={`/quote/${quote.id}`}>
                        <Button
                          fullWidth
                          className="normal-case"
                          variant={renderActionItems(quote).length > 0 ? "outlined" : "filled"}
                          color={renderActionItems(quote).length > 0 ? "gray" : undefined}
                          style={renderActionItems(quote).length > 0 ? undefined : { backgroundColor: colors.dimoPrimary }}
                        >
                          Accéder au devis
                        </Button>
                      </Link>
                    </div>
                  </Card>
                ))}
              </div>
            </>
          )}

          {/* Section Dossiers */}
          {properties.length > 0 && (
            <Typography variant="h4" className="mb-4 text-black">
              {properties.length > 2 ? "Vos dossiers" : "Votre dossier"}
            </Typography>
          )}

          <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
            {properties.map((property) => (
              <Card key={property.id} className="p-6 bg-gray-50 border border-gray-200 shadow-md transition-all duration-300">
                <div className="mb-4">
                  <Typography variant="h5" color="blue-gray" className="flex items-center">
                    <UserIcon className="h-6 w-6 mr-2" style={{ color: colors.dimoPrimary }} />
                    Diagnostic {property.name}
                  </Typography>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                  <div className="flex items-center space-x-2">
                    <IdentificationIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                    <Typography variant="small" className="text-black">
                      Dossier: {property.id}
                    </Typography>
                  </div>
                  <div className="flex items-center space-x-2">
                    <MapPinIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                    <Typography variant="small" className="text-black">
                      Adresse: {property.address}
                    </Typography>
                  </div>
                  <div className="flex items-center space-x-2">
                    <HomeIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                    <Typography variant="small" className="text-black">
                      Type de bien: {property.typeProperty}
                    </Typography>
                  </div>
                  <div className="flex items-center space-x-2">
                    <CalendarIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                    <Typography variant="small" className="text-black">
                      Année de construction: {property.buildingYear}
                    </Typography>
                  </div>
                  <div className="flex items-center space-x-2">
                    <ArrowTopRightOnSquareIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                    <Typography variant="small" className="text-black">
                      Superficie: {property.square} m²
                    </Typography>
                  </div>
                  {property.appointmentText && (
                    <div className="flex items-center space-x-2">
                      <ClockIcon className="h-5 w-5" style={{ color: colors.dimoDark3 }} />
                      <Typography variant="small" className="text-black">
                        Date du rendez-vous: {property.appointmentText}
                      </Typography>
                    </div>
                  )}
                </div>
                  <div className="mt-4">
                    <Typography variant="h6" color="blue-gray" className="mb-2">
                      Diagnostics demandés :
                    </Typography>
                    <div className="flex flex-wrap gap-2">
                      {property.diagnostics.map((diagnostic, index) => (
                        <span key={index} className="px-2 py-1 bg-blue-gray-50 text-blue-gray-700 rounded-md text-xs">
                          {diagnostic}
                        </span>
                      ))}
                    </div>
                  </div>
                <div className="mt-4">
                  <Link to={`/diagnostics/${property.id}`}>
                    <Button
                      fullWidth
                      className="normal-case"
                      variant={renderActionItems(property).length > 0 ? "outlined" : "filled"}
                      color={renderActionItems(property).length > 0 ? "gray" : undefined}
                      style={renderActionItems(property).length > 0 ? undefined : { backgroundColor: colors.dimoPrimary }}
                    >
                      Accéder au dossier
                    </Button>
                  </Link>
                </div>
              </Card>
            ))}
            {properties.length === 0 && !quotes.length && (
              <Card className="p-6 bg-gray-50 border border-gray-200 shadow-md transition-all duration-300">
                <Typography variant="h5" color="blue-gray" className="flex items-center">
                  <UserIcon className="h-6 w-6 mr-2" style={{ color: colors.dimoPrimary }} />
                  Vous n'avez pas de dossier en cours
                </Typography>
                <div className="mt-4">
                  <Link to="https://www.dimo-diagnostic.net/devis-diagnostic-immobilier-tarifs">
                    <Button
                      fullWidth
                      className="normal-case"
                      style={{ backgroundColor: colors.dimoPrimary }}
                    >
                      Faire un devis
                    </Button>
                  </Link>
                </div>
              </Card>
            )}
          </div>
        </div>
      </Card>
      
      {/* Section Bons Plans */}
      <div className="mb-6">
        <RecommendedDeals source="dashboard" />
      </div>
    </Layout>
  );
}