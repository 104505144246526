import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography, Button, Textarea, Select, Option } from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import axiosInstance from "../utils/axiosInstance";
import { LightBulbIcon, SparklesIcon, ShieldCheckIcon, DocumentTextIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { trackEvent } from "../utils/analytics";

export default function Suggestion() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    category: "interface",
    description: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const categories = [
    { value: "interface", label: "Interface utilisateur", icon: SparklesIcon },
    { value: "feature", label: "Nouvelle fonctionnalité", icon: LightBulbIcon },
    { value: "security", label: "Sécurité", icon: ShieldCheckIcon },
    { value: "document", label: "Documentation", icon: DocumentTextIcon },
    { value: "support", label: "Support", icon: UserGroupIcon },
    { value: "other", label: "Autre", icon: UserGroupIcon }
  ];

  const handleChange = (field, value) => {
    if (field === "category") {
      trackEvent('suggestion_change_category', {
        category: value
      });
    }
    
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCancel = () => {
    trackEvent('suggestion_cancel', {});
    navigate(-1);
  };

  const handleReturnToDashboard = () => {
    trackEvent('suggestion_return_dashboard', {});
    navigate("/dashboard");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    if (!formData.category || !formData.description) {
      setError("Veuillez remplir tous les champs obligatoires.");
      setIsSubmitting(false);
      return;
    }

    // Tracking avant soumission
    trackEvent('suggestion_submit', {
      category: formData.category,
      descriptionLength: formData.description.length
    });

    try {
      await axiosInstance.post("/suggestion", formData);
      
      // Tracking succès
      trackEvent('suggestion_submit_success', {
        category: formData.category
      });
      
      setSuccess(true);
    } catch (error) {
      // Tracking erreur
      trackEvent('suggestion_submit_error', {});
      
      console.error("Erreur lors de l'envoi de la suggestion:", error);
      setError("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Suggérer une amélioration - Espace client Dimo Diagnostic</title>
        <meta name="description" content="Proposez une amélioration pour votre espace client Dimo Diagnostic." />
      </Helmet>
      <Card className="mb-6 shadow-md">
        <div className="p-6">
          <div className="mb-6">
            <Typography variant="h3" color="blue-gray" className="mb-4 w-full">
              Suggérer une amélioration
            </Typography>
            {success ? (
              <div className="text-center">
                <div className="flex justify-center mb-4">
                  <LightBulbIcon className="h-16 w-16 text-green-500" />
                </div>
                <Typography variant="h6" color="green" className="mb-4">
                  Votre suggestion a bien été envoyée !
                </Typography>
                <Typography color="blue-gray" className="mb-6">
                  Merci pour votre contribution ! Votre suggestion sera étudiée par notre équipe avec la plus grande attention.
                </Typography>
                <Button
                  style={{ backgroundColor: colors.dimoPrimary }}
                  onClick={handleReturnToDashboard}
                >
                  Retour à l'accueil
                </Button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <Typography className="mb-4">
                  Nous sommes toujours à l'écoute de nos clients. Vos suggestions nous aident à améliorer continuellement notre service.
                </Typography>

                <div>
                  <Typography variant="h6" color="blue-gray" className="mb-2">
                    Catégorie *
                  </Typography>
                  <Select
                    label="Choisissez une catégorie"
                    value={formData.category}
                    onChange={(value) => handleChange("category", value)}
                  >
                    {categories.map((category) => (
                      <Option key={category.value} value={category.value}>
                        <div className="flex items-center gap-2">
                          {React.createElement(category.icon, { className: "h-5 w-5" })}
                          {category.label}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>

                <div>
                  <Typography variant="h6" color="blue-gray" className="mb-2">
                    Description *
                  </Typography>
                  <Textarea
                    label="Décrivez votre suggestion"
                    value={formData.description}
                    onChange={(e) => handleChange("description", e.target.value)}
                    rows={6}
                  />
                </div>

                {error && (
                  <Typography color="red" className="mt-4">
                    {error}
                  </Typography>
                )}

                <div className="flex justify-end gap-4">
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                  >
                    Annuler
                  </Button>
                  <Button
                    type="submit"
                    style={{ backgroundColor: colors.dimoPrimary }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Envoi en cours..." : "Envoyer la suggestion"}
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Card>
    </Layout>
  );
} 