import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Typography,
  Button,
  Spinner,
  Input
} from "@material-tailwind/react";
import { Helmet } from "react-helmet";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Layout from "../components/layout/Layout";
import PropertySelector from "../components/PropertySelector";
import { useProperties } from "../contexts/PropertiesContext";
import { colors } from "../utils/colors";
import axiosInstance from "../utils/axiosInstance";
import { UserIcon, IdentificationIcon, MapPinIcon, CalendarIcon } from "@heroicons/react/24/outline";
import { trackEvent } from "../utils/analytics";

// Ajout du composant Skeleton
const FiscalIdSkeleton = () => {
  const isMobile = window.innerWidth <= 768;
  
  return (
    <div className={`flex ${isMobile ? "flex-col" : "flex-row"} gap-6 animate-pulse`}>
      {/* Partie gauche */}
      <div className={`${isMobile ? "w-full" : "w-1/2"} mb-6`}>
        <Card className="shadow-md p-6 space-y-6">
          {/* Skeleton pour le titre */}
          <div className="h-8 bg-gray-200 rounded-lg w-3/4"></div>
          
          {/* Skeletons pour les paragraphes */}
          <div className="space-y-4">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="space-y-2">
                <div className="h-4 bg-gray-200 rounded w-full"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              </div>
            ))}
          </div>

          {/* Skeleton pour le champ de saisie */}
          <div className="mt-6">
            <div className="h-12 bg-gray-200 rounded w-full mb-4"></div>
            <div className="h-10 bg-gray-200 rounded w-full"></div>
          </div>
        </Card>
      </div>

      {/* Partie droite (PDF) */}
      <div className={`${isMobile ? "w-full" : "w-1/2"} mb-6`}>
        <Card 
          className="shadow-md overflow-hidden w-full bg-gray-200"
          style={{
            height: isMobile ? "500px" : "calc(100vh - 100px)",
            maxWidth: isMobile ? "100%" : "calc((100vh - 100px) / 1.4142)"
          }}
        />
      </div>
    </div>
  );
};

export default function FiscalId() {
  const [isMobile, setIsMobile] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { properties, loading, refreshProperties } = useProperties();
  const [fiscalId, setFiscalId] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isPropertySelectionDone, setIsPropertySelectionDone] = useState(false);

  // PDF fiscal
  const pdfUrl = "https://client.dimo-diagnostic.net/identifiant_fiscal.pdf";

  // Gère la détection du mode mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Une fois que les propriétés sont chargées, on détermine la propriété sélectionnée
  useEffect(() => {
    if (!loading && properties.length > 0 && !isSubmitted) {
      if (id) {
        const property = properties.find((p) => p.id.toString() === id);
        if (property && property.actionsNeeded.fiscalId) {
          setSelectedProperty(property);
        } else {
          navigate("/fiscal-id", { replace: true });
        }
      } else {
        const propertyNeedingFiscalId = properties.find((p) => p.actionsNeeded.fiscalId);
        if (propertyNeedingFiscalId) {
          navigate(`/fiscal-id/${propertyNeedingFiscalId.id}`, { replace: true });
        } else {
          setSelectedProperty(null);
        }
      }
      setIsPropertySelectionDone(true);
    } else if (!loading && properties.length === 0) {
      setIsPropertySelectionDone(true);
    }
  }, [id, properties, loading, navigate, isSubmitted]);

  // Liste des propriétés nécessitant un numéro fiscal
  const propertiesNeedingFiscalId = properties.filter((p) => p.actionsNeeded.fiscalId);

  const handlePropertyChange = (newId) => {
    trackEvent('fiscalid_change_property', { 
      propertyId: newId
    });
    
    const property = properties.find((p) => p.id.toString() === newId.toString());
    setSelectedProperty(property);
    navigate(`/fiscal-id/${newId}`, { replace: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setIsSubmitting(true);

    trackEvent('fiscalid_submit', {
      propertyId: selectedProperty.id
    });

    try {
      await axiosInstance.post('/fiscal-id', {
        fiscalId,
        propertyId: selectedProperty.id
      });
      
      trackEvent('fiscalid_submit_success', {
        propertyId: selectedProperty.id
      });
      
      setSuccess("L'identifiant fiscal a bien été enregistré");
      setFiscalId("");
      setIsSubmitted(true);
      refreshProperties();
    } catch (error) {
      trackEvent('fiscalid_submit_error', {
        propertyId: selectedProperty.id
      });
      
      setError(error.response?.data?.error || "Une erreur est survenue lors de la mise à jour de l'identifiant fiscal");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBackToDiagnostic = () => {
    trackEvent('fiscalid_back_to_diagnostic', {
      propertyId: selectedProperty.id
    });
    
    navigate(`/diagnostics/${selectedProperty.id}`);
  };

  const renderPdfViewer = () => (
    <div className="w-full h-full" style={{ aspectRatio: "1 / 1.4142" }}>
      {isMobile ? (
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
          className="w-full border-0 h-full"
          title="Identifiant Fiscal Document"
        />
      ) : (
        <object
          data={pdfUrl}
          type="application/pdf"
          className="w-full h-full border-0"
        >
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            className="w-full h-full border-0"
            title="Identifiant Fiscal Document"
          />
        </object>
      )}
    </div>
  );

  return (
    <Layout>
      <Helmet>
        <title>Identifiant fiscal du local - Dimo Diagnostic</title>
        <meta
          name="description"
          content="Renseignez l'identifiant fiscal de votre local pour compléter votre dossier."
        />
        <link
          rel="canonical"
          href={`/fiscal-id${id ? `/${id}` : ""}`}
        />
      </Helmet>

      {(loading || !isPropertySelectionDone) && (
        <FiscalIdSkeleton />
      )}

      {!loading && isPropertySelectionDone && !selectedProperty && (
        <Card className="shadow-md p-6 mb-6 space-y-6 w-full">
          <Typography variant="h4" color="blue-gray" className="text-center">
            Identifiant fiscal du local
          </Typography>
          <Typography variant="paragraph" color="blue-gray" className="text-center">
            Vous n'avez actuellement aucun dossier nécessitant un identifiant fiscal.
          </Typography>
          <Typography variant="paragraph" color="blue-gray" className="text-center">
            Si vous pensez qu'il s'agit d'une erreur, veuillez contacter notre support.
          </Typography>

          <div className="flex justify-center">
            <Button
              variant="filled"
              style={{ backgroundColor: colors.dimoPrimary, width: "fit-content" }}
              onClick={() => navigate("/")}
            >
              Retour à l'accueil
            </Button>
          </div>
        </Card>
      )}

      {!loading && isPropertySelectionDone && selectedProperty && (
        <div className={`flex ${isMobile ? "flex-col" : "flex-row"} gap-6`}>
          {/* -- PARTIE GAUCHE -- */}
          <div className={`${isMobile ? "w-full" : "w-1/2"} mb-6`}>
            <Card className="shadow-md p-6 space-y-6">
              {propertiesNeedingFiscalId.length > 1 && (
                <PropertySelector
                  selectedProperty={selectedProperty ? selectedProperty.id : null}
                  setSelectedProperty={handlePropertyChange}
                  properties={propertiesNeedingFiscalId}
                  isLoading={loading}
                />
              )}

              {/* Titre de la page */}
              <Typography variant="h4" color="blue-gray" className="mb-5">
                Identifiant fiscal du local
              </Typography>

              {/* Boîte d'information sur le dossier */}
              {selectedProperty && (
                <Card className="p-4 bg-gray-50 border border-gray-200 mb-6">
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <UserIcon
                        className="h-5 w-5"
                        style={{ color: colors.dimoDark3 }}
                      />
                      <Typography variant="h6" color="blue-gray">
                        {selectedProperty.name}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <IdentificationIcon
                        className="h-5 w-5"
                        style={{ color: colors.dimoDark3 }}
                      />
                      <Typography variant="small" color="gray">
                        Dossier: {selectedProperty.id}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <MapPinIcon
                        className="h-5 w-5"
                        style={{ color: colors.dimoDark3 }}
                      />
                      <Typography variant="small" color="gray">
                        Adresse: {selectedProperty.address}
                      </Typography>
                    </div>
                    <div className="flex items-center space-x-2">
                      <CalendarIcon
                        className="h-5 w-5"
                        style={{ color: colors.dimoDark3 }}
                      />
                      <Typography variant="small" color="gray">
                        Date du rendez-vous: {selectedProperty.appointmentText || "Rendez-vous non défini"}
                      </Typography>
                    </div>
                  </div>
                </Card>
              )}
              
              {success ? (
                <div className="flex flex-col items-center justify-center space-y-6">
                  <CheckCircleIcon className="h-16 w-16 text-green-500" />
                  <Typography variant="h4" color="green">
                    Merci pour votre réponse !
                  </Typography>
                  <Typography color="blue-gray" className="text-center">
                    L'identifiant fiscal a bien été enregistré.
                    Vous pouvez maintenant revenir à votre diagnostic.
                  </Typography>
                  <Button
                    variant="filled"
                    style={{ backgroundColor: colors.dimoPrimary }}
                    onClick={handleBackToDiagnostic}
                  >
                    Retour au diagnostic
                  </Button>
                </div>
              ) : (
                <>
                  <Typography variant="paragraph" color="blue-gray" className="mb-4">
                    Pour finaliser votre dossier de diagnostic, nous avons besoin de l'identifiant fiscal de votre local.
                    Vous pouvez le trouver sur le site des impôts.
                  </Typography>
                  <Typography variant="paragraph" color="blue-gray" className="mb-4">
                    Cet identifiant est nécessaire pour la réalisation de votre DPE (Diagnostic de Performance Énergétique).
                  </Typography>

                  <Typography variant="paragraph" color="blue-gray" className="mb-4">
                    L'identifiant fiscal du local est sur 12 caractères, composé :
                    <ul className="list-disc pl-6 mt-2">
                        <li>du code département sur 2 caractères</li>
                        <li>du code commune (code insee) sur 3 caractères</li>
                        <li>d'un numéro séquentiel non signifiant sur 7 caractères (anciennement appelé numéro invariant)</li>
                    </ul>
                  </Typography>

                  <Typography variant="paragraph" color="blue-gray" className="mb-4">
                    Vous pouvez trouvez ci joint un document qui vous permettra de trouver l'identifiant fiscal de votre local via le site des impôts.
                  </Typography>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <Input
                        type="text"
                        label="Identifiant fiscal"
                        value={fiscalId}
                        onChange={(e) => setFiscalId(e.target.value)}
                        className="w-full"
                        required
                        pattern="[0-9]{12}"
                        maxLength={12}
                        error={!!error}
                      />
                      {error && (
                        <Typography
                          variant="small"
                          color="red"
                          className="mt-2"
                        >
                          {error}
                        </Typography>
                      )}
                    </div>
                    <Button
                      type="submit"
                      variant="filled"
                      style={{ backgroundColor: colors.dimoPrimary }}
                      className="w-full"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Spinner className="h-4 w-4 mx-auto" />
                      ) : (
                        "Enregistrer l'identifiant fiscal"
                      )}
                    </Button>
                  </form>
                </>
              )}
            </Card>
          </div>

          {/* -- PARTIE DROITE (PDF) -- */}
          <div
            className={`${
              isMobile ? "w-full" : "w-1/2"
            } flex justify-start items-start mb-6`}
          >
            <Card
              className="shadow-md overflow-hidden"
              style={{
                height: "calc(100vh - 100px)",
                width: "100%"
              }}
            >
              {renderPdfViewer()}
            </Card>
          </div>
        </div>
      )}
    </Layout>
  );
} 