import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Typography,
  Button,
  Input,
  IconButton,
  Checkbox,
  Spinner,
} from "@material-tailwind/react";
import { 
  XMarkIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import axiosInstance from "../../utils/axiosInstance";
import { colors } from "../../utils/colors";
import { trackEvent } from "../../utils/analytics";

const TipOption = ({ amount, percent, isCustom = false, selected, onSelect, label, isPopular = false, isDefaultAmount = false, handleTipSubmit, isProcessing }) => (
  <div 
    className="relative w-full mb-3"
    onClick={() => {
      onSelect(amount);
      if (!isCustom) {
        handleTipSubmit(amount);
      }
    }}
  >
    <div className={`relative overflow-hidden rounded-lg p-4 border transition-all duration-300 cursor-pointer ${
      selected ? 'border-2 border-green-500 bg-green-50/50 shadow-lg transform scale-105' : 
      'border-gray-200 hover:border-gray-300 hover:shadow-md hover:bg-gray-50/50'
    } ${isPopular ? 'ring-2 ring-green-400 ring-offset-1' : ''}`}>
      {isPopular && (
        <div className="absolute -top-1 -right-1">
          <div className="bg-green-500 text-white text-xs py-1 px-2 rounded-bl-lg rounded-tr-lg font-medium flex items-center">
            <SparklesIcon className="h-3 w-3 mr-1" />
            Populaire
          </div>
        </div>
      )}
      
      <div className="flex flex-col items-center justify-center">
        {isProcessing && selected ? (
          <Spinner className="h-6 w-6" />
        ) : (
          <>
            <Typography variant="h5" className="font-bold">
              {isDefaultAmount ? `${amount}€` : (percent || label || "Personnalisé")}
            </Typography>
            <Typography variant="small" className="text-gray-600">
              {amount && !isDefaultAmount ? `${amount}€` : ""}
            </Typography>
          </>
        )}
      </div>
    </div>
  </div>
);

export default function TipModal({ isOpen, onClose, dealId, technician, amount = 100, paymentLink }) {
  const [selectedTip, setSelectedTip] = useState(null);
  const [customTip, setCustomTip] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [neverShowTip, setNeverShowTip] = useState(false);
  const [isProcessingTip, setIsProcessingTip] = useState(false);

  // Calculer les montants de pourboire suggérés
  const defaultAmount = amount || 100;
  const smallPercent = Math.round(defaultAmount * 0.05);
  const mediumPercent = Math.round(defaultAmount * 0.10);
  const largePercent = Math.round(defaultAmount * 0.15);
  
  // Vérifier si on doit utiliser les montants par défaut
  const useSmallDefault = smallPercent < 5;
  const useMediumDefault = mediumPercent < 10;
  const useLargeDefault = largePercent < 15;
  
  const tipAmounts = {
    small: useSmallDefault ? 5 : smallPercent,
    medium: useMediumDefault ? 10 : mediumPercent,
    large: useLargeDefault ? 15 : largePercent
  };

  useEffect(() => {
    if (isOpen) {
      // Reset des états lors de l'ouverture du modal
      trackEvent('tip_modal_shown', {
        propertyId: dealId
      });
      
      setSelectedTip(null);
      setCustomTip("");
      setShowCustomInput(false);
      setNeverShowTip(false);
    }
  }, [isOpen, dealId]);

  const handleTipSubmit = async (tipAmount) => {
    if (tipAmount === 'custom' && !customTip) {
      return;
    }

    setIsProcessingTip(true);

    // Conversion du montant en nombre avec gestion des décimales (si format avec virgule ou point)
    const amount = tipAmount === 'custom' ? 
      parseFloat(customTip.toString().replace(',', '.')) : 
      tipAmount;
    
    // Vérifier que le montant est au moins 1€
    if (amount < 1) {
      alert("Le montant du pourboire doit être d'au moins 1€");
      setIsProcessingTip(false);
      return;
    }
    
    if (amount) {
      try {
        const response = await axiosInstance.post("/tip", {
          amount: amount,
          dealId,
          source: 'Pourboire'
        });
        
        // Tracking du pourboire
        trackEvent('tip_submitted', { 
          propertyId: dealId,
          amount: amount
        });
        
        // Rediriger vers l'URL de paiement Stripe
        if (response.data.url) {
          window.location.href = response.data.url;
          return; // Sortir de la fonction après redirection
        }

        // Si pas de redirection, fermer le modal
        onClose();
      } catch (error) {
        console.error("Erreur lors de l'envoi du pourboire:", error);
        setIsProcessingTip(false);
        
        // Tracking de l'erreur
        trackEvent('tip_error', { 
          propertyId: dealId,
          amount: amount
        });
        
        // Afficher une alerte en cas d'erreur
        alert("Une erreur est survenue lors de l'envoi du pourboire. Veuillez réessayer ultérieurement.");
        onClose();
      }
    }
    
    // Si la case "Ne plus afficher" est cochée, définir le cookie
    if (neverShowTip) {
      document.cookie = "tipStepSeen=true; max-age=5259600; path=/"; // 2 mois
    }
  };

  const handleSubmitCustomTip = () => {
    if (customTip) {
      // Conversion avec gestion de la virgule ou du point comme séparateur décimal
      const amount = parseFloat(customTip.toString().replace(',', '.'));
      
      // Vérification du montant minimum
      if (amount < 1) {
        alert("Le montant du pourboire doit être d'au moins 1€");
        return;
      }
      
      handleTipSubmit('custom');
    }
  };

  const renderStarRating = (rating = 4.8) => {
    // Arrondir à 0.5 près pour faciliter l'affichage
    const roundedRating = Math.round(rating * 2) / 2;
    const fullStars = Math.floor(roundedRating);
    const hasHalfStar = roundedRating % 1 !== 0;
    
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => {
          if (i < fullStars) {
            return <StarIconSolid key={`star-${i}`} className="h-4 w-4 text-yellow-400" />;
          } else if (i === fullStars && hasHalfStar) {
            return (
              <div key={`star-${i}`} className="relative h-4 w-4">
                <StarIconSolid className="absolute h-4 w-4 text-yellow-400 overflow-hidden" style={{ clipPath: "inset(0 50% 0 0)" }} />
                <StarIconSolid className="h-4 w-4 text-gray-300" />
              </div>
            );
          } else {
            return <StarIconSolid key={`star-${i}`} className="h-4 w-4 text-gray-300" />;
          }
        })}
        <span className="ml-1 text-xs font-medium text-gray-700">{rating}/5</span>
      </div>
    );
  };

  return (
    <Dialog 
      open={isOpen} 
      handler={onClose} 
      size="sm"
      className="bg-white shadow-xl rounded-2xl overflow-hidden"
    >
      <DialogHeader className="border-b-0 px-6 pt-5 pb-0">
        <div className="w-full flex justify-between items-center">
          <Typography variant="h5" className="font-bold text-gray-800">
            Remercier votre diagnostiqueur
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </div>
      </DialogHeader>
      <DialogBody className="p-0 overflow-y-auto">
        <div className="px-6 pt-2 pb-4">
          {/* Section Technicien - Plus compacte */}
          {technician && (
            <div className="bg-white rounded-lg p-4 mb-4 shadow-sm border border-gray-200">
              <div className="flex items-center gap-3">
                {technician.photoUrl ? (
                  <img 
                    src={technician.photoUrl} 
                    alt={`${technician.prenom} ${technician.nom}`}
                    className="w-16 h-16 rounded-full object-cover border-2 border-white shadow-sm"
                  />
                ) : (
                  <div className="w-16 h-16 rounded-full bg-blue-500 flex items-center justify-center border-2 border-white shadow-sm">
                    <Typography className="text-white text-lg font-bold">
                      {technician.prenom?.[0]}{technician.nom?.[0]}
                    </Typography>
                  </div>
                )}
                
                <div className="flex-1">
                  <Typography variant="h6" className="font-medium text-gray-800">
                    {technician.prenom} {technician.nom}
                  </Typography>
                  <Typography color="blue-gray" className="text-xs font-medium mb-1">
                    Votre diagnostiqueur
                  </Typography>
                  {renderStarRating(4.8)}
                </div>
              </div>
            </div>
          )}

          {/* Section Citation - Plus concise */}
          <div className="text-center mb-5">
            <Typography color="gray" className="text-sm mb-1">
              {technician ? 
                `Vous avez apprécié le service de ${technician.prenom} ? Laissez-lui un pourboire pour le remercier.` :
                "Vous avez apprécié notre service ? Laissez un pourboire pour remercier votre diagnostiqueur."
              }
            </Typography>
            <Typography color="green" className="text-xs font-medium">
              100% du pourboire est directement reversé au diagnostiqueur
            </Typography>
          </div>
          
          {/* Options de pourboire */}
          <div className="grid grid-cols-3 gap-3 mb-4">
            <TipOption 
              amount={tipAmounts.small} 
              percent="5%"
              selected={selectedTip === tipAmounts.small} 
              onSelect={setSelectedTip}
              isDefaultAmount={useSmallDefault}
              handleTipSubmit={handleTipSubmit}
              isProcessing={isProcessingTip}
            />
            <TipOption 
              amount={tipAmounts.medium} 
              percent="10%"
              selected={selectedTip === tipAmounts.medium} 
              onSelect={setSelectedTip}
              isPopular={true}
              isDefaultAmount={useMediumDefault}
              handleTipSubmit={handleTipSubmit}
              isProcessing={isProcessingTip}
            />
            <TipOption 
              amount={tipAmounts.large} 
              percent="15%"
              selected={selectedTip === tipAmounts.large} 
              onSelect={setSelectedTip}
              isDefaultAmount={useLargeDefault}
              handleTipSubmit={handleTipSubmit}
              isProcessing={isProcessingTip}
            />
          </div>
          
          {/* Montant personnalisé */}
          <div className="text-center mb-5">
            {!showCustomInput ? (
              <Button
                variant="text"
                className="normal-case text-sm bg-gray-100 hover:bg-gray-200 text-gray-700"
                onClick={() => {
                  setSelectedTip('custom');
                  setShowCustomInput(true);
                }}
              >
                Montant libre
              </Button>
            ) : (
              <div className="max-w-xs mx-auto">
                <div className="flex items-center gap-2">
                  <Input
                    type="text"
                    label="Montant personnalisé (€)"
                    min="1"
                    value={customTip}
                    onChange={(e) => setCustomTip(e.target.value)}
                    className="text-center"
                    placeholder="Ex: 1,5"
                    containerProps={{ className: "flex-1" }}
                  />
                  <Button 
                    color="green"
                    onClick={handleSubmitCustomTip}
                    disabled={!customTip}
                    style={{ backgroundColor: colors.dimoPrimary }}
                    className="px-4"
                  >
                    Valider
                  </Button>
                </div>
              </div>
            )}
          </div>
          
          {/* Option Ne plus afficher */}
          <div className="flex items-center justify-center">
            <Checkbox 
              id="neverShowTip" 
              checked={neverShowTip} 
              onChange={() => setNeverShowTip(!neverShowTip)} 
              color="green"
              className="h-4 w-4"
            />
            <label htmlFor="neverShowTip" className="text-xs text-gray-600 cursor-pointer ml-2">
              Ne plus me proposer de pourboire
            </label>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
} 