import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Card,
  CardBody,
  Typography,
  Button,
  Input,
  Alert,
  IconButton,
  Checkbox,
  Spinner,
} from "@material-tailwind/react";
import { 
  CreditCardIcon, 
  BanknotesIcon, 
  ArrowPathIcon, 
  ArrowLeftIcon,
  XMarkIcon,
  SparklesIcon,
  CheckIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import axiosInstance from "../../utils/axiosInstance";
import { colors } from "../../utils/colors";
import { trackEvent } from "../../utils/analytics";

const TipOption = ({ amount, percent, isCustom = false, selected, onSelect, label, isPopular = false, handleTipSubmit, isDefaultAmount = false, isProcessing }) => (
  <div 
    className="relative w-full mb-3"
    onClick={() => {
      onSelect(amount);
      if (!isCustom) {
        handleTipSubmit(amount);
      }
    }}
  >
    <div className={`relative overflow-hidden rounded-lg p-4 border transition-all duration-300 ${
      selected ? 'border-2 border-green-500 bg-green-50/50 shadow-lg transform scale-105' : 
      'border-gray-200 hover:border-gray-300 hover:shadow-md hover:bg-gray-50/50'
    } ${isPopular ? 'ring-2 ring-green-400 ring-offset-1' : ''}`}>
      {isPopular && (
        <div className="absolute -top-1 -right-1">
          <div className="bg-green-500 text-white text-xs py-1 px-2 rounded-bl-lg rounded-tr-lg font-medium flex items-center">
            <SparklesIcon className="h-3 w-3 mr-1" />
            Populaire
          </div>
        </div>
      )}
      
      <div className="flex flex-col items-center justify-center">
        {isProcessing && selected ? (
          <Spinner className="h-6 w-6" />
        ) : (
          <>
            <Typography variant="h5" className="font-bold">
              {isDefaultAmount ? `${amount}€` : (percent || label || "Personnalisé")}
            </Typography>
            <Typography variant="small" className="text-gray-600">
              {amount && !isDefaultAmount ? `${amount}€` : ""}
            </Typography>
          </>
        )}
      </div>
    </div>
  </div>
);

const PaymentOption = ({ icon, title, description, popular = false, onClick }) => (
  <Card className="w-full mb-4 cursor-pointer hover:bg-gray-50 transition-colors duration-100 ease-in-out" onClick={onClick}>
    <CardBody className="flex items-center p-4">
      <div className="rounded-full bg-blue-50 p-3 mr-4">
        {icon}
      </div>
      <div className="flex-grow">
        <Typography variant="h6" color="blue-gray">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal">
          {description}
        </Typography>
      </div>
      {popular && (
        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded">
          Le plus utilisé
        </span>
      )}
    </CardBody>
  </Card>
);

export default function PaymentModal({ isOpen, onClose, paymentLink, dealId, technician }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedTip, setSelectedTip] = useState(null);
  const [customTip, setCustomTip] = useState("");
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [isTransferPopupOpen, setIsTransferPopupOpen] = useState(false);
  const [tipError, setTipError] = useState(false);
  const [showTipSystemError, setShowTipSystemError] = useState(false);
  const [tipAttempted, setTipAttempted] = useState(false);
  const [neverShowTip, setNeverShowTip] = useState(false);
  const [isProcessingTip, setIsProcessingTip] = useState(false);

  // Calculer les montants de pourboire suggérés (basés sur un montant par défaut de 100€)
  const defaultAmount = 100;
  const smallPercent = Math.round(defaultAmount * 0.05);
  const mediumPercent = Math.round(defaultAmount * 0.10);
  const largePercent = Math.round(defaultAmount * 0.15);
  
  // Vérifier si on doit utiliser les montants par défaut
  const useSmallDefault = smallPercent < 5;
  const useMediumDefault = mediumPercent < 10;
  const useLargeDefault = largePercent < 15;
  
  const tipAmounts = {
    small: useSmallDefault ? 5 : smallPercent,
    medium: useMediumDefault ? 10 : mediumPercent,
    large: useLargeDefault ? 15 : largePercent
  };

  useEffect(() => {
    if (isOpen) {
      // Vérifier si l'utilisateur a déjà vu l'étape pourboire
      const tipStepSeen = document.cookie.split('; ').find(row => row.startsWith('tipStepSeen='));
      
      if (tipStepSeen && tipStepSeen.split('=')[1] === 'true') {
        // Si l'utilisateur a déjà vu l'étape pourboire, passer directement à l'étape 2
        setCurrentStep(2);
      } else {
        // Sinon, commencer par l'étape 1 (pourboire) et tracker l'événement
        setCurrentStep(1);
        trackEvent('payment_tip_step_shown', {
          propertyId: dealId
        });
      }
      
      setSelectedTip(null);
      setCustomTip("");
      setShowCustomInput(false);
      setTipError(false);
      setShowTipSystemError(false);
      setTipAttempted(false);
      setNeverShowTip(false);
    }
  }, [isOpen, dealId]);

  const handleTipSubmit = async (tipAmount) => {
    if (tipAmount === 'custom' && !customTip) {
      return;
    }

    setIsProcessingTip(true);

    // Conversion du montant en nombre avec gestion des décimales (si format avec virgule ou point)
    const amount = tipAmount === 'custom' ? 
      parseFloat(customTip.toString().replace(',', '.')) : 
      tipAmount;
    
    // Vérifier que le montant est au moins 1€
    if (amount < 1) {
      alert("Le montant du pourboire doit être d'au moins 1€");
      return;
    }
    
    setTipAttempted(true);
    
    if (amount) {
      try {
        const response = await axiosInstance.post("/tip", {
          amount: amount,
          dealId,
          source: 'Paiement Facture'
        });
        
        // Tracking du pourboire
        trackEvent('payment_tip_submitted', { 
          propertyId: dealId,
          amount: amount
        });

        // Rediriger vers l'URL de paiement Stripe
        if (response.data.url) {
          window.location.href = response.data.url;
          return; // Sortir de la fonction après redirection
        }
      } catch (error) {
        console.error("Erreur lors de l'envoi du pourboire:", error);
        setTipError(true);
        setIsProcessingTip(false);
        
        // Tracking de l'erreur
        trackEvent('payment_tip_error', { 
          propertyId: dealId,
          amount: amount
        });
      }
    }
    
    // Si la case "Ne plus afficher" est cochée, définir le cookie
    if (neverShowTip) {
      document.cookie = "tipStepSeen=true; max-age=5259600; path=/"; // 2 mois
    }
    
    // Passer à l'étape suivante (seulement si nous n'avons pas redirigé vers Stripe)
    window.scrollTo(0, 0);
    setCurrentStep(2);
    setShowTipSystemError(true);
  };

  const handleSubmitCustomTip = () => {
    if (customTip) {
      // Conversion avec gestion de la virgule ou du point comme séparateur décimal
      const amount = parseFloat(customTip.toString().replace(',', '.'));
      
      // Vérification du montant minimum
      if (amount < 1) {
        alert("Le montant du pourboire doit être d'au moins 1€");
        return;
      }
      
      handleTipSubmit('custom');
    }
  };

  const handleSkipTip = () => {
    // Si la case "Ne plus afficher" est cochée, définir le cookie
    if (neverShowTip) {
      document.cookie = "tipStepSeen=true; max-age=5259600; path=/"; // 2 mois
    }
    
    // Tracking de l'action "Passer"
    trackEvent('payment_tip_skipped', { 
      propertyId: dealId
    });
    
    setCurrentStep(2);
  };

  const handlePayment = (method) => {
    trackEvent('payment_method_selected', {
      propertyId: dealId,
      method: method
    });

    if (method === "transfer") {
      setIsTransferPopupOpen(true);
      onClose();
    } else if (paymentLink) {
      window.open(paymentLink, '_blank');
      onClose();
    }
  };

  const closeTransferPopup = () => {
    setIsTransferPopupOpen(false);
  };

  const renderStarRating = (rating = 4.8) => {
    // Arrondir à 0.5 près pour faciliter l'affichage
    const roundedRating = Math.round(rating * 2) / 2;
    const fullStars = Math.floor(roundedRating);
    const hasHalfStar = roundedRating % 1 !== 0;
    
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => {
          if (i < fullStars) {
            return <StarIconSolid key={`star-${i}`} className="h-4 w-4 text-yellow-400" />;
          } else if (i === fullStars && hasHalfStar) {
            return (
              <div key={`star-${i}`} className="relative h-4 w-4">
                <StarIconSolid className="absolute h-4 w-4 text-yellow-400 overflow-hidden" style={{ clipPath: "inset(0 50% 0 0)" }} />
                <StarIconSolid className="h-4 w-4 text-gray-300" />
              </div>
            );
          } else {
            return <StarIconSolid key={`star-${i}`} className="h-4 w-4 text-gray-300" />;
          }
        })}
        <span className="ml-1 text-xs font-medium text-gray-700">{rating}/5</span>
      </div>
    );
  };

  const renderTipStep = () => (
    <>
      <DialogHeader className="border-b-0 px-6 pt-5 pb-0">
        <div className="w-full flex justify-between items-center">
          <Typography variant="h5" className="font-bold text-gray-800">
            Remercier votre diagnostiqueur
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={onClose}>
            <XMarkIcon className="h-5 w-5" />
          </IconButton>
        </div>
      </DialogHeader>
      <DialogBody className="p-0 overflow-y-auto">
        <div className="px-6 pt-2 pb-4">
          {/* Section Technicien - Plus compacte */}
          {technician && (
            <div className="bg-white rounded-lg p-4 mb-4 shadow-sm border border-gray-200">
              <div className="flex items-center gap-3">
                {technician.photoUrl ? (
                  <img 
                    src={technician.photoUrl} 
                    alt={`${technician.prenom} ${technician.nom}`}
                    className="w-16 h-16 rounded-full object-cover border-2 border-white shadow-sm"
                  />
                ) : (
                  <div className="w-16 h-16 rounded-full bg-blue-500 flex items-center justify-center border-2 border-white shadow-sm">
                    <Typography className="text-white text-lg font-bold">
                      {technician.prenom?.[0]}{technician.nom?.[0]}
                    </Typography>
                  </div>
                )}
                
                <div className="flex-1">
                  <Typography variant="h6" className="font-medium text-gray-800">
                    {technician.prenom} {technician.nom}
                  </Typography>
                  <Typography color="blue-gray" className="text-xs font-medium mb-1">
                    Votre diagnostiqueur
                  </Typography>
                  {renderStarRating(4.8)}
                </div>
              </div>
            </div>
          )}

          {/* Section Citation - Plus concise */}
          <div className="text-center mb-5">
            <Typography color="gray" className="text-sm mb-1">
              {technician ? 
                `Vous avez apprécié le service de ${technician.prenom} ? Laissez-lui un pourboire pour le remercier.` :
                "Vous avez apprécié notre service ? Laissez un pourboire pour remercier votre diagnostiqueur."
              }
            </Typography>
            <Typography color="green" className="text-xs font-medium">
              100% du pourboire est directement reversé au diagnostiqueur
            </Typography>
          </div>
          
          {/* Options de pourboire */}
          <div className="grid grid-cols-3 gap-3 mb-4">
            <TipOption 
              amount={tipAmounts.small} 
              percent="5%"
              selected={selectedTip === tipAmounts.small} 
              onSelect={setSelectedTip}
              handleTipSubmit={handleTipSubmit}
              isDefaultAmount={useSmallDefault}
              isProcessing={isProcessingTip}
            />
            <TipOption 
              amount={tipAmounts.medium} 
              percent="10%"
              selected={selectedTip === tipAmounts.medium} 
              onSelect={setSelectedTip}
              isPopular={true}
              handleTipSubmit={handleTipSubmit}
              isDefaultAmount={useMediumDefault}
              isProcessing={isProcessingTip}
            />
            <TipOption 
              amount={tipAmounts.large} 
              percent="15%"
              selected={selectedTip === tipAmounts.large} 
              onSelect={setSelectedTip}
              handleTipSubmit={handleTipSubmit}
              isDefaultAmount={useLargeDefault}
              isProcessing={isProcessingTip}
            />
          </div>
          
          {/* Montant personnalisé */}
          <div className="text-center mb-5">
            {!showCustomInput ? (
              <Button
                variant="text"
                className="normal-case text-sm bg-gray-100 hover:bg-gray-200 text-gray-700"
                onClick={() => {
                  setSelectedTip('custom');
                  setShowCustomInput(true);
                }}
              >
                Montant libre
              </Button>
            ) : (
              <div className="max-w-xs mx-auto">
                <div className="flex items-center gap-2">
                  <Input
                    type="text"
                    label="Montant personnalisé (€)"
                    min="1"
                    value={customTip}
                    onChange={(e) => setCustomTip(e.target.value)}
                    className="text-center"
                    placeholder="Ex: 1,5"
                    containerProps={{ className: "flex-1" }}
                  />
                  <Button 
                    color="green"
                    onClick={handleSubmitCustomTip}
                    disabled={!customTip}
                    style={{ backgroundColor: colors.dimoPrimary }}
                    className="px-4"
                  >
                    Valider
                  </Button>
                </div>
              </div>
            )}
          </div>
          
          {/* Option Ne plus afficher */}
          <div className="flex items-center justify-center">
            <Checkbox 
              id="neverShowTip" 
              checked={neverShowTip} 
              onChange={() => setNeverShowTip(!neverShowTip)} 
              color="green"
              className="h-4 w-4"
            />
            <label htmlFor="neverShowTip" className="text-xs text-gray-600 cursor-pointer ml-2">
              Ne plus me proposer de pourboire
            </label>
          </div>
        </div>
        
        {/* Bouton Passer */}
        <div className="bg-gray-50 px-6 py-3 rounded-b-xl border-t border-gray-200">
          <Button 
            variant="text" 
            onClick={handleSkipTip}
            className="text-sm text-gray-500 w-full"
          >
            Accéder au paiement sans pourboire
          </Button>
        </div>
      </DialogBody>
    </>
  );

  const renderStep2 = () => (
    <>
      <DialogHeader>
        <Typography variant="h5" color="blue-gray" className="font-medium">
          Choisissez votre moyen de paiement
        </Typography>
        {tipAttempted && showTipSystemError && (
          <Alert 
            color="red" 
            variant="ghost" 
            className="mt-4 rounded-lg border-red-500 text-xs sm:text-sm"
          >
            Une erreur est survenue lors de l'enregistrement du pourboire. 
            Celui-ci ne sera pas ajouté à votre paiement, vous pouvez continuer votre paiement.
          </Alert>
        )}
      </DialogHeader>
      <DialogBody divider className="overflow-y-auto max-h-[60vh]">

        <PaymentOption
          icon={<CreditCardIcon className="h-6 w-6 text-blue-500" />}
          title="Carte bancaire"
          description="Payez par carte bancaire. Service fourni par Stripe. Votre rapport sera disponible sous 30 secondes*"
          onClick={() => handlePayment("card")}
        />
        <PaymentOption
          icon={<ArrowPathIcon className="h-6 w-6 text-green-500" />}
          title="E-Virement"
          description="Un virement classique en mieux ! La transaction est directement rapprochée et votre rapport sera disponible sous 30 secondes*"
          popular={true}
          onClick={() => handlePayment("e-transfer")}
        />
        <PaymentOption
          icon={<BanknotesIcon className="h-6 w-6 text-gray-500" />}
          title="Virement"
          description="Le virement classique. Sa gestion prend plus de temps que les autres solutions. Votre rapport sera disponible dès traitement par nos équipes."
          onClick={() => handlePayment("transfer")}
        />
        <Typography variant="small" color="gray" className="text-xs mt-4 italic">
          *Sous réserve de la finalisation par nos équipes ou absence d'élément(s) manquant(s) du donneur d'ordre.
        </Typography>
        <div className="flex justify-start mt-6">
          <Button
            variant="text"
            className="flex items-center gap-2"
            onClick={() => setCurrentStep(1)}
            style={{ color: colors.dimoPrimary }}
          >
            <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Retour
          </Button>
        </div>
      </DialogBody>
    </>
  );

  return (
    <>
      <Dialog 
        open={isOpen} 
        handler={onClose} 
        size={currentStep === 1 ? "md" : "sm"}
        className="bg-white shadow-xl rounded-xl overflow-hidden"
      >
        {currentStep === 1 ? renderTipStep() : renderStep2()}
      </Dialog>

      <Dialog 
        open={isTransferPopupOpen} 
        handler={closeTransferPopup} 
        size="sm"
      >
        <DialogHeader>
          <Typography variant="h5" color="blue-gray">
            Important : Référence de paiement
          </Typography>
        </DialogHeader>
        <DialogBody>
          <Typography color="gray" className="font-normal mb-4">
            Sur la page suivante, une référence de paiement unique vous sera fournie. Vous devez la recopier exactement dans le libellé de votre virement, sans ajout d'informations supplémentaires (nom, numéro de facture, etc.).
          </Typography>
          <Typography color="gray" className="font-normal">
            Sans cette référence exacte, le traitement de votre paiement devra être effectué manuellement, ce qui peut prendre plusieurs jours.
          </Typography>
        </DialogBody>
        <div className="flex justify-between gap-2 p-4">
          <Button 
            variant="outlined"
            onClick={closeTransferPopup}
          >
            Retour
          </Button>
          <Button 
            className="bg-[#023634] hover:bg-[#042C2B]"
            onClick={() => {
              closeTransferPopup();
              if (paymentLink) {
                window.open(paymentLink, '_blank');
              }
            }}
          >
            Continuer vers le virement
          </Button>
        </div>
      </Dialog>
    </>
  );
}