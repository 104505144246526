import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { colors } from "../../utils/colors";
import { trackEvent } from "../../utils/analytics";

export default function ModifyAppointmentModal({ isOpen, onClose, supportPhone, supportEmail }) {
  // Appel au tracking lors de l'ouverture du modal
  React.useEffect(() => {
    // Fonction déplacée à l'intérieur du useEffect
    const handleOpenModal = () => {
      if (isOpen) {
        // Tracking de l'ouverture du modal
        trackEvent('modal_modify_appointment_opened', {});
      }
    };
    
    handleOpenModal();
  }, [isOpen]);

  return (
    <Dialog open={isOpen} handler={onClose} size="sm">
      <DialogHeader>Modifier le rendez-vous</DialogHeader>
      <DialogBody divider className="grid gap-4">
        <Typography color="gray" className="font-normal">
          Pour modifier votre rendez-vous, veuillez contacter notre support client :
        </Typography>
        <div className="flex items-center gap-2">
          <PhoneIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          <Typography className="font-medium" style={{ color: colors.dimoPrimary }}>
            {supportPhone}
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <EnvelopeIcon className="h-5 w-5" style={{ color: colors.dimoPrimary }} />
          <Typography className="font-medium" style={{ color: colors.dimoPrimary }}>
            {supportEmail}
          </Typography>
        </div>
      </DialogBody>
      <DialogFooter className="space-x-2">
        <Button 
          variant="outlined" 
          color="gray" 
          onClick={() => {
            trackEvent('modal_modify_appointment_closed', {});
            onClose();
          }}
        >
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
}