import React, { useState, useEffect, useRef } from 'react';
import { Card, Typography, Button, Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";
import { colors } from "../../utils/colors";
import axiosInstance from "../../utils/axiosInstance";
import { trackEvent } from "../../utils/analytics";

export default function RecommendedDeals({ source = 'recommended_deals' }) {
  const [deals, setDeals] = useState([]);
  const [displayDeals, setDisplayDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [isResetting, setIsResetting] = useState(false);
  const scrollContainerRef = useRef(null);
  const autoScrollTimerRef = useRef(null);
  const scrollPositionRef = useRef(0);

  useEffect(() => {
    // Tracking du chargement des offres recommandées
    trackEvent('deals_load', {
      source: source
    });
    
    const fetchDeals = async () => {
      try {
        const response = await axiosInstance.get('/deals');
        const recommendedDeals = response.data.deals.filter(d => d.recommended);
        setDeals(recommendedDeals);
        
        // Préparer les offres pour le carrousel infini en les dupliquant
        if (recommendedDeals.length > 0) {
          // Dupliquer les offres pour créer un effet de défilement continu
          setDisplayDeals([...recommendedDeals, ...recommendedDeals, ...recommendedDeals]);
        }
        
        // Tracking du succès du chargement
        trackEvent('deals_load_success', {
          source: source,
          dealCount: recommendedDeals.length
        });
        
        setLoading(false);
      } catch (err) {
        console.error('Erreur lors de la récupération des bons plans:', err);
        setError('Une erreur est survenue lors du chargement des bons plans.');
        
        // Tracking de l'erreur
        trackEvent('deals_load_error', {
          source: source,
          error: err.message || 'unknown'
        });
        
        setLoading(false);
      }
    };

    fetchDeals();
  }, [source]);

  // Surveillance du défilement pour détecter quand réinitialiser
  useEffect(() => {
    const handleScroll = () => {
      if (!scrollContainerRef.current || isResetting) return;
      
      const container = scrollContainerRef.current;
      scrollPositionRef.current = container.scrollLeft;
      
      // Si on atteint le dernier tiers, on revient au premier tiers sans animation
      // Cela crée une illusion de défilement infini
      if (container.scrollLeft > (container.scrollWidth * 2/3)) {
        setIsResetting(true);
        container.style.scrollBehavior = 'auto';
        container.scrollLeft = container.scrollLeft - (container.scrollWidth / 3);
        setTimeout(() => {
          container.style.scrollBehavior = 'smooth';
          setIsResetting(false);
        }, 50);
      }
      // Si on revient au début (en cas de défilement manuel vers la gauche)
      else if (container.scrollLeft < (container.scrollWidth / 6) && container.scrollLeft > 10) {
        setIsResetting(true);
        container.style.scrollBehavior = 'auto';
        container.scrollLeft = container.scrollLeft + (container.scrollWidth / 3);
        setTimeout(() => {
          container.style.scrollBehavior = 'smooth';
          setIsResetting(false);
        }, 50);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer && !loading) {
      scrollContainer.addEventListener('scroll', handleScroll);
      
      // Positionner initialement au premier ensemble de cartes (pas au tout début)
      setTimeout(() => {
        const container = scrollContainerRef.current;
        if (container && deals.length > 0) {
          container.style.scrollBehavior = 'auto';
          container.scrollLeft = container.scrollWidth / 3;
          setTimeout(() => {
            container.style.scrollBehavior = 'smooth';
          }, 50);
        }
      }, 100);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, deals.length, isResetting]);

  // Configuration du carrousel automatique
  useEffect(() => {
    const startAutoScroll = () => {
      if (!scrollContainerRef.current || deals.length <= 0 || isResetting) return;
      
      const scrollContainer = scrollContainerRef.current;
      const cardWidth = scrollContainer.firstChild ? scrollContainer.firstChild.offsetWidth + 16 : 280; // Largeur de la carte + gap
      
      autoScrollTimerRef.current = setInterval(() => {
        if (!autoScrollEnabled || isResetting) return;
        
        // Faire défiler d'une carte à la fois vers la droite
        scrollContainer.scrollBy({ left: cardWidth, behavior: 'smooth' });
      }, 4000); // Défilement toutes les 4 secondes
    };
    
    if (!loading && deals.length > 0) {
      startAutoScroll();
    }
    
    return () => {
      if (autoScrollTimerRef.current) {
        clearInterval(autoScrollTimerRef.current);
      }
    };
  }, [loading, deals, autoScrollEnabled, isResetting]);

  // Gestion des événements de survol pour pauser le défilement
  const handleMouseEnter = () => {
    setAutoScrollEnabled(false);
  };

  const handleMouseLeave = () => {
    setAutoScrollEnabled(true);
  };

  const handleDirectRedirect = async (deal) => {
    if (deal?.redirect_url) {
      try {
        // Tracking du clic sur une offre
        trackEvent('deals_click', {
          source: source,
          dealId: deal.id,
          dealProvider: deal.provider,
          dealCategory: deal.category
        });
        
        await axiosInstance.post('/deals/log-click', {
          dealId: deal.id,
          provider: deal.provider,
          category: deal.category,
          source: source
        });
        
        window.open(deal.redirect_url, '_blank');
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement du clic:', error);
        
        // Tracking de l'erreur
        trackEvent('deals_click_error', {
          source: source,
          dealId: deal.id,
          error: error.message || 'unknown'
        });
      }
    }
  };

  const handleDealRequest = (deal) => {
    if (deal.id === 'qoridor') {
      // Tracking du clic sur Qoridor (qui ouvre un modal)
      trackEvent('deals_qoridor_modal_open', {
        source: source
      });
      
      setSelectedDeal(deal);
      setOpenConfirmModal(true);
    } else {
      handleDirectRedirect(deal);
    }
  };

  const handleConfirm = async () => {
    if (selectedDeal) {
      // Tracking de confirmation dans le modal
      trackEvent('deals_qoridor_confirm', {
        source: source
      });
      
      await handleDirectRedirect(selectedDeal);
    }
    setOpenConfirmModal(false);
    setSelectedDeal(null);
  };

  const handleCloseModal = () => {
    // Tracking de la fermeture du modal
    trackEvent('deals_qoridor_cancel', {
      source: source
    });
    
    setOpenConfirmModal(false);
    setSelectedDeal(null);
  };

  const handlePrivacyClick = (e) => {
    e.stopPropagation();
    
    // Tracking du clic sur la politique de confidentialité
    trackEvent('deals_privacy_click', {
      source: source
    });
  };

  if (loading) {
    return (
      <Card className="p-6 shadow-md bg-white mb-6">
        <div className="h-8 bg-gray-200 rounded w-48 mb-6 animate-pulse"></div>
        <div className="relative">
          <div className="flex gap-4 overflow-x-auto pb-2 -mx-4 px-4 md:gap-6 md:-mx-6 md:px-6">
            {[...Array(3)].map((_, index) => (
              <Card 
                key={index} 
                className="flex-shrink-0 w-[260px] md:w-[280px] bg-white overflow-hidden animate-pulse"
              >
                <div className="relative">
                  <div className="aspect-[16/9] bg-gray-200"></div>
                  <div className="absolute bottom-2 left-2 bg-white rounded-lg p-2">
                    <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                  </div>
                </div>
                <div className="p-4 flex flex-col h-[180px]">
                  <div className="h-4 bg-gray-200 rounded w-24 mb-2"></div>
                  <div className="space-y-2 flex-grow">
                    <div className="h-3 bg-gray-200 rounded w-full"></div>
                    <div className="h-3 bg-gray-200 rounded w-3/4"></div>
                    <div className="h-3 bg-gray-200 rounded w-5/6"></div>
                  </div>
                  <div className="h-8 bg-gray-200 rounded w-full mt-auto"></div>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </Card>
    );
  }

  if (error) {
    return null;
  }

  if (deals.length === 0) {
    return null;
  }

  return (
    <Card className="p-6 shadow-md bg-white mb-6">
      <Typography variant="h3" className="text-black mb-6">
        Les bons plans du moment
      </Typography>
      <div className="relative">
        <div 
          ref={scrollContainerRef}
          className="flex gap-4 overflow-x-auto pb-2 -mx-4 px-4 md:gap-6 md:-mx-6 md:px-6 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ scrollBehavior: 'smooth' }}
        >
          {displayDeals.map((deal, index) => (
            <Card 
              key={`${deal.id}-${index}`}
              className="flex-shrink-0 w-[260px] md:w-[280px] bg-white hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer group"
              onClick={() => handleDealRequest(deal)}
            >
              <div className="relative">
                <div className="aspect-[16/9] bg-gray-50">
                  <img 
                    src={deal.image || '/placeholder.png'} 
                    alt={deal.title} 
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="absolute bottom-2 left-2 bg-white rounded-lg p-2 shadow-sm">
                  <img 
                    src={deal.logo || '/placeholder-logo.png'} 
                    alt={`${deal.title} logo`} 
                    className="w-8 h-8 object-contain"
                  />
                </div>
              </div>
              <div className="p-4 flex flex-col h-[180px]">
                <div className="flex items-center gap-2 mb-2">
                  <Typography variant="small" className="font-bold">
                    {deal.provider}
                  </Typography>
                </div>
                <Typography variant="paragraph" className="text-sm text-gray-700 flex-grow">
                  {deal.shortDescription || deal.description[0]}
                </Typography>
                <Button
                  fullWidth
                  className="mt-auto normal-case text-sm"
                  style={{ backgroundColor: colors.dimoPrimary }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDealRequest(deal);
                  }}
                >
                  {deal.button_text || "Profitez de l'offre"}
                </Button>
              </div>
            </Card>
          ))}
        </div>
      </div>

      {/* Modal de confirmation */}
      <Dialog open={openConfirmModal} handler={handleCloseModal} size="sm">
        <DialogHeader className="justify-center">
          <Typography variant="h5" className="text-center">
            🎉 Super choix !
          </Typography>
        </DialogHeader>
        <DialogBody className="text-center">
          <Typography className="mb-4 font-medium text-lg text-gray-800">
            Vous allez être recontacté par Qoridor
          </Typography>
          <Typography className="mb-6 text-gray-600">
            Qoridor dispose des outils les plus avancés sur le marché pour estimer votre bien. C'est le moment d'en profiter et tout cela gratuitement !
          </Typography>
          <div className="flex gap-4 justify-center mb-6">
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
            >
              Plus tard
            </Button>
            <Button
              onClick={handleConfirm}
              style={{ backgroundColor: colors.dimoPrimary }}
            >
              Découvrir l'offre
            </Button>
          </div>
          <Typography variant="small" className="text-gray-500 text-xs">
            En cliquant sur "Découvrir l'offre" vous reconnaissez que vos données personnelles seront traitées conformément à la{' '}
            <a 
              href="https://www.dimo-diagnostic.net/protection-des-donnees" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700"
              onClick={handlePrivacyClick}
            >
              politique de confidentialité de DIMO DIAGNOSTIC
            </a>
            .
          </Typography>
        </DialogBody>
      </Dialog>
    </Card>
  );
} 