import React from "react";
import { IconButton } from "@material-tailwind/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { colors } from "../../utils/colors";
import Logo from "../../utils/logo";
import { Link } from "react-router-dom";
import { trackEvent } from "../../utils/analytics";

export default function Header({ toggleSidebar }) {
  const handleToggleSidebar = () => {
    // Tracking de l'ouverture/fermeture de la sidebar depuis le header
    trackEvent('header_toggle_sidebar', {});
    
    toggleSidebar();
  };

  const handleLogoClick = () => {
    // Tracking du clic sur le logo pour retourner au dashboard
    trackEvent('header_logo_click', {});
  };

  return (
    <header className="bg-white shadow-md p-4 flex justify-between items-center lg:hidden">
      <Link to="/dashboard" className="mx-auto" onClick={handleLogoClick}>
        <Logo />
      </Link>
      <IconButton
        variant="text"
        style={{ color: colors.dimoPrimary }}
        onClick={handleToggleSidebar}
      >
        <Bars3Icon className="h-6 w-6" />
      </IconButton>
    </header>
  );
}