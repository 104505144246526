import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Spinner, Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";
import Layout from "../components/layout/Layout";
import { colors } from "../utils/colors";
import { Helmet } from 'react-helmet';
import { GiftIcon } from "@heroicons/react/24/outline";
import axiosInstance from "../utils/axiosInstance";
import { trackEvent } from "../utils/analytics";

export default function Deals() {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [isPro, setIsPro] = useState(false);
  const [isIndependent, setIsIndependent] = useState(false);

  const categories = [
    { id: 'all', label: 'Tous' },
    { id: 'immobilier', label: 'Immobilier' },
    { id: 'banque', label: 'Banque' },
    { id: 'energie', label: 'Énergie' },
    { id: 'services', label: 'Services' },
    { id: 'assurance', label: 'Assurance' }
  ];

  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await axiosInstance.get('/deals');
        setDeals(response.data.deals);
        setLoading(false);
      } catch (err) {
        console.error('Erreur lors de la récupération des bons plans:', err);
        setError('Une erreur est survenue lors du chargement des bons plans.');
        setLoading(false);
      }
    };

    fetchDeals();
  }, []);

  useEffect(() => {
    const token = document.cookie.split('; ').find(row => row.startsWith('authToken='));
    if (token) {
      const tokenValue = token.split('=')[1];
      try {
        const decodedToken = JSON.parse(atob(tokenValue.split('.')[1]));
        setIsPro(decodedToken.is_organization || decodedToken.is_key_account || false);
        setIsIndependent(decodedToken.is_independent || false);
      } catch (error) {
        console.error('Erreur lors du décodage du token:', error);
      }
    }
  }, []);

  const handleDirectRedirect = async (deal, source = 'deals') => {
    if (deal?.redirect_url) {
      try {
        // Tracking du clic sur un bon plan
        trackEvent('deals_redirect_clicked', { 
          dealId: deal.id,
          dealProvider: deal.provider,
          dealCategory: deal.category,
          source: source
        });
        
        await axiosInstance.post('/deals/log-click', {
          dealId: deal.id,
          provider: deal.provider,
          category: deal.category,
          source: source
        });
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement du clic:', error);
      }
      window.open(deal.redirect_url, '_blank');
    }
  };

  const handleDealRequest = (deal, source = 'deals') => {
    if (deal.id === 'qoridor') {
      trackEvent('deals_qoridor_modal_opened', { 
        source: source
      });
      
      setSelectedDeal(deal);
      setOpenConfirmModal(true);
    } else {
      handleDirectRedirect(deal, source);
    }
  };

  const handleConfirm = async () => {
    if (selectedDeal) {
      trackEvent('deals_qoridor_confirmed', {});
      await handleDirectRedirect(selectedDeal);
    }
    setOpenConfirmModal(false);
    setSelectedDeal(null);
  };

  const handleCloseModal = () => {
    trackEvent('deals_qoridor_cancelled', {});
    setOpenConfirmModal(false);
    setSelectedDeal(null);
  };

  const handleCategoryChange = (categoryId) => {
    trackEvent('deals_category_filter', { 
      category: categoryId
    });
    
    setSelectedCategory(categoryId);
  };

  const filteredDeals = deals.filter(deal => 
    selectedCategory === 'all' || deal.category === selectedCategory
  );

  const handleCardClick = (e, deal, source) => {
    if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'button') {
      return;
    }
    handleDealRequest(deal, source);
  };

  const handleButtonClick = (e, deal, source) => {
    e.stopPropagation();
    handleDealRequest(deal, source);
  };

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64">
          <Spinner className="h-12 w-12" style={{ color: colors.dimoPrimary }} />
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Card className="p-6">
          <Typography color="red" className="text-center">{error}</Typography>
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>Bons plans - Dimo Diagnostic</title>
        <meta name="description" content="Découvrez nos offres spéciales et bons plans pour vos diagnostics immobiliers" />
        <link rel="canonical" href="https://client.dimo-diagnostic.net/deals" />
      </Helmet>

      <Card className="mb-6 shadow-md overflow-hidden">
        {/* Hero section Qoridor uniquement pour les clients indépendants */}
        {isIndependent && !isPro && (
          <div className="relative">
            {/* Hero section style Netflix */}
            <div className="relative h-[600px]" style={{ backgroundColor: "#333" }}>
              {/* Video de fond avec overlay plus sombre */}
              <div className="absolute inset-0 z-0">
                <div className="absolute inset-0 bg-black/25 z-10"></div>
                <video 
                  src="/qoridor.mp4"
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="w-full h-full object-cover opacity-75"
                  poster="/deal/qoridor-bg.png"
                  preload="metadata"
                  loading="lazy"
                >
                  <source src="/qoridor.mp4" type="video/mp4" />
                </video>
              </div>

              {/* Contenu avec texte en blanc */}
              <div className="relative z-10 h-full p-6 md:p-12 flex flex-col justify-center">
                <div className="max-w-2xl">
                  <img 
                    src="/deal-logo/qoridor.png" 
                    alt="Qoridor logo" 
                    className="w-32 md:w-48 mb-6"
                    style={{ filter: 'invert(1)' }}
                  />
                  <Typography variant="h3" className="text-white mb-4">
                    Estimez gratuitement votre bien immobilier
                  </Typography>
                  <Typography variant="paragraph" className="text-white mb-8 text-lg">
                    Obtenez une estimation précise de votre bien grâce aux outils les plus avancés du marché, et bénéficiez de l'expertise de professionnels qualifiés.
                  </Typography>
                  <div className="flex flex-wrap gap-4">
                    <Button
                      size="lg"
                      className="normal-case"
                      style={{ backgroundColor: colors.dimoPrimary }}
                      onClick={() => {
                        const qoridorDeal = {
                          id: 'qoridor',
                          provider: 'Qoridor',
                          category: 'immobilier',
                          redirect_url: 'https://qoridor.fr?utm_source=dimo-diagnostic&utm_medium=marketplace&utm_campaign=dimo-diagnostic'
                        };
                        handleDealRequest(qoridorDeal, 'hero_section');
                      }}
                    >
                      Estimer mon bien gratuitement
                    </Button>
                    <Button
                      size="lg"
                      variant="outlined"
                      className="normal-case bg-white/10 backdrop-blur-sm text-white"
                      style={{ borderColor: 'white' }}  
                      onClick={() => {
                        const qoridorInfoDeal = {
                          id: 'qoridor-info',
                          provider: 'Qoridor',
                          category: 'immobilier',
                          redirect_url: 'https://www.qoridor.fr/?utm_source=dimo-diagnostic&utm_medium=marketplace&utm_campaign=dimo-diagnostic'
                        };
                        handleDealRequest(qoridorInfoDeal, 'hero_section');
                      }}
                    >
                      En savoir plus
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Hero section normal pour tous les autres cas */}
        {(!isIndependent || isPro) && (
          <div className="p-6">
            <div className="flex items-center gap-4 mb-6">
              <GiftIcon className="h-8 w-8" style={{ color: colors.dimoPrimary }} />
              <div>
                <Typography variant="h3" className="text-black">Club partenaires</Typography>
                <Typography variant="paragraph" className="text-gray-700">
                  Imaginé par nos clients, pour nos clients !
                </Typography>
              </div>
            </div>

            <Typography variant="paragraph" className="mb-8 text-gray-700">
              Nous avons pris soin de dénicher les services les plus pertinents du moment, spécialement conçues pour vous accompagner dans vos projets avec sérénité, efficacité et intelligence financière.
              Pensés pour répondre à vos attentes, ces services sont là pour vous simplifier la vie et enrichir chaque étape de votre expérience avec nous.
              Vous êtes à un clic d'améliorer votre projet ! 👇
            </Typography>
          </div>
        )}

        {/* Section des bons plans avec padding */}
        <div className="p-6">
          {/* sinon on affiche les bons plans */}
            <div className="mb-12">
              <Typography variant="h5" className="text-black mb-6">
                Bons plans repérés
              </Typography>
              <div className="relative">
                <div className="flex gap-4 overflow-x-auto pb-1 -mx-4 px-4 md:gap-6 md:-mx-6 md:px-6 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent [&::-webkit-scrollbar]{height:2px}">
                  {deals.filter(d => d.recommended).map((deal) => (
                    <Card 
                      key={deal.id} 
                      className="flex-shrink-0 w-[260px] md:w-[280px] bg-white hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer group"
                      onClick={(e) => handleCardClick(e, deal, 'deals_recommended')}
                    >
                      <div className="relative">
                        <div className="aspect-[4/3] bg-gray-50">
                          <img 
                            src={deal.image || '/placeholder.png'} 
                            alt={deal.title} 
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="absolute bottom-2 left-2 bg-white rounded-lg p-2 shadow-sm">
                          <img 
                            src={deal.logo || '/placeholder-logo.png'} 
                            alt={`${deal.title} logo`} 
                            className="w-8 h-8 object-contain"
                          />
                        </div>
                      </div>
                      <div className="p-4 flex flex-col h-[180px]">
                        <div className="flex items-center gap-2 mb-2">
                          <Typography variant="small" className="font-bold">
                            {deal.provider}
                          </Typography>
                        </div>
                        <Typography variant="paragraph" className="text-sm text-gray-700 flex-grow">
                          {deal.shortDescription || deal.description[0]}
                        </Typography>
                        <Button
                          fullWidth
                          className="mt-auto normal-case text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                          style={{ backgroundColor: colors.dimoPrimary, color: colors.dimoPop }}
                          onClick={(e) => handleButtonClick(e, deal, 'deals_recommended')}
                        >
                          {deal.button_text || "Profitez de l'offre"}
                        </Button>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            </div>

          {/* Toutes les intégrations */}
          <div>
            <div className="flex flex-col gap-4 mb-8">
              <Typography variant="h4" className="text-black">
                Tous les bons plans
              </Typography>
              <div className="flex flex-wrap gap-2">
                {categories.map(category => (
                  <Button 
                    key={category.id}
                    variant={selectedCategory === category.id ? "filled" : "outlined"}
                    size="sm"
                    className="normal-case rounded-full"
                    style={selectedCategory === category.id ? 
                      { backgroundColor: colors.dimoPrimary } : 
                      { borderColor: colors.dimoPrimary, color: colors.dimoPrimary }
                    }
                    onClick={() => handleCategoryChange(category.id)}
                  >
                    {category.label}
                  </Button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredDeals.map((deal) => (
                <Card 
                  key={deal.id} 
                  className="flex flex-col bg-white shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer group"
                  onClick={(e) => handleCardClick(e, deal, 'deals_list')}
                >
                  <div className="h-48 bg-gray-50 overflow-hidden">
                    <img 
                      src={deal.image} 
                      alt={deal.title} 
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <div className="p-4 flex flex-col flex-grow">
                    <div className="flex items-center gap-2 mb-2">
                      <img 
                        src={deal.logo} 
                        alt={`${deal.provider} logo`} 
                        className="w-6 h-6 object-contain"
                      />
                      <Typography variant="small" className="font-bold text-gray-800">
                        {deal.provider}
                      </Typography>
                    </div>
                    <Typography variant="h6" className="font-bold mb-1">
                      {deal.title}
                    </Typography>
                    <Typography className="text-gray-600 text-sm mb-3">
                      {deal.subtitle}
                    </Typography>

                    <ul className="list-disc pl-4 mb-4 flex-grow text-sm">
                      {deal.description.map((item, index) => (
                        <li key={index} className="text-gray-600 mb-1">
                          {item}
                        </li>
                      ))}
                    </ul>

                    <Button
                      fullWidth
                      className="mt-auto normal-case text-sm transform translate-y-1 group-hover:translate-y-0 transition-transform duration-300"
                      style={{ backgroundColor: colors.dimoPrimary, color: colors.dimoPop }}
                      onClick={(e) => handleButtonClick(e, deal, 'deals_list')}
                    >
                      {deal.button_text || "Profitez de l'offre"}
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </Card>

      {/* Modal de confirmation */}
      <Dialog open={openConfirmModal} handler={handleCloseModal} size="sm">
        <DialogHeader className="justify-center">
          <Typography variant="h5" className="text-center">
            🎉 Super choix !
          </Typography>
        </DialogHeader>
        <DialogBody className="text-center">
          <Typography className="mb-4 font-medium text-lg text-gray-800">
            Vous allez être recontacté par Qoridor
          </Typography>
          <Typography className="mb-6 text-gray-600">
            Qoridor dispose des outils les plus avancés sur le marché pour estimer votre bien. C'est le moment d'en profiter et tout cela gratuitement !
          </Typography>
          <div className="flex gap-4 justify-center mb-6">
            <Button
              variant="outlined"
              onClick={handleCloseModal}
              style={{ borderColor: colors.dimoPrimary, color: colors.dimoPrimary }}
            >
              Plus tard
            </Button>
            <Button
              onClick={handleConfirm}
              style={{ backgroundColor: colors.dimoPrimary }}
            >
              Découvrir l'offre
            </Button>
          </div>
          <Typography variant="small" className="text-gray-500 text-xs">
            En cliquant sur "Découvrir l'offre" vous reconnaissez que vos données personnelles seront traitées conformément à la{' '}
            <a 
              href="https://www.dimo-diagnostic.net/protection-des-donnees" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700"
              onClick={(e) => e.stopPropagation()}
            >
              politique de confidentialité de DIMO DIAGNOSTIC
            </a>
            .
          </Typography>
        </DialogBody>
      </Dialog>
    </Layout>
  );
} 